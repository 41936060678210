/* eslint-disable */
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import { bcsStepOptions } from '../../../constants/bcs';

const BcsHistogram = ({ data = [], title = '', systemId = 3 }) => {
  const formatData = useMemo(() => {
    const step = bcsStepOptions[systemId]?.step || 1;
    const res = {};
    for (let i = 1; i <= 5; i += step) {
      res[i] = data?.filter((value) => Number(value) === i)?.length;
    }
    return res;
  }, [data, systemId]);

  const barData = {
    options: {
      chart: {
        id: 'bcs-histogram',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9db668'],
      xaxis: {
        categories: Object.keys(formatData)?.sort(),
      },
      yaxis: {
        labels: {
          formatter: (val) => val,
        },
      },
      title: {
        text: title,
        align: 'left',
      },
    },
    series: [
      {
        name: 'Count',
        data: Object.keys(formatData)
          ?.sort()
          .map((q) => formatData[q]),
      },
    ],
  };

  return <Chart options={barData.options} series={barData.series} type='bar' height='100%' />;
};

export default BcsHistogram;
