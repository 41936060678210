import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../../hooks';

const boxSx = {
  display: 'flex',
  alignItems: 'center',
  pt: '4px',
  pb: '4px',
  borderBottom: '1px solid #eeeeee',
};

const SingleSampleTMRStat = ({ data }) => {
  const { t } = useTranslation();
  const { formatDecimal } = useSettings();

  if (!data) {
    return null;
  }

  const { TopPercent, SecondPanPercent, ThirdPanPercent, BottomPanPercent, pef, peCHO_C, peNDF } = data;

  return (
    <>
      <Box component='div'>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.TopPercent')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(TopPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.SecondPanPercent')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(SecondPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.ThirdPanPercent')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(ThirdPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.BottomPanPercent')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(BottomPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.pef')}
          </Typography>
          <Typography component='span'>{formatDecimal(pef)}%</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.peNDF')}
          </Typography>
          <Typography component='span'>{formatDecimal(peNDF)}%</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between', border: 'none' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.peCHO_C')}
          </Typography>
          <Typography component='span'>{formatDecimal(peCHO_C)}%</Typography>
        </Box>
      </Box>
    </>
  );
};

export default SingleSampleTMRStat;
