import { SET_CURRENT_USER, SIGN_OUT } from '../actions/actionTypes';
import { userRoles } from '../constants/roles';

const initialState = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  language: '',
  date_format: 'M/d/yyyy',
  phone: '',
  address: null,
  city: null,
  state_province: null,
  postal_code: null,
  country_id: null,
  opt_in: false,
  is_active: false,
  status: '',
  is_primary_admin: false,
  is_amts_admin: false,
  is_dealer: false,
  is_feed_supplier: false,
  has_active_fm: false,
  has_active_nutrition: false,
  has_active_mixalot: false,
  company_mills: [],
  user_mills: [],
  dealer: null,
  implementations_used: 0,
  created_at: '',
  updated_at: '',
  roles: [],
  user_roles: [],
  mill_users: [],
  countries: [],
  company: null,
};

const currentUserReducer = (state = initialState, action = {}) => {
  const { user } = action;
  const roles = user?.roles || [];

  switch (action.type) {
    case SET_CURRENT_USER:
      if (!user) {
        return initialState;
      }

      if (user?.has_active_nutrition) {
        roles?.push({ id: Math.random(), name: userRoles.SUBSCRIBER.name, description: userRoles.SUBSCRIBER.label });
      }

      return {
        ...state,
        ...user,
        roles,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default currentUserReducer;
