/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import PrintIcon from '@mui/icons-material/Print';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  filterCurrentFeeds,
  getFeedList,
  getFeedsInfoById,
  getFeedsTemplates,
  resetFeedsStore,
} from '../../../actions/feedsActions';
import { setExactFeed } from '../../../actions/rationsActions';
import { FeedsTable, FeedsTree, FeedsList, FeedsGraphs, FeedsInfo } from '../../../components/feeds';
import LoadScreen from '../../../components/load-screen';
import { TemplateSelect } from '../../../components/rations';
import { SideMenu, BottomNavigation, SearchInput } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { TabPanel, Tabs } from '../../../components/shared/tabs';
import { nutrientLabels, revertedNutrientLabels } from '../../../constants';
import { useLoading } from '../../../hooks';
import { feedsSelector, rationsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TABLE: 0,
  GRAPH: 1,
  TREE: 2,
};

const FeedsPage = () => {
  const dispatch = useDispatch();
  const feeds = useSelector(feedsSelector);
  const ration = useSelector(rationsSelector);
  const { t } = useTranslation();

  const { feedsList, currentFeeds, templateList, isLoaded } = feeds;

  const { exactFeed } = ration;

  const [currentInfo, setCurrentInfo] = useState(undefined);
  const [collapsed, setCollapse] = useState(true);
  // const [collapsed, setCollapse] = useState(!exactFeed && !currentFeeds?.length);
  const [currentPage, setCurrentPage] = useState(exactFeed ? tabs.TABLE : tabs.TREE);
  const [pickedNutrients, setPickedNutrients] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(undefined);
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState(tabs.TABLE);
  const [fetching, setFetching] = useState(!!exactFeed);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const handleFeedClick = async (feed) => {
    if (currentFeeds.find((q) => q.Feed_ID === feed.Feed_ID && q.OADate === feed.OADate)) {
      dispatch(
        filterCurrentFeeds(currentFeeds.filter((mix) => mix.Feed_ID !== feed.Feed_ID || mix.OADate !== feed.OADate))
      );
      setFetching(false);
    } else {
      if (
        currentFeeds?.length &&
        !currentFeeds.find((q) => q.Farm_ID === feed.Farm_ID && q.FeedName === feed.FeedName)
      ) {
        dispatch(filterCurrentFeeds([]));
      }
      try {
        setFetching(true);
        setCurrentInfo(feed);
        setActiveTab(tabs.TABLE);
        await dispatch(getFeedsInfoById(feed));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const getFeedInfo = async (fList) => {
    const filteredFeed = fList
      ?.find((q) => q.farm_id === exactFeed.farmId)
      ?.feeds?.find((fd) => fd?.feed_name === exactFeed?.feedName);

    await handleFeedClick({
      Farm_ID: exactFeed?.farmId,
      farmName: exactFeed?.farmName,
      FeedName: filteredFeed?.feed_name,
      Feed_ID: filteredFeed?.feed_id,
      OADate: filteredFeed?.OADates?.[0]?.oadate,
    });

    dispatch(setExactFeed(undefined));
  };

  const onLoad = async () => {
    try {
      startLoading();
      const fList = await dispatch(getFeedList());
      await dispatch(getFeedsTemplates());
      if (exactFeed) {
        await getFeedInfo(fList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetFeedsStore());
      dispatch(setExactFeed(undefined));
    };
  }, []);

  const handleRowClick = (nutr) => {
    if (pickedNutrients.find((el) => el === nutr)) {
      setPickedNutrients(pickedNutrients.filter((q) => q !== nutr));
    } else {
      setPickedNutrients([...pickedNutrients, nutr]);
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const templateColumn = useMemo(
    () =>
      currentTemplate?.ration_outputs
        ?.filter((el) => el.Visible === 'true')
        .map((el) => revertedNutrientLabels[el.Output]),
    [currentFeeds, feedsList, currentTemplate]
  );

  const handleTemplateChange = (e) => {
    setCurrentTemplate(templateList.find((el) => el.template_name === e.target.value) || undefined);
  };

  const getFeedColumns = () => {
    const feedRow = new Set();
    currentFeeds
      ?.filter((q) => !!q.feedInfo)
      ?.forEach((q) => {
        Object?.keys(q.feedInfo)
          .filter((el) => nutrientLabels[el]?.toUpperCase()?.includes(search?.toUpperCase()))
          .filter((el) => (templateColumn ? templateColumn.includes(el) : true))
          .forEach((key) => feedRow.add(key));
      });

    return Array.from(feedRow);
  };

  const getRows = () => {
    const newData = [];
    const feedColumns = getFeedColumns();

    feedColumns?.forEach((feedCol) => {
      const feedOBj = { feed_name: feedCol };
      currentFeeds.forEach((q) => {
        Object.assign(feedOBj, { [`${q.OADate}`]: q?.feedInfo[feedCol] });
      });
      newData.push(feedOBj);
    });

    return newData;
  };

  const onClear = () => {
    dispatch(filterCurrentFeeds([]));
    setCurrentInfo(undefined);
    setPickedNutrients([]);
    setActiveTab(tabs.TABLE);
  };

  const rows = useMemo(() => getRows(), [currentFeeds, feedsList, currentTemplate, search]);

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('feeds.tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('feeds.feedView'),
      icon: <TableIcon />,
    },
    {
      value: tabs.GRAPH,
      label: t('feeds.graphs'),
      icon: <ChartIcon />,
    },
  ];

  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        sx={{
          p: {
            lg: 0,
            md: 0,
            sm: 0,
            sx: 0,
          },
          position: 'relative',
        }}
      >
        {((loading && !isLoaded) || (mobileView && fetching)) && <LoadScreen />}
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <FeedsTree
                    currentFeeds={currentFeeds}
                    feedsList={feedsList}
                    handleClick={handleFeedClick}
                    mobileView={mobileView}
                    onClear={onClear}
                  />
                  {isLoaded && !feedsList?.length && <NoResults text={t('feeds.noResults')} />}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
                {!loading && (
                  <>
                    <Typography
                      component='h6'
                      sx={{
                        margin: '8px 0',
                        fontWeight: 'bold',
                      }}
                      variant='h6'
                    >
                      {t('feeds.feedView')}
                    </Typography>
                    <FeedsInfo currentInfo={currentInfo} />
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <TemplateSelect
                        currentTemplate={currentTemplate}
                        list={templateList}
                        onChange={handleTemplateChange}
                        size='small'
                      />
                    </Box>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <SearchInput
                        handleClear={() => setSearch('')}
                        onChange={(e) => setSearch(e.target.value)}
                        size='small'
                        sx={{ width: '100%' }}
                        value={search}
                      />
                    </Box>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      {/*<FeedsList data={rows} handleClick={handleRowClick} pickedNutrients={pickedNutrients} />*/}
                      <FeedsTable
                        data={currentFeeds}
                        handleRowClick={handleRowClick}
                        isMobile
                        loading={fetching}
                        pickedNutrients={pickedNutrients}
                        rows={rows}
                        sx={{ maxHeight: 'calc(100vh - 170px)' }}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.GRAPH)}>
                <FeedsInfo currentInfo={currentInfo} />
                <Box component='div' sx={{ margin: '16px 0' }}>
                  <TemplateSelect
                    currentTemplate={currentTemplate}
                    list={templateList}
                    onChange={handleTemplateChange}
                    size='small'
                  />
                </Box>
                <FeedsGraphs
                  currentInfo={currentInfo}
                  pickedNutrients={pickedNutrients}
                  rows={rows}
                  templateColumn={templateColumn}
                />
              </Box>
              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  if (currentInfo) {
                    setCurrentPage(newValue);
                  }
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              {!!feedsList?.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <FeedsTree
                    currentFeeds={currentFeeds}
                    feedsList={feedsList}
                    handleClick={handleFeedClick}
                    onClear={onClear}
                  />
                </SideMenu>
              )}
              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box component='div'>
                    <h2>{t('feeds.title')}</h2>
                    {isLoaded && !feedsList?.length && <NoResults text={t('feeds.noResults')} />}
                    {!currentFeeds?.length && !collapsed && !exactFeed && (
                      <Box component='div' sx={{ width: 'fit-content' }}>
                        <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                          {t('feeds.warningAlert')}
                        </MuiAlert>
                      </Box>
                    )}
                  </Box>
                  {currentInfo && (
                    <Box
                      component='div'
                      sx={{
                        display: 'flex',
                        gap: '16px',
                      }}
                    >
                      <SearchInput
                        handleClear={() => setSearch('')}
                        onChange={(e) => setSearch(e.target.value)}
                        size='small'
                        value={search}
                      />
                      <TemplateSelect
                        currentTemplate={currentTemplate}
                        list={templateList}
                        onChange={handleTemplateChange}
                        size='small'
                      />
                    </Box>
                  )}
                </Box>
                {currentInfo && (
                  <Tabs
                    activeTab={activeTab}
                    onChange={setActiveTab}
                    tabs={[
                      {
                        label: t('feeds.feedView'),
                        value: tabs.TABLE,
                        icon: <TableIcon />,
                      },
                      {
                        label: t('feeds.graphs'),
                        value: tabs.GRAPH,
                        disabled: !pickedNutrients.length,
                        icon: <ChartIcon />,
                      },
                    ]}
                  />
                )}

                <TabPanel index={tabs.TABLE} value={activeTab}>
                  {currentFeeds && (
                    <>
                      <FeedsInfo currentInfo={currentInfo} />
                      <FeedsTable
                        data={currentFeeds}
                        handleRowClick={handleRowClick}
                        loading={fetching}
                        pickedNutrients={pickedNutrients}
                        rows={rows}
                        sx={{ maxHeight: 'calc(100vh - 380px)' }}
                      />
                    </>
                  )}
                </TabPanel>

                <TabPanel index={tabs.GRAPH} value={activeTab}>
                  <FeedsGraphs
                    currentInfo={currentInfo}
                    loading={fetching}
                    pickedNutrients={pickedNutrients}
                    rows={rows}
                    templateColumn={templateColumn}
                  />
                </TabPanel>
                {pickedNutrients?.length > 0 && (
                  <Fab
                    aria-label='add'
                    color='primary'
                    onClick={() => window.print()}
                    sx={{
                      margin: 0,
                      top: 'auto',
                      right: 20,
                      bottom: 20,
                      left: 'auto',
                      position: 'fixed',
                      fontWeight: 'bold',
                    }}
                    variant='extended'
                  >
                    <PrintIcon sx={{ mr: 1 }} />
                    {t('print')}
                  </Fab>
                )}
              </Container>
            </>
          )}
        </div>
      </Container>
      {pickedNutrients?.length > 0 && (
        <div className='print-charts'>
          <FeedsGraphs
            currentInfo={currentInfo}
            customKey='print'
            isAnimationActive={false}
            pickedNutrients={pickedNutrients}
            print
            rows={rows}
            templateColumn={templateColumn}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FeedsPage;
