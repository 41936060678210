/* eslint-disable */
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { getAvg } from '../../../helpers';

import { useSettings } from '../../../hooks';

const PANS = ['BottomPanPercent', 'SecondPanPercent', 'ThirdPanPercent', 'TopPercent'];

const SampleTMRColumnChart = ({ data = {} }) => {
  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const formatData = useMemo(() => {
    let labels = data.map((sample) => [sample?.FeedName || sample?.RecipeName, formatDate(sample.OADate, true, true)]);
    let series = PANS.map((key) => {
      let values = data.map((sample) => sample[key]?.toFixed(2));
      return {
        name: t(`psc.${key}`),
        data: [...values, getAvg(values)?.toFixed(2)],
      };
    });

    return {
      labels: [...labels, t('avg')],
      series,
    };
  }, [data]);

  const barData = {
    options: {
      chart: {
        id: 'tmr-history-column',
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9db668', '#68B6A8', '#8168B6', '#B66876'],
      xaxis: {
        categories: formatData.labels,
      },
      yaxis: {
        labels: {
          formatter: (val) => `${100 - val}%`,
        },
      },
      tooltip: {
        y: {
          formatter: (value) => `${value}%`,
        },
      },
    },
    series: formatData?.series,
  };

  return (
    <Box
      component='div'
      sx={{
        minHeight: '400px',
      }}
    >
      <Chart options={barData.options} series={barData.series} type='bar' height='100%' />
    </Box>
  );
};

export default SampleTMRColumnChart;
