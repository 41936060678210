import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import React from 'react';

import { ToggleContainer, ToggleButton } from './styled';

const Toggle = (props) => {
  const { value, handleChange, options = [], color = 'primary', ...other } = props;

  return (
    <FormControl>
      <ToggleContainer color={color} exclusive onChange={(e) => handleChange(e.target.value)} value={value} {...other}>
        {options?.map((option) => (
          <ToggleButton key={option?.value} value={option?.value}>
            {option?.label}
          </ToggleButton>
        ))}
      </ToggleContainer>
    </FormControl>
  );
};

Toggle.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default Toggle;
