import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { dateFormats } from '../../../constants/settings';
import { settingsSelector } from '../../../reducers/selectors';

const DatePicker = (props) => {
  const {
    value,
    onChange,
    openTo = 'day',
    views = ['day'],
    label = '',
    minDate,
    maxDate,
    disablePast,
    disableFuture,
    disabled,
    closeOnSelect,
    size,
    ...other
  } = props;

  const userSettings = useSelector(settingsSelector);
  const dateFormat = [dateFormats.dM, dateFormats.ddMM].includes(userSettings.DateFormat)
    ? dateFormats.ddMM
    : dateFormats.MMdd;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: '100%' }}>
      <MuiDatePicker
        closeOnSelect={closeOnSelect}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled}
        format={dateFormat}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        openTo={openTo}
        renderInput={(params) => <TextField {...params} />}
        showTodayButton
        slotProps={{
          textField: {
            size: size,
            sx: { backgroundColor: '#ffffff', width: '100%' },
          },
        }}
        sx={{
          '.PrivatePickersSlideTransition-root': {
            minHeight: '248px',
          },
          width: '100%',
        }}
        value={value}
        views={views}
        {...other}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  openTo: PropTypes.oneOf(['day', 'month', 'year']),
  views: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year'])),
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
};

export default DatePicker;
