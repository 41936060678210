export const userSelector = (state) => state.currentUser;
export const farmsSelector = (state) => state.farms;
export const mixesSelector = (state) => state.mixes;
export const rationsSelector = (state) => state.rations;
export const feedsSelector = (state) => state.feeds;
export const comparisonsSelector = (state) => state.comparisons;
export const activityLogSelector = (state) => state.activityLog;
export const bcsResultsSelector = (state) => state.bcsResults;
export const particleSizeSampleFeedSelector = (state) => state.particleSizeSampleFeed;
export const particleSizeFeedHistorySelector = (state) => state.particleSizeFeedHistory;
export const particleSizeTMRHistorySelector = (state) => state.particleSizeTMRHistory;
export const farmVisitResultsSelector = (state) => state.farmVisitResults;

// Settings
export const settingsSelector = (state) => state.settings;
export const languageSelector = (state) => settingsSelector(state)?.Language;

// User
export const userRolesSelector = (state) => userSelector(state)?.roles || [];

// Admin
export const farmsAccessSelector = (state) => state.farmsAccess;
