/* eslint-disable consistent-return */
import ParticleSizeService from '../services/particleSizeService';

import { FILTER_CURRENT_PSC_TMRS, SET_CURRENT_PSC_TMRS, SET_TMR_HISTORY_LIST } from './actionTypes';

export const setTMRHistoryList = (historyList) => ({ type: SET_TMR_HISTORY_LIST, historyList });
export const setCurrentSamples = (selectedSamples) => ({ type: SET_CURRENT_PSC_TMRS, selectedSamples });
export const filterCurrentSamples = (selectedSamples) => ({ type: FILTER_CURRENT_PSC_TMRS, selectedSamples });

export const getTMRHistoryList = () => async (dispatch) => {
  try {
    const res = await ParticleSizeService.getTMRFarms();
    dispatch(setTMRHistoryList(res?.data?.tmr_farms || []));
  } catch (err) {
    console.error(err);
  }
};

export const getTMRSampleInfo = (tmr) => async (dispatch) => {
  try {
    const res = await ParticleSizeService.getTMRById(tmr);
    dispatch(setCurrentSamples(res?.data?.tmr));
  } catch (err) {
    console.error(err);
  }
};

export const resetCurrentSamples = () => (dispatch) => {
  dispatch(filterCurrentSamples([]));
};
