const userRoles = {
  ADMIN: { label: 'Admin', name: 'company_admin', localeKey: 'admin' },
  MILL_ADMIN: { label: 'Mill Admin', name: 'mill_admin', localeKey: 'millAdmin' },
  SUBSCRIBER: { label: 'Subscriber', name: 'subscriber', localeKey: 'subscriber' },
};

const adminRoles = {
  MANAGEMENT: { label: 'Management', name: 'management', localeKey: 'management' },
};

const roles = {};
Object.keys(userRoles).forEach((key) => {
  roles[key] = userRoles[key].name;
});

export { userRoles, adminRoles, roles };
