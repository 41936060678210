import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';

import HistoryHistogram from '../HistoryHistogram';

import SingleSampleFeedInfo from './SingleSampleFeedInfo';
import SingleSampleFeedStat from './SingleSampleFeedStat';

const SingleSampleFeedComponent = ({ selectedFeeds = [], currentInfo }) => (
  <Box component='div' sx={{ display: 'flex' }}>
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 350,
      }}
    >
      <Paper
        component='div'
        sx={{
          p: 2,
          mb: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <SingleSampleFeedInfo currentInfo={currentInfo || selectedFeeds?.[0]} />
      </Paper>
      <Paper
        component='div'
        sx={{
          p: 2,
          mt: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <SingleSampleFeedStat data={selectedFeeds ? selectedFeeds[0] : null} />
      </Paper>
    </Box>
    <Paper
      elevation={1}
      sx={{
        pb: 1,
        pt: 0,
        pr: 1,
        ml: { xs: 0, sm: 0, md: 2 },
        mt: { xs: 2, sm: 2, md: 0 },
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '> div': { flex: 1 },
      }}
    >
      <HistoryHistogram data={selectedFeeds ? selectedFeeds[0] : null} />
    </Paper>
  </Box>
);

export default SingleSampleFeedComponent;
