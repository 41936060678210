import { urls } from '../constants';

import axios from './axios';

const getFeedFarms = () => axios.get(urls.particleSize.farms);

const getTMRFarms = () => axios.get(urls.particleSize.tmrList);

const getFeedById = async (feed) => {
  const response = await axios.post(urls.particleSize.view, { ...feed });
  return response;
};

const getTMRById = async (tmr) => {
  const response = await axios.post(urls.particleSize.viewTMR, { ...tmr });
  return response;
};

const createFeedSample = async (obj) => {
  const response = await axios.post(urls.particleSize.feed, { ...obj });
  return response;
};

const createTMRSample = async (obj) => {
  const response = await axios.post(urls.particleSize.tmr, { ...obj });
  return response;
};

const particleSizeService = {
  getFeedFarms,
  getFeedById,
  createTMRSample,
  createFeedSample,
  getTMRFarms,
  getTMRById,
};

export default particleSizeService;
