import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { tmrFields } from '../../../constants/psc';
import { useSettings } from '../../../hooks';
import { StyledCard } from '../../shared/cards';

import SingleSampleTMRInfo from './SingleSampleTMRInfo';

const PREFIX = 'SampleTMRCard';

const classes = {
  root: `${PREFIX}-root`,
  expanded: `${PREFIX}-expanded`,
  root2: `${PREFIX}-root2`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    '&$expanded': {
      margin: '0 !important',
      minHeight: '48px !important',
    },
    borderTop: '1px solid #ececec',
    boxShadow: 'none',
  },

  [`& .${classes.expanded}`]: {},

  [`& .${classes.root2}`]: {
    padding: '8px 16px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  },
});

const boxSx = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row !important',
  pt: '4px',
  pb: '4px',
};

export default function SampleTMRCard(props) {
  const { data, sortedData, isSolo = false } = props;

  const { t } = useTranslation();
  const { formatDecimal } = useSettings();

  const StyledAccordion = Accordion;

  const AccordionDetails = MuiAccordionDetails;

  const ChipContainer = (chipProps) => (
    <Grid
      component='div'
      item
      md={4}
      sm={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      xs={4}
    >
      {chipProps.children}
    </Grid>
  );

  return (
    <Root>
      <StyledCard component={Paper}>
        <Typography component='h6'></Typography>
        <SingleSampleTMRInfo currentInfo={data} sx={{ mb: 1 }} />
        <Box component='div'>
          {isSolo &&
            tmrFields.map((field, idx) => (
              <Box
                component='div'
                key={`field_${idx}`}
                sx={{
                  ...boxSx,
                  borderTop: idx === 0 ? 'none' : '1px solid #eeeeee',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  component='span'
                  sx={{
                    mr: 1,
                    fontWeight: 'bold',
                  }}
                >
                  {t(`psc.${field}`)}
                </Typography>
                <Typography component='span'>{formatDecimal(data[field])}%</Typography>
              </Box>
            ))}
          {!isSolo &&
            tmrFields.map((field, idx) => (
              <StyledAccordion
                classes={{
                  root: classes.root,
                  expanded: classes.expanded,
                }}
                key={`acc_${idx}`}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  expandIcon={<ExpandMoreIcon />}
                  id={`acc_id_${idx}`}
                  sx={{
                    p: 0,
                    m: 0,
                    minHeight: '48px !important',
                    '> div': {
                      m: '0 !important',
                    },
                  }}
                >
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '95%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      component='span'
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {t(`psc.${field}`)}
                    </Typography>
                    <Typography component='span'>{formatDecimal(data[field])}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  classes={{
                    root: classes.root2,
                  }}
                >
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row !important',
                      gap: '16px',
                    }}
                  >
                    <ChipContainer>
                      <Chip label={`${t('feeds.avg')} ${sortedData[field]?.avg}`} />
                    </ChipContainer>
                    <ChipContainer>
                      <Chip label={`${t('feeds.sd')} ${sortedData[field]?.sd}`} />
                    </ChipContainer>
                    <ChipContainer>
                      <Chip label={`${t('feeds.cv')} ${sortedData[field]?.cv}`} />
                    </ChipContainer>
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
            ))}
        </Box>
      </StyledCard>
    </Root>
  );
}
