import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import * as React from 'react';

const TreeWrap = (props) => {
  const { children } = props;

  return (
    <TreeView
      aria-label='icon expansion'
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      {...props}
    >
      {children}
    </TreeView>
  );
};

export default TreeWrap;
