import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Select } from '../shared';

const FilterModal = (props) => {
  const { open, onClose, onChange, filters, farmOptions = [], actionOptions = [], statusOptions = [] } = props;

  const { t } = useTranslation();

  return (
    <Modal maxWidth='xs' onClose={onClose} open={open} title={t('activityLog.filterActivityLog')}>
      <Box component='div' sx={{ mt: 1, mb: 3 }}>
        <Select
          label={t('activityLog.farm')}
          onChange={(e) => onChange('farm', e.target.value)}
          options={farmOptions}
          value={filters.farm}
        />
      </Box>

      <Box component='div' sx={{ mb: 3 }}>
        <Select
          label={t('activityLog.action')}
          onChange={(e) => onChange('action', e.target.value)}
          options={actionOptions}
          value={filters.action}
        />
      </Box>

      <Box component='div' sx={{ mb: 1 }}>
        <Select
          label={t('activityLog.status')}
          onChange={(e) => onChange('status', e.target.value)}
          options={statusOptions}
          value={filters.status}
        />
      </Box>
    </Modal>
  );
};

export default FilterModal;
