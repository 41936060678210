/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import PrintIcon from '@mui/icons-material/Print';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SectionWrap } from '../../../components/admin-farm-visit';
import FarmVisitTree from '../../../components/admin-farm-visit/FarmVisitTree';
import LoadScreen from '../../../components/load-screen';
import { BottomNavigation, ConfirmationModal, Input, SideMenu } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { EMPTY_OPTION, EMPTY_QUESTION, EMPTY_SECTION } from '../../../constants/adminFarmVisit';
import { getUUID } from '../../../helpers';
import { formatToForm, formatToRequest } from '../../../helpers/farmVisit';
import { useAlert, useLoading } from '../../../hooks';
import { farmsSelector } from '../../../reducers/selectors';
import farmVisitService from '../../../services/farmVisitService';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TREE: 0,
  QUESTIONNAIRE: 1,
};

const AdminFarmVisitList = () => {
  const { t } = useTranslation();

  const [collapsed, setCollapse] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(tabs.TREE);
  const [sections, setSections] = useState([EMPTY_SECTION]);
  const [formTitle, setFormTitle] = useState('New Form');
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedFarmVisit, setSelectedFarmVisit] = useState();
  const [farmVisitList, setFarmVisitList] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isModalOpen, toggleModalOpen] = useState(false);

  const farms = useSelector(farmsSelector);
  const { farmsList = [] } = farms;

  const { showAlert } = useAlert();
  const { loading, startLoading, stopLoading } = useLoading(false);

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.QUESTIONNAIRE,
      label: t('adminFarmVisit.title'),
      icon: <TableIcon />,
    },
  ];

  const onLoad = async () => {
    try {
      startLoading();
      const resp = await farmVisitService.getFarmVisitQuestionnaireTemplate();
      const formattedResp = formatToForm({ questionnaire: resp.template });
      if (formattedResp?.title) {
        setFormTitle(formattedResp?.title);
      }
      if (formattedResp?.sections) {
        setSections(formattedResp?.sections);
      }

      const res = await farmVisitService.getAllFarmVisitResults();
      if (res?.questionnaires?.length) {
        setFarmVisitList(res.questionnaires);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoaded(true);
      stopLoading();
    }
  };

  // const loadVarmVisitsByFarm = async () => {
  //   try {
  //     startLoading();
  //     const resp = await farmVisitService.getFarmVisitByFarm(selectedFarm.farm_id);
  //     setFarmVisitsByFarm(resp.questionnaires);
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     stopLoading();
  //   }
  // };

  const fetchFarmVisitById = async (farm) => {
    try {
      startLoading();
      setSelectedFarmVisit(farm);
      const resp = await farmVisitService.getFarmVisitFormById(farm.id);
      const formattedResp = formatToForm(resp);
      if (formattedResp?.title) {
        setFormTitle(formattedResp?.title);
      }
      if (formattedResp?.sections) {
        setSections(formattedResp?.sections);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  // useEffect(() => {
  //   if (selectedFarm) {
  //     loadVarmVisitsByFarm();
  //   }
  // }, [selectedFarm]);

  const handleUpdateSection = (section, field, newValue) => {
    const foundIndex = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    sectionCopy[foundIndex][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateQuestion = (section, question, field, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (field === 'type' && !sectionCopy[foundIndexSect]?.questions[foundIndexQuest]?.options?.length) {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options = [];
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    }
    sectionCopy[foundIndexSect].questions[foundIndexQuest][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateOption = (section, question, index, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    Object.assign(sectionCopy[foundIndexSect].questions[foundIndexQuest].options[index], {
      label: newValue,
      id: getUUID(),
    });
    setSections(sectionCopy);
  };

  const handleOption = (section, question, index, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    } else {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.splice(index, 1);
    }
    setSections(sectionCopy);
  };

  const handleQuestion = (section, question, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions.push({
        ...EMPTY_QUESTION,
        id: getUUID(),
      });
    } else {
      const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
      sectionCopy[foundIndexSect].questions.splice(foundIndexQuest, 1);
    }
    setSections(sectionCopy);
  };

  const handleSection = (section, action = 'add') => {
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy.push({
        ...EMPTY_SECTION,
        id: getUUID(),
        questions: [
          {
            ...EMPTY_QUESTION,
            id: getUUID(),
          },
        ],
      });
    } else {
      const foundIndexSect = sections.findIndex((q) => q.id === section.id);
      sectionCopy.splice(foundIndexSect, 1);
    }
    setSections(sectionCopy);
  };

  const handleSave = async () => {
    try {
      startLoading();
      const formattedObject = formatToRequest(formTitle, sections);
      await farmVisitService.createNewAdminVisit(formattedObject);
      showAlert('success', t('adminFarmVisit.successAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const handleUpdate = async (id) => {
    try {
      startLoading();
      const formattedObject = formatToRequest(formTitle, sections);
      await farmVisitService.updateExistingFarmVisit(formattedObject, id);
      showAlert('success', t('adminFarmVisit.updateAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const handlePublish = async (id) => {
    try {
      startLoading();
      await farmVisitService.publishFarmVisit(id);
      showAlert('success', t('adminFarmVisit.publishAlert'));
      setSelectedFarmVisit({ ...selectedFarmVisit, Is_Published: true });
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const handleDelete = async (id) => {
    try {
      startLoading();
      await farmVisitService.deleteFarmVisit(id);
      resetForm();
      showAlert('success', t('adminFarmVisit.deleteAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      toggleModalOpen(false);
      setCurrentPage(tabs.TREE);
      stopLoading();
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isDisabled =
    !formTitle?.trim()?.length ||
    sections.filter(
      (sect) => !sect?.title?.trim()?.length || sect?.questions?.filter((q) => !q?.title?.trim()?.length)?.length
    ).length;

  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const resetForm = () => {
    setSelectedFarmVisit(undefined);
    onLoad();
  };

  const fabActions = useMemo(() => {
    if (selectedFarmVisit) {
      return [
        { icon: <SaveIcon />, name: t('save'), action: () => handleUpdate(selectedFarmVisit?.id) },
        ...(!selectedFarmVisit?.Is_Published
          ? [{ icon: <PublishIcon />, name: t('publish'), action: () => handlePublish(selectedFarmVisit?.id) }]
          : []),
        { icon: <DeleteIcon />, name: t('delete'), action: () => toggleModalOpen(true) },
      ];
    }
    return [{ icon: <SaveIcon />, name: t('save'), action: () => handleSave() }];
  }, [selectedFarmVisit]);

  const handleAction = (action) => {
    action();
    handleClose();
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        p: {
          lg: 0,
          md: 0,
          sm: 0,
          sx: 0,
        },
        position: 'relative',
      }}
    >
      {mobileView ? (
        <>
          <Box component='div' sx={getMobileStyles(tabs.TREE)}>
            <Box component='div' sx={{ pb: 2 }}>
              <FarmVisitTree
                mobileView={mobileView}
                onClear={resetForm}
                onClick={fetchFarmVisitById}
                results={farmVisitList}
                selected={selectedFarmVisit?.id}
              />
              {isLoaded && !farmVisitList?.length && <NoResults text={t('farmVisit.noResults')} />}
            </Box>
          </Box>
          {!selectedFarmVisit && !collapsed && (
            <Box component='div' sx={{ width: 'fit-content' }}>
              <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                {t('farmVisit.warningAlert')}
              </MuiAlert>
            </Box>
          )}
          {!!selectedFarmVisit && (
            <Box component='div' sx={getMobileStyles(tabs.QUESTIONNAIRE)}>
              <Box>
                <Typography
                  component='h6'
                  sx={{
                    margin: '8px 0 16px',
                    fontWeight: 'bold',
                  }}
                  variant='h6'
                >
                  {selectedFarmVisit
                    ? `${t('adminFarmVisit.title')} - ${selectedFarmVisit?.Title}`
                    : t('adminFarmVisit.create')}
                  {selectedFarmVisit?.Is_Published && (
                    <Typography
                      component={'span'}
                      sx={{
                        fontWeight: 'bold',
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    >
                      ({t('published')})
                    </Typography>
                  )}
                </Typography>

                <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                  <Button
                    color='error'
                    onClick={() => toggleModalOpen(true)}
                    startIcon={<DeleteIcon />}
                    sx={{ color: '#ffffff', fontSize: 11, fontWeight: 'bold', mr: 1 }}
                    variant='contained'
                  >
                    {t('delete')}
                  </Button>
                  {!selectedFarmVisit?.Is_Published && (
                    <Button
                      onClick={() => handlePublish(selectedFarmVisit?.id)}
                      startIcon={<PublishIcon />}
                      sx={{ color: '#ffffff', fontSize: 11, fontWeight: 'bold', mr: 1 }}
                      variant='contained'
                    >
                      {t('publish')}
                    </Button>
                  )}
                </Box>
              </Box>

              <SectionWrap
                handleOption={handleOption}
                handleQuestion={handleQuestion}
                handleSection={handleSection}
                handleUpdateOption={handleUpdateOption}
                handleUpdateQuestion={handleUpdateQuestion}
                handleUpdateSection={handleUpdateSection}
                mobileView={mobileView}
                sections={sections}
                setSections={setSections}
              />
              <Fab
                aria-label='add'
                color='primary'
                disabled={!!isDisabled}
                onClick={() => handleSave()}
                sx={{
                  margin: 0,
                  top: 'auto',
                  right: 20,
                  bottom: 20,
                  left: 'auto',
                  position: 'fixed',
                  fontWeight: 'bold',
                }}
                variant='extended'
              >
                <SaveIcon sx={{ mr: 1 }} />
                {t('submit')}
              </Fab>
            </Box>
          )}
          <BottomNavigation
            activeTab={currentPage}
            onChange={(event, newValue) => {
              setCurrentPage(newValue);
            }}
            tabs={navigationTabs}
          />
        </>
      ) : (
        <>
          <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
            <FarmVisitTree
              farmsList={farmsList}
              mobileView={mobileView}
              onClear={resetForm}
              onClick={fetchFarmVisitById}
              results={farmVisitList}
              selected={selectedFarmVisit?.id}
            />
          </SideMenu>

          <Container maxWidth={false} sx={{ p: containerPadding }}>
            {!selectedFarmVisit && !collapsed && (
              <Box component='div' sx={{ width: 'fit-content' }}>
                <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                  {t('farmVisit.warningAlert')}
                </MuiAlert>
              </Box>
            )}
            {!!selectedFarmVisit && (
              <Box component='div'>
                <Box
                  component='div'
                  sx={{
                    p: {
                      md: '0',
                      sm: '0',
                      xs: '0 16px',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h2>
                    {selectedFarmVisit
                      ? `${t('adminFarmVisit.title')} - ${selectedFarmVisit?.Title}`
                      : t('adminFarmVisit.create')}{' '}
                    {selectedFarmVisit?.Is_Published && (
                      <Typography
                        component={'span'}
                        sx={{
                          fontWeight: 'bold',
                          color: (theme) => theme?.palette?.primary?.main,
                        }}
                      >
                        ({t('published')})
                      </Typography>
                    )}
                  </h2>

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      color='error'
                      onClick={() => toggleModalOpen(true)}
                      startIcon={<DeleteIcon />}
                      sx={{ color: '#ffffff', fontSize: 11, fontWeight: 'bold', mr: 1 }}
                      variant='contained'
                    >
                      {t('delete')}
                    </Button>
                    {!selectedFarmVisit?.Is_Published && (
                      <Button
                        onClick={() => handlePublish(selectedFarmVisit?.id)}
                        startIcon={<PublishIcon />}
                        sx={{ color: '#ffffff', fontSize: 11, fontWeight: 'bold', mr: 1 }}
                        variant='contained'
                      >
                        {t('publish')}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box
                  component='div'
                  sx={{
                    p: {
                      md: '0',
                      sm: '0',
                      xs: '0 16px',
                    },
                  }}
                >
                  <Input
                    error={!formTitle?.length}
                    id='admin-farm-visit-form-title'
                    label={t('adminFarmVisit.formTitle')}
                    name='admin-farm-visit-form-title'
                    onChange={(e) => setFormTitle(e.target.value)}
                    sx={{
                      width: mobileView ? '100%' : '50%',
                      mb: 2,
                    }}
                    value={formTitle}
                  />
                </Box>
                <Box
                  component='div'
                  sx={{
                    mb: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '16px',
                    flexDirection: mobileView ? 'column' : 'row',
                  }}
                >
                  <SectionWrap
                    handleOption={handleOption}
                    handleQuestion={handleQuestion}
                    handleSection={handleSection}
                    handleUpdateOption={handleUpdateOption}
                    handleUpdateQuestion={handleUpdateQuestion}
                    handleUpdateSection={handleUpdateSection}
                    mobileView={mobileView}
                    sections={sections}
                    setSections={setSections}
                  />
                </Box>

                <Fab
                  aria-label='add'
                  color='primary'
                  disabled={!!isDisabled}
                  onClick={() => handleUpdate(selectedFarmVisit?.id)}
                  sx={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    fontWeight: 'bold',
                  }}
                  variant='extended'
                >
                  <SaveIcon sx={{ mr: 1 }} />
                  {t('submit')}
                </Fab>
              </Box>
            )}
          </Container>
        </>
      )}
      {(!isLoaded || loading) && <LoadScreen />}

      <ConfirmationModal
        onClose={() => toggleModalOpen(false)}
        onSubmit={() => handleDelete(selectedFarmVisit?.id)}
        open={isModalOpen}
        submitText={t('yesDelete')}
        text={t('adminFarmVisit.areYouSure', { name: selectedFarmVisit?.Title })}
      />
    </Container>
  );
};

export default AdminFarmVisitList;
