export const mixesUnits = {
  DRY_MATTER: 'DM_%mix',
  AS_FED: 'AF_%mix',
};

export const comparisonsUnits = {
  DRY_MATTER: 'DM_amount',
  AS_FED: 'AF_amount',
};

export const activityLogActions = {
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
};

export const activityLogStatuses = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const farmVisitFieldTypes = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  SCORE: 'score',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};
