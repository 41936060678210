import DeleteIcon from '@mui/icons-material/DeleteForever';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { QUESTION_TYPES, HAS_OPTIONS } from '../../constants/adminFarmVisit';
import { Accordion, Button, Input, Select } from '../shared';

const Questions = (props) => {
  const { section, sectionNum, handleUpdateQuestion, handleUpdateOption, handleOption, handleQuestion, mobileView } =
    props;

  const { t } = useTranslation();

  return (
    <Droppable droppableId={`droppable${section.id}`} type={`${sectionNum}`}>
      {(provided) => (
        <div ref={provided.innerRef}>
          <Stack component='div' spacing={2}>
            {section.questions.map((question, index) => (
              <Draggable draggableId={`${sectionNum}${index}`} index={index} key={`${sectionNum}${index}`}>
                {(drProvided) => (
                  <div ref={drProvided.innerRef} style={drProvided.draggableProps.style} {...drProvided.draggableProps}>
                    <Accordion
                      isCard
                      title={
                        <>
                          <Box
                            component='span'
                            sx={{
                              marginInlineEnd: '16px',
                              display: 'flex',
                            }}
                            {...drProvided.dragHandleProps}
                          >
                            <DragHandleIcon />
                          </Box>
                          {question.title}
                        </>
                      }
                    >
                      <Box
                        component='div'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 2,
                          gap: '16px',
                          flexWrap: mobileView ? 'wrap' : 'unset',
                        }}
                      >
                        <Input
                          error={!question.title?.length}
                          id={`question-title-${question.id}`}
                          label={t('adminFarmVisit.titleSection')}
                          name={`question-title-${question.id}`}
                          onChange={(e) => handleUpdateQuestion(section, question, 'title', e.target.value)}
                          sx={{
                            flexGrow: 1,
                          }}
                          value={question.title}
                        />
                        <Box
                          component='div'
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography component='div' sx={{ my: 2 }}>
                            {t('adminFarmVisit.required')}
                          </Typography>
                          <Switch
                            checked={!!question.required}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={() => handleUpdateQuestion(section, question, 'required', !question.required)}
                            value={!!question.required}
                          />
                        </Box>
                        <IconButton
                          aria-label='close'
                          color='inherit'
                          disabled={section?.questions?.length === 1}
                          onClick={() => handleQuestion(section, question, 'delete')}
                          sx={{
                            minWidth: '40px',
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Input
                        id={`section-desc-${question.id}`}
                        label={t('adminFarmVisit.descSection')}
                        multiline
                        name={`section-desc-${question.id}`}
                        onChange={(e) => handleUpdateQuestion(section, question, 'description', e.target.value)}
                        sx={{
                          width: '100%',
                          mb: 2,
                        }}
                        value={question.description}
                      />
                      <Select
                        id={`section-type-${question.id}`}
                        label={t('adminFarmVisit.type')}
                        name={`section-type-${question.id}`}
                        onChange={(e) => handleUpdateQuestion(section, question, 'type', e.target.value)}
                        options={QUESTION_TYPES}
                        value={question.type}
                      />
                      {HAS_OPTIONS.includes(question.type) && (
                        <>
                          <Typography component='div' sx={{ my: 2 }}>
                            {t('adminFarmVisit.options')}
                          </Typography>
                          {question?.options?.map((option, idx) => (
                            <Box
                              component='div'
                              key={`${sectionNum}_${idx}`}
                              sx={{
                                width: '100%',
                                mb: 2,
                                display: 'flex',
                              }}
                            >
                              <Input
                                error={!option?.label?.length}
                                id={`section-option-${question.id}-${idx}`}
                                label={`${t('adminFarmVisit.option')} ${idx + 1}`}
                                multiline
                                name={`section-opt-${question.id}-${idx}`}
                                onChange={(e) => handleUpdateOption(section, question, idx, e.target.value)}
                                sx={{
                                  width: '100%',
                                }}
                                value={option.label}
                              />
                              <IconButton
                                aria-label='close'
                                color='inherit'
                                disabled={question?.options.length <= 2}
                                onClick={() => handleOption(section, question, idx, 'delete')}
                                sx={{
                                  minWidth: '56px',
                                  marginLeft: '8px',
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                          <Button onClick={() => handleOption(section, question, null, 'add')}>
                            {t('adminFarmVisit.addOption')}
                          </Button>
                        </>
                      )}
                    </Accordion>
                  </div>
                )}
              </Draggable>
            ))}
          </Stack>
          {provided.placeholder}
          <Button
            onClick={() => handleQuestion(section, null, 'add')}
            sx={{
              mt: '16px',
            }}
          >
            {t('adminFarmVisit.addQuestion')}
          </Button>
        </div>
      )}
    </Droppable>
  );
};

export default Questions;
