import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Step1, Step2, Step3, Step4 } from '../../../components/body-condition-score';
import { Button } from '../../../components/shared';
import { bcsStepOptions } from '../../../constants/bcs';
import routes from '../../../constants/routes';
import { useLoading } from '../../../hooks';
import { farmsSelector } from '../../../reducers/selectors';
import { initLoad, createBCS } from '../../../services/bcsService';
import { containerPadding } from '../../../styles/theme/shared';

const initialValues = {
  Farm_ID: '',
  LocationName: '',
  CattleName: '',
  NumAnimals: 100,
  BCSSetting: {
    Percent_to_BCS: 0.1,
    BCSSystemId: bcsStepOptions[3].id,
  },
  BCS: [],
};

const BCSNewScorePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const farms = useSelector(farmsSelector);
  const { farmsList = [] } = farms;

  const { loading, startLoading, stopLoading } = useLoading(false);

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {}, []);

  const steps = [t('bcs.initialization'), t('bcs.settings'), t('bcs.scoring'), t('bcs.summary')];

  const onInit = async (formObj) => {
    startLoading();

    try {
      const data = {
        Farm_ID: formObj.Farm_ID,
        LocationName: formObj.LocationName,
        CattleName: formObj.CattleName,
      };
      const values = {
        ...formValues,
        Farm_ID: formObj.Farm_ID,
        LocationName: formObj.LocationName,
        CattleName: formObj.CattleName,
      };

      if (formValues.CattleName !== formObj.CattleName) {
        values.BCS = [];
      }

      const res = await initLoad(data);
      if (res) {
        setFormValues({
          ...values,
          NumAnimals: res.NumAnimals || 100,
          BCSSetting: {
            Percent_to_BCS: res?.BCSSetting?.Percent_to_BCS || 0.1,
            BCSSystemId: res?.BCSSetting?.BCSSystem?.id || bcsStepOptions[3].id,
          },
        });
      } else {
        setFormValues(values);
      }

      setActiveStep(1);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSetSettings = (formObj) => {
    const values = {
      ...formValues,
      NumAnimals: formObj.NumAnimals,
      BCSSetting: {
        Percent_to_BCS: formObj.Percent_to_BCS / 100,
        BCSSystemId: formObj.BCSSystemId,
      },
    };

    if (formValues?.BCSSetting?.BCSSystemId !== formObj.BCSSystemId) {
      values.BCS = [];
    }

    setFormValues(values);
    setActiveStep(2);
  };

  const onSubmit = async () => {
    startLoading();

    try {
      await createBCS(formValues);
      setActiveStep(4);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <Step1 farms={farmsList} formValues={formValues} loading={loading} onSubmit={onInit} />;
      case 1:
        return <Step2 formValues={formValues} onBack={() => setActiveStep(0)} onSubmit={onSetSettings} />;
      case 2:
        return (
          <Step3
            formValues={formValues}
            loading={loading}
            onBack={() => setActiveStep(1)}
            onSubmit={(bcs) => {
              setFormValues({ ...formValues, BCS: bcs });
              setActiveStep(3);
            }}
            resetScores={() => setFormValues({ ...formValues, BCS: [] })}
          />
        );
      case 3:
        return (
          <Step4
            farms={farmsList}
            formValues={formValues}
            loading={loading}
            onBack={() => setActiveStep(2)}
            onSubmit={onSubmit}
          />
        );
      case 4:
        return (
          <Box component='div' sx={{ mt: 2 }}>
            <MuiAlert severity='success'>
              <AlertTitle sx={{ fontWeight: 'bold' }}>{t('success')}</AlertTitle>
              {`${t('bcs.successAlert')}.`}
              <Typography
                component='span'
                onClick={() => navigate(routes.BCS_RESULTS)}
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#1d4620',
                  marginLeft: '6px',
                }}
              >
                {`${t('bcs.linkMessage')}.`}
              </Typography>
            </MuiAlert>
            <Box component='div' sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button
                onClick={() => {
                  setFormValues(initialValues);
                  setActiveStep(0);
                }}
              >
                {t('bcs.addNewScore')}
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const onStepClick = (index) => {
    if (index <= activeStep) {
      setActiveStep(index);
    }
  };

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{t('bcs.newScoreTitle')}</h2>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 2, pb: 3 }}>
        {steps?.map((label, index) => (
          <Step key={label} onClick={() => onStepClick(index)} sx={{ cursor: 'pointer !important' }}>
            <StepLabel sx={{ fontWeight: 'bold' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStep()}
    </Container>
  );
};

export default BCSNewScorePage;
