/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';

import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';

import {
  FeedHistoryTree,
  SingleSampleFeedComponent,
  SampleFeedsTable,
  SampleTMRColumnChart,
  SampleFeedCardList,
  HistoryHistogram,
} from '../../../components/particle-size-calculator';
import LoadScreen from '../../../components/load-screen';
import { SideMenu, BottomNavigation } from '../../../components/shared';
import { TabPanel, Tabs } from '../../../components/shared/tabs';

import { useLoading } from '../../../hooks';

import {
  filterCurrentFeeds,
  getFeedHistoryList,
  getFeedSampleInfo,
  resetCurrentFeeds,
} from '../../../actions/pscFeedHistoryActions';
import { particleSizeFeedHistorySelector } from '../../../reducers/selectors';

import { containerPadding } from '../../../styles/theme/shared';
import NoResults from '../../../components/shared/no-results';
import { routes } from '../../../constants';

const tabs = {
  TABLE: 0,
  GRAPH: 1,
  TREE: 2,
};

const FeedHistoryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const particleSizeData = useSelector(particleSizeFeedHistorySelector);

  const { historyList, selectedFeeds, isLoaded } = particleSizeData;

  const [collapsed, setCollapse] = useState(true);
  // const [collapsed, setCollapse] = useState(!selectedFeeds.length);
  const [currentPage, setCurrentPage] = useState(!selectedFeeds.length ? tabs.TREE : tabs.TABLE);
  const [fetching, setFetching] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs.TABLE);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const handleResultClick = async (info) => {
    if (selectedFeeds.find((q) => q.Feed_ID === info.Feed_ID && q.OADate === info.OADate)) {
      dispatch(
        filterCurrentFeeds(selectedFeeds.filter((q) => !(q.Feed_ID === info.Feed_ID && q.OADate === info.OADate)))
      );
      setFetching(false);
    } else {
      try {
        setFetching(true);
        await dispatch(getFeedSampleInfo(info));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getFeedHistoryList());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetCurrentFeeds());
    };
  }, []);

  const onClear = () => {
    dispatch(filterCurrentFeeds([]));
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('results'),
      icon: <TableIcon />,
      disabled: !selectedFeeds.length,
    },
    {
      value: tabs.GRAPH,
      label: t('graph'),
      icon: <ChartIcon />,
      disabled: !selectedFeeds.length,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          p: {
            lg: 0,
            md: 0,
            sm: 0,
            sx: 0,
          },
          position: 'relative',
        }}
      >
        {((loading && !isLoaded) || fetching) && <LoadScreen />}
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <FeedHistoryTree
                    historyList={historyList}
                    handleClick={handleResultClick}
                    currentResults={selectedFeeds}
                    onClear={onClear}
                    mobileView
                  />
                  {isLoaded && !historyList?.length && <NoResults text={t('psc.noResults')} url={routes.PSC} />}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    margin: '8px 0 16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('psc.historyFeed')}
                </Typography>

                <SampleFeedCardList selectedFeeds={selectedFeeds} />
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.GRAPH)}>
                {selectedFeeds.length === 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('psc.graphTitle')}
                    </Typography>
                    <Paper>
                      <HistoryHistogram data={selectedFeeds[0]} mobileView />
                    </Paper>
                  </Box>
                )}
                {selectedFeeds.length > 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 0',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('psc.graphTitle')}
                    </Typography>
                    <Paper sx={{ pb: 2, mt: 2, minHeight: 400 }}>
                      <SampleTMRColumnChart data={selectedFeeds} />
                    </Paper>
                  </Box>
                )}
              </Box>
              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              {!!historyList?.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <FeedHistoryTree
                    historyList={historyList}
                    handleClick={handleResultClick}
                    currentResults={selectedFeeds}
                    onClear={onClear}
                  />
                </SideMenu>
              )}
              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box component='div' sx={{ width: '100%' }}>
                    <h2>{t('psc.historyFeed')}</h2>

                    {!selectedFeeds?.length && !collapsed && (
                      <Box component='div' sx={{ width: 'fit-content' }}>
                        <MuiAlert variant='outlined' severity='warning' icon={<ArrowBackIcon />}>
                          {t('psc.warningAlert')}
                        </MuiAlert>
                      </Box>
                    )}
                    {isLoaded && !historyList?.length && <NoResults text={t('psc.noResults')} url={routes.PSC} />}
                    {selectedFeeds.length === 1 && <SingleSampleFeedComponent selectedFeeds={selectedFeeds} />}

                    {selectedFeeds.length > 1 && (
                      <Box component='div'>
                        <Tabs
                          activeTab={activeTab}
                          onChange={setActiveTab}
                          tabs={[
                            {
                              label: t('results'),
                              value: tabs.TABLE,
                              icon: <TableIcon />,
                            },
                            {
                              label: t('graph'),
                              value: tabs.GRAPH,
                              icon: <ChartIcon />,
                            },
                          ]}
                        />

                        <TabPanel value={activeTab} index={tabs.TABLE}>
                          <SampleFeedsTable data={selectedFeeds} />
                        </TabPanel>

                        <TabPanel value={activeTab} index={tabs.GRAPH}>
                          <Paper sx={{ pb: 2 }}>
                            <SampleTMRColumnChart data={selectedFeeds} />
                          </Paper>
                        </TabPanel>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Container>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default FeedHistoryPage;
