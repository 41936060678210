import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';

function formatLabel(value) {
  if (!value) {
    return '';
  }
  const parts = value.split(' – ');
  const firstPart = parts[0]
    .split(' ')
    .map((word) => word[0]?.toUpperCase())
    .join('');
  return `${firstPart} – ${parts.slice(1).join(' – ')}`;
}

const BarChart = ({ data, unit = '', print = false }) => {
  const labels = data.map((q) => q.label);
  const values = data.map((q) => q.value);

  const barData = {
    options: {
      chart: {
        id: `bar-chart-${Math.random()}`,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9db668'],
      xaxis: {
        categories: labels,
        labels: {
          formatter: (label) => formatLabel(label?.full) || label?.short || '',
        },
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
        y: {
          formatter: (value) => `${value} ${unit}`,
        },
        x: {
          formatter: (label) => label?.full || label?.short || '',
        },
      },
    },
    series: [
      {
        name: 'Value',
        data: values,
      },
    ],
  };

  useEffect(() => {
    const labels = document.querySelectorAll('.apexcharts-xaxis text');
    labels.forEach((label) => {
      label.innerHTML = label.innerHTML.replace(/\|/g, '<br/>');
    });
  }, [barData]);

  return (
    <Box
      component='div'
      sx={{
        minHeight: print ? '420px' : '100%',
      }}
    >
      <Chart height={print ? '100%' : '220%'} options={barData.options} series={barData.series} type='bar' />
    </Box>
  );
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  unit: PropTypes.string,
  print: PropTypes.bool,
};

export default BarChart;
