/* eslint-disable max-len */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useSettings } from '../../hooks';

export default function FarmsCard(props) {
  const { users, changes, handleChange, handleChangeDate, farm } = props;

  const { formatDate } = useSettings();

  const selected =
    changes.filter((el) => el.farm_id === farm.farm_id && el.grant_access === true)?.length === users?.length;
  const getChecked = (user) =>
    changes?.find((perm) => perm.farm_id === farm.farm_id && perm?.user_id === user?.user_id)?.grant_access;
  const getCheckedDate = (user) =>
    changes?.find((perm) => perm.farm_id === farm.farm_id && perm?.user_id === user?.user_id)?.expiration_date;

  const getDate = (user) => {
    const foundDate = changes.find(
      (perm) => perm.farm_id === farm.farm_id && perm?.user_id === user?.user_id
    )?.expiration_date;
    if (foundDate) {
      return formatDate(foundDate);
    }
    return '---';
  };

  return (
    <>
      <Accordion>
        <AccordionSummary aria-controls='panel1a-content' expandIcon={<ExpandMoreIcon />} id='panel1a-header'>
          <Checkbox
            checked={selected}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              handleChange(e.target.checked, farm, 'farm', null);
            }}
            onClick={(e) => e.stopPropagation()}
            sx={{
              padding: '0',
              marginInlineEnd: '16px',
              color: (theme) => `${theme?.palette?.primary?.main} !important`,
            }}
            value={selected}
          />
          <Typography>{farm?.farm_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {users.map((usr) => (
            <Box
              component='div'
              key={usr.user_id}
              sx={{
                display: 'flex',
                borderBlock: '0.5px solid #ececec',
                paddingBlockStart: '8px',
              }}
            >
              <Checkbox
                checked={getChecked(usr)}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => handleChange(e.target.checked, farm, 'date', usr)}
                sx={{
                  padding: '0',
                  marginInlineStart: '8px !important',
                  marginInlineEnd: '16px',
                  color: (theme) => `${theme?.palette?.primary?.main} !important`,
                }}
                value={getChecked(usr)}
              />
              <Box component='div' sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    wordBreak: 'break-all',
                  }}
                >
                  {usr?.email}
                </Typography>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={{ mb: 1 }}>{getDate(usr)}</Typography>
                  {getChecked(usr) && (
                    <IconButton
                      aria-label='delete'
                      onClick={() => handleChangeDate(usr, farm, getCheckedDate(usr))}
                      size='small'
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
