import { SET_MIXES_LIST, SET_MIXES_FEEDS, SET_CURRENT_MIXES, FILTER_CURRENT_MIXES } from '../actions/actionTypes';

const initialState = {
  currentMixes: [],
  feedList: [],
  mixList: [],
  isLoaded: false,
};

const mixesReducer = (state = initialState, action = {}) => {
  const { currentMixes, feedList, mixList } = action;

  switch (action.type) {
    case SET_CURRENT_MIXES:
      return {
        ...state,
        currentMixes: [...state.currentMixes, currentMixes],
      };
    case FILTER_CURRENT_MIXES:
      return {
        ...state,
        currentMixes,
      };
    case SET_MIXES_FEEDS:
      return {
        ...state,
        feedList,
      };
    case SET_MIXES_LIST:
      return {
        ...state,
        mixList,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default mixesReducer;
