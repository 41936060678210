import WarningIcon from '@mui/icons-material/ReportGmailerrorred';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button';

import { Transition, StyledActions, StyledDialog } from './styled';

const ConfirmationModal = (props) => {
  const {
    open,
    onClose = () => {},
    onSubmit = () => {},
    variant = 'warning',
    title = 'Are you sure?',
    text = '',
    closeText = '',
    submitText = '',
    loading,
    ...other
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledDialog {...other} TransitionComponent={Transition} fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <DialogContent sx={{ backgroundColor: theme?.palette?.primary?.greyLight }}>
        <Box component='div' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 2 }}>
          <WarningIcon color={variant} sx={{ fontSize: 100 }} />
        </Box>
        {title && (
          <Typography component='h6' sx={{ fontWeight: 'bold', mb: 2, fontSize: 20, textAlign: 'center' }}>
            {title}
          </Typography>
        )}
        <Typography component='div' sx={{ textAlign: 'center' }}>
          {text}
        </Typography>
      </DialogContent>
      <StyledActions>
        <Button onClick={onClose} variant='outlined'>
          {closeText || t('cancel')}
        </Button>
        <Button
          color={variant === 'error' ? 'error' : 'primary'}
          disabled={loading}
          loading={loading}
          onClick={onSubmit}
        >
          {submitText || t('confirm')}
        </Button>
      </StyledActions>
    </StyledDialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  closeText: PropTypes.string,
  submitText: PropTypes.string,
};

export default ConfirmationModal;
