import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import RecipeIcon from '@mui/icons-material/AssignmentOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CattleIcon from '../../../assets/HoofIcon';
import { useSettings } from '../../../hooks';

const SingleSampleTMRInfo = ({ currentInfo, sx = {} }) => {
  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const rowStyles = {
    display: 'flex',
    alignItems: 'center',
    pt: '4px',
    pb: '4px',
  };

  return (
    !!currentInfo && (
      <>
        <Box component='div' sx={{ ...sx }}>
          <Box component='div' sx={rowStyles}>
            <FarmIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography
              component='span'
              sx={{
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {t('farm')}:
            </Typography>
            <Typography component='span'>{currentInfo?.FarmName}</Typography>
          </Box>
          <Box component='div' sx={rowStyles}>
            <LocationIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography
              component='span'
              sx={{
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {t('location')}:
            </Typography>
            <Typography component='span'>{currentInfo?.LocationName}</Typography>
          </Box>
          <Box component='div' sx={rowStyles}>
            <CattleIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography
              component='span'
              sx={{
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {t('cattle')}:
            </Typography>
            <Typography component='span'>{currentInfo?.CattleName}</Typography>
          </Box>
          <Box component='div' sx={rowStyles}>
            <RecipeIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography
              component='span'
              sx={{
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {t('recipe')}:
            </Typography>
            <Typography component='span'>{currentInfo?.RecipeName}</Typography>
          </Box>
          <Box component='div' sx={rowStyles}>
            <DateIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography
              component='span'
              sx={{
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {t('date')}:
            </Typography>
            <Typography component='span'>{formatDate(currentInfo?.OADate, true, true)}</Typography>
          </Box>
        </Box>
      </>
    )
  );
};

export default SingleSampleTMRInfo;
