import { urls } from '../constants';

import axios from './axios';

const getFarmVisitForm = async (farmId) => {
  const response = await axios.post(urls.adminFarmVisit.get, { Farm_ID: farmId });
  return response?.data;
};

const getFarmVisitFormById = async (id) => {
  const response = await axios.get(`${urls.adminFarmVisit.get}/${id}`);
  return response?.data;
};

const getFarmVisitByFarm = async (id) => {
  const response = await axios.get(`${urls.adminFarmVisit.getByFarm}/${id}/questionnaires`);
  return response?.data;
};

const getFarmVisitQuestionnaireTemplate = async () => {
  const response = await axios.get(`${urls.adminFarmVisit.getTemplate}`);
  return response?.data;
};

const updateExistingFarmVisit = async (obj, id) => {
  const response = await axios.patch(`${urls.adminFarmVisit.questionnaires}/${id}`, { ...obj });
  return response?.data;
};

const createNewAdminVisit = async (obj) => {
  const response = await axios.post(urls.adminFarmVisit.create, { ...obj });
  return response;
};

const publishFarmVisit = async (id) => {
  const response = await axios.get(`${urls.adminFarmVisit.questionnaires}/${id}/publish`);
  return response;
};

const deleteFarmVisit = async (id) => {
  const response = await axios.delete(`${urls.adminFarmVisit.questionnaires}/${id}`);
  return response;
};

const getAdminVisit = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const Customer_ID = `${user?.customer_id}`;
  const response = await axios.post(urls.adminFarmVisit.get);
  return response?.data;
};

const createNewResult = async (obj) => {
  const response = await axios.post(urls.farmVisit.create, { ...obj });
  return response;
};

const getFarmVisitResults = async () => {
  const response = await axios.get(urls.farmVisit.getResults);
  return response?.data;
};

const getFarmVisitResult = async (obj) => {
  const response = await axios.post(urls.farmVisit.getResult, { ...obj });
  return response?.data;
};

const getAllFarmVisitResults = async () => {
  const response = await axios.get(urls.adminFarmVisit.questionnaires);
  return response?.data;
};

const farmVisitService = {
  createNewAdminVisit,
  getAdminVisit,
  getFarmVisitForm,
  getFarmVisitFormById,
  createNewResult,
  getFarmVisitResults,
  getFarmVisitResult,
  getFarmVisitByFarm,
  getFarmVisitQuestionnaireTemplate,
  updateExistingFarmVisit,
  publishFarmVisit,
  deleteFarmVisit,
  getAllFarmVisitResults,
};

export default farmVisitService;
