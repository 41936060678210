/* eslint-disable */
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { useSettings } from '../../../hooks';

const SampleTMRPefChart = ({ data = {} }) => {
  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const formatData = useMemo(() => {
    let labels = data.map((sample) => formatDate(sample.OADate, true, true));
    let values = [
      {
        data: data.map((sample) => sample?.pef?.toFixed(2)),
        name: t('psc.pef'),
      },
    ];

    return {
      labels,
      values,
    };
  }, [data]);

  const barData = {
    options: {
      chart: {
        id: 'tmr-history-pef',
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      colors: ['#9db668'],
      xaxis: {
        categories: formatData.labels,
        tickPlacement: 'between',
      },
      yaxis: {
        labels: {
          formatter: (val) => `${val}%`,
        },
        max: 100,
        min: 0,
      },
    },
    series: formatData?.values,
  };

  return (
    <Box
      component='div'
      sx={{
        minHeight: '400px',
      }}
    >
      <Chart options={barData.options} series={barData.series} type='line' height='100%' width='100%' />
    </Box>
  );
};

export default SampleTMRPefChart;
