import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';

import { StyledCard } from '../../shared/cards';

import SingleSampleFeedInfo from './SingleSampleFeedInfo';
import SingleSampleFeedStat from './SingleSampleFeedStat';

export default function SampleFeedCard(props) {
  const { data } = props;
  const { OADate, FarmName, FeedName } = data;

  return (
    <>
      <StyledCard component={Paper}>
        <Typography component='h6'></Typography>
        <SingleSampleFeedInfo
          currentInfo={{
            FarmName,
            FeedName,
            OADate,
          }}
          mobileView
        />
        <Box component='div' sx={{ mt: 2, mb: 2 }} />
        <SingleSampleFeedStat data={data} />
      </StyledCard>
    </>
  );
}
