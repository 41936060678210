import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import RecipeIcon from '@mui/icons-material/AssignmentOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CattleIcon from '../../../assets/HoofIcon';
import { useSettings } from '../../../hooks';
import Tree from '../../shared/tree';

const TMRHistoryTree = (props) => {
  const { historyList = [], handleClick, mobileView, currentResults, onClear } = props;

  const { formatDate } = useSettings();
  const { t } = useTranslation();

  const treeData = useMemo(
    () =>
      historyList?.map((item) => ({
        id: item.farm_id,
        label: (
          <Box component='div'>
            <FarmIcon fontSize='small' /> {item.farm_name}
          </Box>
        ),
        children: item.tmr_index?.map((loc) => ({
          id: `${item.farm_id}${loc.location_name}`,
          label: (
            <Box component='div'>
              <LocationIcon fontSize='small' /> {loc.location_name}
            </Box>
          ),
          children: loc.cattles?.map((cat) => ({
            id: `${item.farm_id}${loc.location_name}${cat.cattle_name}`,
            label: (
              <Box component='div'>
                <CattleIcon fontSize='small' /> {cat.cattle_name}
              </Box>
            ),
            children: cat?.tmr_versions
              .reduce((acc, val) => {
                const indexOfAcc = acc.findIndex((el) => el.recipe_name === val.recipe_name);
                if (indexOfAcc >= 0) {
                  acc[indexOfAcc].dates.push(val.oadate);
                } else {
                  acc.push({
                    recipe_name: val.recipe_name,
                    dates: [val.oadate],
                  });
                }
                return acc;
              }, [])
              ?.map((rec) => ({
                id: `${cat.cattle_name}${rec.recipe_name}`,
                label: (
                  <Box component='div'>
                    <RecipeIcon fontSize='small' /> {rec.recipe_name}
                  </Box>
                ),
                children: rec.dates?.map((date) => ({
                  id: `${rec.recipe_name}${date}`,
                  selected: currentResults.find((q) => q.RecipeName === rec.recipe_name && q.OADate === date),
                  label: (
                    <Box component='div'>
                      <DateIcon fontSize='small' />
                      <span style={{ display: 'flex' }}>{formatDate(date, true)}</span>
                    </Box>
                  ),
                  onClick: () =>
                    handleClick({
                      Farm_ID: item.farm_id,
                      LocationName: loc.location_name,
                      CattleName: cat.cattle_name,
                      OADate: date,
                      RecipeName: rec.recipe_name,
                      FarmName: item.farm_name,
                    }),
                })),
              })),
          })),
        })),
      })),
    [historyList, currentResults]
  );

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('psc.selectSample')}
        </Typography>
        {currentResults && !!currentResults?.length && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default TMRHistoryTree;
