import UserService from '../services/userService';

import { SET_EXACT_RECIPE, SET_FARMS_LIST, SET_PICKED_FARMS } from './actionTypes';

export const setFarmsList = (farmsList) => ({ type: SET_FARMS_LIST, farmsList });
export const setPickedFarms = (pickedFarms) => ({ type: SET_PICKED_FARMS, pickedFarms });
export const setExactFarms = (exactFarm) => ({ type: SET_EXACT_RECIPE, exactFarm });

export const getAllFarms = () => async (dispatch) => {
  try {
    const res = await UserService.getUsersFarmRations();
    dispatch(setFarmsList(res?.data?.permitted_farms));
  } catch (err) {
    console.error(err);
  }
};

export const setUserFarms = (farms) => async (dispatch) => {
  try {
    dispatch(setPickedFarms(farms));
  } catch (err) {
    throw new Error('Server Error');
  }
};

export const setExactRecipe = (farms) => async (dispatch) => {
  try {
    dispatch(setExactFarms(farms));
  } catch (err) {
    throw new Error('Server Error');
  }
};
