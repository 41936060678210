import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';

const CheckboxGroup = ({ selected = [], onChange, options = [] }) => (
  <FormGroup>
    {options?.map((option) => (
      <FormControlLabel
        control={
          <Checkbox checked={selected?.includes(option.value)} name={option.value} onChange={onChange} size='small' />
        }
        key={option.value}
        label={option.label}
      />
    ))}
  </FormGroup>
);

export default CheckboxGroup;
