import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Select } from '../shared';

const Step1 = (props) => {
  const { formValues, onSubmit, loading, farms = [] } = props;

  const { t } = useTranslation();

  const [selectedFarm, setSelectedFarm] = useState(formValues.Farm_ID || null);
  const [selectedLocation, setSelectedLocation] = useState(formValues.LocationName || null);

  const form = useFormik({
    initialValues: {
      Farm_ID: formValues.Farm_ID || '',
      LocationName: formValues.LocationName || '',
      CattleName: formValues.CattleName || '',
    },
    onSubmit,
  });

  const locationOptions = useMemo(() => {
    if (!selectedFarm) {
      return [];
    }
    const farm = farms?.find((f) => f.farm_id === selectedFarm);
    return farm && farm.locations
      ? farm.locations?.map((location) => ({
          label: location?.location_name || '',
          value: location?.location_name,
        }))
      : [];
  }, [selectedFarm]);

  const cattleOptions = useMemo(() => {
    if (!selectedFarm || !selectedLocation) {
      return [];
    }
    const farm = farms?.find((f) => f.farm_id === selectedFarm);
    if (!farm || !farm.locations) {
      return [];
    }
    const location = farm.locations.find((l) => l.location_name === selectedLocation);
    return location && location.cattles
      ? location.cattles?.map((cattle) => ({
          label: cattle?.cattle_name || '',
          value: cattle?.cattle_name,
        }))
      : [];
  }, [selectedFarm, selectedLocation]);

  return (
    <form onSubmit={form.onSubmit}>
      <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component='div'
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', sm: '100%', md: '500px' },
          }}
        >
          <Stack component='div' spacing={2}>
            <Select
              id='farm-select'
              label={t('farm')}
              name='Farm_ID'
              onChange={(e) => {
                form.handleChange(e);
                form.setFieldValue('LocationName', '');
                form.setFieldValue('CattleName', '');
                setSelectedFarm(e.target.value);
                setSelectedLocation(null);
              }}
              options={farms?.map((farm) => ({ label: farm?.farm_name, value: farm?.farm_id }))}
              value={form.values.Farm_ID}
            />
            <Select
              disabled={!selectedFarm}
              id='location-select'
              label={t('location')}
              name='LocationName'
              onChange={(e) => {
                form.handleChange(e);
                form.setFieldValue('CattleName', '');
                setSelectedLocation(e.target.value);
              }}
              options={locationOptions}
              value={form.values.LocationName}
            />
            <Select
              disabled={!selectedLocation}
              id='cattle-select'
              label={t('cattle')}
              name='CattleName'
              onChange={form.handleChange}
              options={cattleOptions}
              value={form.values.CattleName}
            />
          </Stack>
          <Box component='div' sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!form.values.Farm_ID || !form.values.LocationName || !form.values.CattleName || loading}
              loading={loading}
              onClick={() => onSubmit(form.values)}
              type='submit'
            >
              {t('next')}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default Step1;
