/* eslint-disable consistent-return */
import farmVisitService from '../services/farmVisitService';

import { SET_FARM_VISIT_RESULTS, SET_CURRENT_FARM_VISIT_RESULT } from './actionTypes';

export const setResults = (results) => ({ type: SET_FARM_VISIT_RESULTS, results });
export const setCurrentResult = (result) => ({ type: SET_CURRENT_FARM_VISIT_RESULT, result });

export const getResults = () => async (dispatch) => {
  try {
    const res = await farmVisitService.getFarmVisitResults();
    dispatch(setResults(res?.answers_farms || []));
  } catch (err) {
    console.error(err);
  }
};
