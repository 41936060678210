import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledContainer, ArrowContainer } from './styled';

const SideMenu = (props) => {
  const { collapsed, setCollapse, children } = props;

  return (
    <StyledContainer>
      <Box component='div' sx={{ display: 'flex', backgroundColor: '#f9f9f9' }}>
        <Collapse in={collapsed} orientation='horizontal'>
          <Box component='div' sx={{ marginTop: 8, maxHeight: 'calc(100% - 64px)', overflow: 'auto' }}>
            {children}
          </Box>
        </Collapse>

        <ArrowContainer
          onClick={() => setCollapse(!collapsed)}
          sx={{
            background: '#9db6681a',
            borderLeft: collapsed ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
            boxShadow: collapsed ? '7px 0 15px -2px rgb(0 0 0 / 15%)' : 'none',
            '&:hover': {
              background: '#9db6681a',
            },
          }}
        >
          {collapsed ? <ChevronLeftIcon sx={{ fontSize: 30 }} /> : <ChevronRightIcon sx={{ fontSize: 30 }} />}
        </ArrowContainer>
      </Box>
    </StyledContainer>
  );
};

SideMenu.propTypes = {
  collapsed: PropTypes.bool,
  setCollapse: PropTypes.func,
};

export default SideMenu;
