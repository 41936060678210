import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

export const StyledCard = styled(Paper)(() => ({
  paddingTop: 4,
  marginBottom: 16,
  span: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  svg: {
    marginRight: 4,
  },
}));

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#ffffff' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme?.palette?.primary?.danger,
  color: theme?.palette?.primary?.contrastText,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '8px 16px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
