import {
  FILTER_CURRENT_FEEDS,
  SET_CURRENT_FEEDS,
  SET_FEEDS,
  SET_FEEDS_LIST,
  SET_FEEDS_TEMPLATE,
} from '../actions/actionTypes';

const initialState = {
  feedsList: [],
  currentFeeds: [],
  feedInfo: [],
  templateList: [],
  isLoaded: false,
};

const feedsReducer = (state = initialState, action = {}) => {
  const { feedsList, currentFeeds, feedInfo, templateList } = action;

  switch (action.type) {
    case SET_FEEDS_LIST:
      return {
        ...state,
        feedsList,
        isLoaded: true,
      };
    case SET_CURRENT_FEEDS:
      return {
        ...state,
        currentFeeds: [...state.currentFeeds, currentFeeds],
      };
    case FILTER_CURRENT_FEEDS:
      return {
        ...state,
        currentFeeds,
      };
    case SET_FEEDS:
      return {
        ...state,
        feedInfo,
      };
    case SET_FEEDS_TEMPLATE:
      return {
        ...state,
        templateList,
      };
    default:
      return state;
  }
};

export default feedsReducer;
