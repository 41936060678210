import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

import { Copyright } from '../../components/auth';
import { Button } from '../../components/shared';
import { SSO_URL } from '../../constants/urls';

export default function LoginPage() {
  const handleSsoLogin = () => {
    window.location.href = `${SSO_URL}?redirect=${window.location.origin}`;
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          display: 'flex',
          minHeight: '80vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Button onClick={handleSsoLogin} sx={{ m: 6 }}>
          Sign in to AMTS On-line
        </Button>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
