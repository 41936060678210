import AuthService from '../services/authService';

import { SET_CURRENT_USER, SIGN_OUT } from './actionTypes';

export const setCurrentUser = (user) => ({ type: SET_CURRENT_USER, user });

export const getCurrentUser = (q) => async (dispatch) => {
  let user;
  if (q) {
    user = await AuthService.getUserFromAdmin(q);
  } else {
    user = await AuthService.getCurrentUser();
  }
  dispatch(setCurrentUser(user || undefined));
};

export const signIn = (email, password) => async (dispatch) => {
  try {
    await AuthService.login(email, password);
    dispatch(getCurrentUser());
  } catch (error) {
    const resMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    throw resMessage;
  }
};

export const signOut = () => ({ type: SIGN_OUT });
