/* eslint-disable */
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

const HistoryHistogram = ({ data = {}, mobileView = false }) => {
  const { t } = useTranslation();

  const formatData = useMemo(
    () => ({
      [t('psc.topPan')]: data.TopPercent,
      [t('psc.secondPan')]: data.SecondPanPercent,
      [t('psc.thirdPan')]: data.ThirdPanPercent,
      [t('psc.bottomPan')]: data.BottomPanPercent,
    }),
    [data]
  );

  const barData = {
    options: {
      chart: {
        id: 'feed-history-histogram',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9db668'],
      xaxis: {
        categories: Object.keys(formatData),
      },
      yaxis: {
        labels: {
          formatter: (val) => `${val}%`,
        },
      },
    },
    series: [
      {
        name: 'Value',
        data: Object.values(formatData)?.map((value) => value.toFixed(2)),
      },
    ],
  };

  return (
    <Box
      component='div'
      sx={{
        minHeight: mobileView ? '400px' : '100%',
      }}
    >
      <Chart options={barData.options} series={barData.series} type='bar' height='100%' />
    </Box>
  );
};

export default HistoryHistogram;
