/* eslint-disable consistent-return */

import ParticleSizeService from '../services/particleSizeService';
import UserService from '../services/userService';

import {
  FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED,
  PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT,
  PARTICLE_SIZE_SINGLE_FEED_SET_LIST,
} from './actionTypes';

export const setFeedList = (feedsList) => ({ type: PARTICLE_SIZE_SINGLE_FEED_SET_LIST, feedsList });
export const setCurrentFeeds = (currentFeeds) => ({ type: PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT, currentFeeds });
export const filterCurrentFeeds = (currentFeeds) => ({ type: FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED, currentFeeds });

export const getFeedList = () => async (dispatch) => {
  try {
    const res = await UserService.getFeedsList();
    dispatch(setFeedList(res?.data?.feed_view_index));
    return res?.data?.feed_view_index;
  } catch (err) {
    console.error(err);
  }
};

export const getFeedsInfoById = (obj) => async (dispatch) => {
  try {
    const res = await ParticleSizeService.getFeedById(obj);
    dispatch(setCurrentFeeds({ ...obj, feedInfo: res?.data?.feed?.feed_data }));
  } catch (err) {
    console.error(err);
  }
};

export const resetPSFeedsStore = () => (dispatch) => {
  try {
    dispatch(filterCurrentFeeds([]));
  } catch (err) {
    console.error(err);
  }
};
