import { SET_CURRENT_COMPARISONS, FILTER_CURRENT_COMPARISONS } from '../actions/actionTypes';

const initialState = {
  currentComparisons: [],
  isLoaded: false,
};

const comparisonsReducer = (state = initialState, action = {}) => {
  const { currentComparisons } = action;

  switch (action.type) {
    case SET_CURRENT_COMPARISONS:
      return {
        ...state,
        currentComparisons: [...state.currentComparisons, currentComparisons],
        isLoaded: true,
      };
    case FILTER_CURRENT_COMPARISONS:
      return {
        ...state,
        currentComparisons,
      };
    default:
      return state;
  }
};

export default comparisonsReducer;
