import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Transition, StyledDialog } from './styled';

const ViewPdfModal = (props) => {
  const { open, onClose = () => {}, title = '', url = '' } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [numPages, setNumPages] = useState(null);
  const [, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (options) => {
    setNumPages(options?.numPages);
  };

  const handleClose = () => {
    setNumPages(null);
    setPageNumber(1);
    onClose();
  };

  return (
    <StyledDialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{ '.MuiDialog-paper': { height: '100%', maxWidth: '800px' } }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ paddingLeft: '0px !important', paddingRight: '8px !important' }}>
          <DialogTitle id='responsive-dialog-title' sx={{ flex: 1, fontWeight: 'bold' }}>
            {title}
          </DialogTitle>
          {!fullScreen && (
            <IconButton
              aria-label='open'
              color='inherit'
              edge='start'
              onClick={() => window.open(url, '_blank')}
              sx={{ mr: 2 }}
            >
              <OpenInNewIcon />
            </IconButton>
          )}
          <IconButton aria-label='close' color='inherit' edge='start' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ backgroundColor: theme?.palette?.primary?.greyLight }}>
        <Box component='div' sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <Document
            file={url}
            loading={
              <Box component='div' sx={{ mt: 10 }}>
                <CircularProgress />
              </Box>
            }
            noData=''
            onLoadError={console.error}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {numPages &&
              new Array(numPages)?.fill('')?.map((i, index) => (
                <Box component='div' key={index} sx={{ mb: 2, position: 'relative' }}>
                  <Page
                    loading={null}
                    noData=''
                    pageNumber={index + 1}
                    width={fullScreen ? window?.screen?.width - 32 : 760}
                  />
                </Box>
              ))}
          </Document>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

ViewPdfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default ViewPdfModal;
