/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LoadScreen from '../../../components/load-screen';
import { SideMenu, BottomNavigation } from '../../../components/shared';
import { TabPanel, Tabs } from '../../../components/shared/tabs';

import { useLoading } from '../../../hooks';

import { particleSizeTMRHistorySelector } from '../../../reducers/selectors';

import {
  filterCurrentSamples,
  getTMRHistoryList,
  getTMRSampleInfo,
  resetCurrentSamples,
} from '../../../actions/pscTMRHistoryActions';

import {
  TMRHistoryTree,
  SingleSampleTMRComponent,
  SampleTMRTable,
  SampleTMRChartsContainer,
} from '../../../components/particle-size-calculator/tmr';
import { HistoryHistogram } from '../../../components/particle-size-calculator';

import { containerPadding } from '../../../styles/theme/shared';
import SampleTMRList from '../../../components/particle-size-calculator/tmr/SampleTMRList';
import NoResults from '../../../components/shared/no-results';
import { routes } from '../../../constants';

const tabs = {
  TABLE: 0,
  GRAPH: 1,
  TREE: 2,
};

const FeedHistoryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const particleSizeData = useSelector(particleSizeTMRHistorySelector);

  const { historyList, selectedSamples, isLoaded } = particleSizeData;

  const [collapsed, setCollapse] = useState(true);
  // const [collapsed, setCollapse] = useState(!selectedSamples?.length);
  const [currentPage, setCurrentPage] = useState(!selectedSamples.length ? tabs.TREE : tabs.TABLE);
  const [fetching, setFetching] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs.TABLE);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const handleResultClick = async (info) => {
    if (selectedSamples.find((q) => q.RecipeName === info.RecipeName && q.OADate === info.OADate)) {
      dispatch(
        filterCurrentSamples(
          selectedSamples.filter((q) => !(q.RecipeName === info.RecipeName && q.OADate === info.OADate))
        )
      );
      setFetching(false);
    } else {
      try {
        setFetching(true);
        await dispatch(getTMRSampleInfo(info));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getTMRHistoryList());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetCurrentSamples());
    };
  }, []);

  const onClear = () => {
    dispatch(filterCurrentSamples([]));
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('results'),
      icon: <TableIcon />,
      disabled: !selectedSamples.length,
    },
    {
      value: tabs.GRAPH,
      label: t('graph'),
      icon: <ChartIcon />,
      disabled: !selectedSamples.length,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          p: {
            lg: 0,
            md: 0,
            sm: 0,
            sx: 0,
          },
          position: 'relative',
        }}
      >
        {((loading && !isLoaded) || fetching) && <LoadScreen />}
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <TMRHistoryTree
                    historyList={historyList}
                    handleClick={handleResultClick}
                    currentResults={selectedSamples}
                    onClear={onClear}
                    mobileView
                  />
                  {isLoaded && !historyList?.length && <NoResults text={t('psc.noResultsTMR')} url={routes.PSC} />}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    margin: '8px 0 16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('psc.historyTMR')}
                </Typography>
                <SampleTMRList data={selectedSamples} />
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.GRAPH)}>
                {selectedSamples?.length === 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('psc.graphTitle')}
                    </Typography>
                    <Paper
                      elevation={1}
                      sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '> div': { flex: 1 },
                      }}
                    >
                      <HistoryHistogram data={selectedSamples ? selectedSamples[0] : null} mobileView />
                    </Paper>
                  </Box>
                )}
                {selectedSamples?.length > 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('psc.graphTitle')}
                    </Typography>
                    <SampleTMRChartsContainer data={selectedSamples} />
                  </Box>
                )}
              </Box>
              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              {!!historyList?.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <TMRHistoryTree
                    historyList={historyList}
                    handleClick={handleResultClick}
                    currentResults={selectedSamples}
                    onClear={onClear}
                  />
                </SideMenu>
              )}
              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box component='div' sx={{ width: '100%' }}>
                    <h2>{t('psc.historyTMR')}</h2>

                    {!selectedSamples?.length && !collapsed && (
                      <Box component='div' sx={{ width: 'fit-content' }}>
                        <MuiAlert variant='outlined' severity='warning' icon={<ArrowBackIcon />}>
                          {t('psc.warningAlert')}
                        </MuiAlert>
                      </Box>
                    )}
                    {isLoaded && !historyList?.length && <NoResults text={t('psc.noResultsTMR')} url={routes.PSC} />}
                    {selectedSamples.length === 1 && <SingleSampleTMRComponent selectedSamples={selectedSamples} />}

                    {selectedSamples.length > 1 && (
                      <Box component='div'>
                        <Tabs
                          activeTab={activeTab}
                          onChange={setActiveTab}
                          tabs={[
                            {
                              label: t('results'),
                              value: tabs.TABLE,
                              icon: <TableIcon />,
                            },
                            {
                              label: t('graph'),
                              value: tabs.GRAPH,
                              icon: <ChartIcon />,
                            },
                          ]}
                        />

                        <TabPanel value={activeTab} index={tabs.TABLE}>
                          <SampleTMRTable data={selectedSamples} />
                        </TabPanel>

                        <TabPanel value={activeTab} index={tabs.GRAPH}>
                          <SampleTMRChartsContainer data={selectedSamples} />
                        </TabPanel>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Container>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default FeedHistoryPage;
