import { SET_USER_SETTINGS } from '../actions/actionTypes';
import {
  globalUnits,
  currencyUnits,
  dateFormats,
  decimalFormats,
  energyUnits,
  languages,
  decimalPrecisionFormats,
} from '../constants/settings';

export const initialState = {
  GlobalUnit: globalUnits.METRIC,
  CurrencyUnit: currencyUnits.DOLLAR,
  MinorCurrencyUnit: currencyUnits.DOLLAR,
  EnergyUnit: energyUnits.MCAL,
  ForagesDecimalPrecision: decimalPrecisionFormats.TWO_DIGITS,
  ConcentratesDecimalPrecision: decimalPrecisionFormats.TWO_DIGITS,
  MineralsDecimalPrecision: decimalPrecisionFormats.TWO_DIGITS,
  MixesDecimalPrecision: decimalPrecisionFormats.TWO_DIGITS,
  DecimalFormat: decimalFormats.DOT,
  DateFormat: dateFormats.Md,
  Language: languages.ENGLISH,
};

const currentUserReducer = (state = initialState, action = {}) => {
  const { settings } = action;

  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,
        ...settings,
      };
    default:
      return state;
  }
};

export default currentUserReducer;
