import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

import './accordion.scss';

const Accordion = ({ title, children, defaultOpen = false, isCard = false, mobileView = false }) => {
  const [isOpen, setOpen] = React.useState(defaultOpen);

  return (
    <div className={`accordion-wrapper ${mobileView ? 'accordion-mobile' : ''} ${isCard ? 'accordion-card' : ''}`}>
      <div className={`accordion-title ${isOpen ? 'open' : ''}`} onClick={() => setOpen(!isOpen)}>
        {title}
        <div className={`accordion-title-icon ${isOpen ? 'open' : ''}`}>
          <ExpandMoreIcon />
        </div>
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className='accordion-content'>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
