/* eslint-disable consistent-return */
import { getBcsResults, getBcsView } from '../services/bcsService';

import { FILTER_CURRENT_BCS_RESULT, SET_BCS_RESULTS_LIST, SET_CURRENT_BCS_RESULT } from './actionTypes';

export const setResults = (resultsList) => ({ type: SET_BCS_RESULTS_LIST, resultsList });
export const setCurrentResults = (pickedResults) => ({ type: SET_CURRENT_BCS_RESULT, pickedResults });
export const filterCurrentResults = (pickedResults) => ({ type: FILTER_CURRENT_BCS_RESULT, pickedResults });

export const getBcsResultsList = () => async (dispatch) => {
  try {
    const res = await getBcsResults();
    dispatch(setResults(res?.data?.bcs_farms));
  } catch (err) {
    console.error(err);
  }
};

export const getBcsResultsInfo = (info) => async (dispatch) => {
  try {
    const res = await getBcsView(info);
    dispatch(setCurrentResults(res?.data?.bcs_data));
  } catch (err) {
    console.error(err);
  }
};

export const resetBcsResultsStore = () => (dispatch) => {
  dispatch(filterCurrentResults([]));
};
