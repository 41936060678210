import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadio from '@mui/material/RadioGroup';
import React from 'react';

const RadioGroup = (props) => {
  const { name, title, defaultValue, options, value, onChange } = props;

  return (
    <FormControl>
      <FormLabel id='demo-radio-buttons-group-label' sx={{ mb: 1, fontSize: 14 }}>
        {title}
      </FormLabel>
      <MuiRadio
        aria-labelledby='demo-radio-buttons-group-label'
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        value={value}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            control={<Radio size='small' />}
            key={`${option.value}_${index}`}
            label={option.label}
            value={option.value}
          />
        ))}
      </MuiRadio>
    </FormControl>
  );
};

export default RadioGroup;
