/* eslint-disable consistent-return */
import UserService from '../services/userService';

import { FILTER_CURRENT_FEEDS, SET_CURRENT_FEEDS, SET_FEEDS, SET_FEEDS_LIST, SET_FEEDS_TEMPLATE } from './actionTypes';

export const setFeedList = (feedsList) => ({ type: SET_FEEDS_LIST, feedsList });
export const setFeedInfo = (feedInfo) => ({ type: SET_FEEDS, feedInfo });
export const setCurrentFeeds = (currentFeeds) => ({ type: SET_CURRENT_FEEDS, currentFeeds });
export const filterCurrentFeeds = (currentFeeds) => ({ type: FILTER_CURRENT_FEEDS, currentFeeds });
export const setTemplateList = (templateList) => ({ type: SET_FEEDS_TEMPLATE, templateList });

export const getFeedList = () => async (dispatch) => {
  try {
    const res = await UserService.getFeedsList();
    dispatch(setFeedList(res?.data?.feed_view_index));
    return res?.data?.feed_view_index;
  } catch (err) {
    console.error(err);
  }
};

export const getFeedsInfoById = (obj, noReducer) => async (dispatch) => {
  try {
    const res = await UserService.getFeedView(obj);

    if (noReducer) {
      return { ...obj, nutrients: res?.data?.feed?.feed_data };
    }

    dispatch(setFeedInfo(res?.data?.feed?.feed_data));
    dispatch(setCurrentFeeds({ ...obj, feedInfo: res?.data?.feed?.feed_data }));
  } catch (err) {
    console.error(err);
  }
};

export const resetFeedsStore = () => (dispatch) => {
  try {
    dispatch(filterCurrentFeeds([]));
  } catch (err) {
    console.error(err);
  }
};

export const getFeedsTemplates = () => async (dispatch) => {
  try {
    const res = await UserService.getUserTemplates();
    await dispatch(setTemplateList(res?.data?.templates));
  } catch (err) {
    console.error(err);
  }
};
