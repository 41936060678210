/* eslint-disable */
import React from 'react';
import Chart from 'react-apexcharts';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import { useSettings } from '../../../hooks';
import { fromOADate } from '../../../helpers';

const BcsResultsCandleChart = (props) => {
  const { data = {}, isTrend, setIsTrend } = props;
  const { formatDate } = useSettings();
  const { t } = useTranslation();
  const { candleData, allOutliers, trendData, sortedData, isTrendEnabled, meanData } = data;

  const maxRangeDate = add(fromOADate(sortedData[sortedData.length - 1]?.OADate), { minutes: 5 });
  const minRangeDate = sub(fromOADate(sortedData[0]?.OADate), { minutes: 5 });

  const basicCharts = [
    {
      name: 'Score',
      type: 'boxPlot',
      data: candleData || [],
    },
    {
      name: 'Outlier',
      type: 'scatter',
      data: allOutliers || [],
    },
  ];

  const barData = {
    series: isTrendEnabled
      ? [
          ...basicCharts,
          {
            name: 'Trend',
            type: 'line',
            data: trendData || [],
          },
        ]
      : basicCharts,
    options: {
      markers: {
        size: [0, 6],
      },
      stroke: {
        curve: 'straight',
      },
      annotations: {
        position: 'front',
        points: meanData.map((mean) => ({
          x: isTrendEnabled ? mean.x?.getTime() : mean.x,
          y: mean.y,
          marker: {
            size: 0,
          },
          image: {
            path: '/close-icon.png',
          },
        })),
      },
      colors: ['#9db668', '#FEB019'],
      legend: {
        offsetY: 12,
        height: 32,
      },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: '#9db668',
            lower: '#8168B6',
          },
        },
      },
      xaxis: {
        type: isTrendEnabled ? 'datetime' : 'numeric',
        max: isTrendEnabled ? maxRangeDate?.getTime() : candleData.length + 1,
        min: isTrendEnabled ? minRangeDate?.getTime() : 0,
        tickPlacement: isTrendEnabled ? undefined : 'on',
        tickAmount: isTrendEnabled ? undefined : candleData.length + 1,
        labels: {
          formatter: (val) => {
            if (isTrendEnabled) {
              return formatDate(val, false, true);
            } else if (sortedData[val - 1]?.OADate) {
              return formatDate(sortedData[val - 1]?.OADate, true, true);
            }
          },
        },
        tooltip: {
          formatter: (val) => {
            if (isTrendEnabled) {
              return formatDate(val, false, true);
            }
            return formatDate(sortedData[val - 1]?.OADate, true, true);
          },
        },
      },
      yaxis: {
        max: 5,
        min: 1,
        tickAmount: 4,
        labels: {
          formatter: (val) => val,
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
    },
  };

  return (
    <Box component='div' sx={{ position: 'relative', pt: { xs: 2, sm: 2, md: 0 } }}>
      <Box
        component='div'
        className='charts-toggle'
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <Switch
          checked={isTrend}
          value={isTrend}
          onChange={() => setIsTrend(!isTrend)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box
          component='span'
          sx={{ verticalAlign: 'middle', fontSize: 14, color: (theme) => theme?.palette?.primary?.greyDark }}
        >
          {t('bcs.trendsCheckbox')}
        </Box>
      </Box>
      <Chart options={barData.options} series={barData.series} type='boxPlot' width='100%' height='400px' />
    </Box>
  );
};

export default BcsResultsCandleChart;
