import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';

const StyledChip = styled(Chip)(() => ({
  borderWidth: 2,
  fontSize: '12px',
  transition: 'none',
  '.MuiChip-icon': {
    fontSize: '18px',
  },
  borderRadius: '8px',
}));

export default StyledChip;
