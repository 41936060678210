// Current user actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_OUT = 'SIGN_OUT';

// User settings actions
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

// Farms actions
export const SET_FARMS_LIST = 'SET_FARMS_LIST';
export const SET_PICKED_FARMS = 'SET_PICKED_FARMS';
export const SET_EXACT_RECIPE = 'SET_EXACT_RECIPE';

// Rations actions
export const SET_RATION_INFO = 'SET_RATION_INFO';
export const SET_RATION_TOTAL_INFO = 'SET_RATION_TOTAL_INFO';
export const SET_RATION_TEMPLATE = 'SET_RATION_TEMPLATE';
export const SET_RATION_TEMPLATE_LIST = 'SET_RATION_TEMPLATE_LIST';
export const SET_RATION_NUTRIENTS = 'SET_RATION_NUTRIENTS';
export const SET_EXACT_FEED = 'SET_EXACT_FEED';

// Mixes actions
export const SET_MIXES_LIST = 'SET_MIXES_LIST';
export const SET_MIXES_FEEDS = 'SET_MIXES_FEEDS';
export const SET_CURRENT_MIXES = 'SET_CURRENT_MIXES';
export const FILTER_CURRENT_MIXES = 'FILTER_CURRENT_MIXES';

// Feeds actions
export const SET_FEEDS_LIST = 'SET_FEEDS_LIST';
export const SET_CURRENT_FEEDS = 'SET_CURRENT_FEEDS';
export const SET_FEEDS = 'SET_FEEDS';
export const FILTER_CURRENT_FEEDS = 'FILTER_CURRENT_FEEDS';
export const SET_FEEDS_TEMPLATE = 'SET_FEEDS_TEMPLATE';

// Comparisons actions
export const SET_CURRENT_COMPARISONS = 'SET_CURRENT_COMPARISONS';
export const FILTER_CURRENT_COMPARISONS = 'FILTER_CURRENT_COMPARISONS';
export const SET_COMPARISON_FEEDS = 'SET_COMPARISON_FEEDS';

// Activity Log
export const SET_ACTIVITY_LOG = 'SET_ACTIVITY_LOG';

// Farms Access actions
export const SET_MILLS_LIST = 'SET_MILLS_LIST';
export const SET_FARMS_ACCESS_LIST = 'SET_FARMS_ACCESS_LIST';
export const SET_USERS_LIST = 'SET_USERS_LIST';

// BCS Results actions
export const SET_BCS_RESULTS_LIST = 'SET_BCS_RESULTS_LIST';
export const SET_CURRENT_BCS_RESULT = 'SET_CURRENT_BCS_RESULT';
export const FILTER_CURRENT_BCS_RESULT = 'FILTER_CURRENT_BCS_RESULT';

// Particle Size actions
export const SET_FEED_HISTORY_LIST = 'SET_FEED_HISTORY_LIST';
export const SET_CURRENT_PSC_FEEDS = 'SET_CURRENT_PSC_FEEDS';
export const FILTER_CURRENT_PSC_FEEDS = 'FILTER_CURRENT_PSC_FEEDS';
export const SET_TMR_HISTORY_LIST = 'SET_TMR_HISTORY_LIST';
export const SET_CURRENT_PSC_TMRS = 'SET_CURRENT_PSC_TMRS';
export const FILTER_CURRENT_PSC_TMRS = 'FILTER_CURRENT_PSC_TMRS';
export const PARTICLE_SIZE_SINGLE_FEED_SET_LIST = 'PARTICLE_SIZE_SINGLE_FEED_SET_LIST';
export const PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT = 'PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT';
export const FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED = 'FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED';

// Farm Visit actions
export const SET_FARM_VISIT_RESULTS = 'SET_FARM_VISIT_RESULTS';
export const SET_CURRENT_FARM_VISIT_RESULT = 'SET_CURRENT_FARM_VISIT_RESULT';
