import axios from 'axios';
import Cookies from 'js-cookie';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL || 'https://admin-api.dev.agmodelsystems.com/amts',
  responseType: 'json',
});

AxiosInstance.interceptors.request.use((config) => {
  const accessToken = Cookies.get('amtsAccessToken');

  if (config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = accessToken;
  }

  return config;
});

// eslint-disable-next-line consistent-return
const getCurrentUser = async () => {
  try {
    const res = await AxiosInstance.get('/account/settings');
    if (res && res.status === 200) {
      return res.data;
    }
    return undefined;
  } catch (err) {
    console.error(err);
  }
};

export { getCurrentUser };
export default AxiosInstance;
