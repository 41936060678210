import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import LoadScreen from './components/load-screen';
import { Alert } from './components/shared';
import { routes } from './constants';
import { useAlert } from './hooks';
import useAuth from './hooks/useAuth';
import AppPage from './pages/app';
import { LoginPage } from './pages/auth';
import { userSelector, languageSelector } from './reducers/selectors';
import theme, { defaultStaticColor } from './styles/theme';

const muiTheme = theme(defaultStaticColor);

export default function App() {
  const { i18n } = useTranslation();
  const { isLoaded } = useAuth();

  const currentUser = useSelector(userSelector);
  const language = useSelector(languageSelector);
  const isWindows = navigator.userAgent.indexOf('Win') !== -1;

  const { isOpen, variant, message, closeAlert } = useAlert();

  useEffect(() => {
    i18n.changeLanguage(language).catch(console.error);
  }, [language]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={`app ${isWindows ? 'windows-body' : ''}`}>
        {isLoaded ? (
          <>
            {!currentUser?.email ? (
              <Routes>
                <Route element={<LoginPage />} path={routes.LOGIN} />
                <Route element={<Navigate replace to={routes.LOGIN} />} path='*' />
              </Routes>
            ) : (
              <Routes>
                <Route element={<AppPage />} path='*' />
              </Routes>
            )}
          </>
        ) : (
          <LoadScreen />
        )}
      </div>
      <Alert message={message} onClose={closeAlert} open={isOpen} variant={variant} />
    </ThemeProvider>
  );
}
