/* eslint-disable max-len */

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { nutrientLabels } from '../../constants';
import { globalUnits } from '../../constants/settings';
import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../helpers';
import { useSettings } from '../../hooks';
import { Skeleton, Table } from '../shared';

const FeedsTable = (props) => {
  const { data, rows, handleRowClick, pickedNutrients, loading, sx, isMobile } = props;

  const { t } = useTranslation();

  const { formatDate, formatWeight, formatDecimal, formatLabel } = useSettings();

  const filterRow = (obj) => Object.values(obj).filter((val) => typeof val !== 'string');

  const getAdditionalCalc = (row, type) => {
    const lbl = nutrientLabels[row?.feed_name] || row?.feed_name;
    const formattedLabel = formatLabel(lbl);
    return formatWeight(type(filterRow(row)), formattedLabel, formattedLabel, undefined, false, lbl);
  };

  const getColumns = () => {
    const valuesColumns = [
      {
        title: 'Nutrient',
        field: 'feed_name',
        sort: true,
        headerStyles: { paddingInlineStart: '48px' },
        formatter: (value) => (
          <Box component='div'>
            <Checkbox
              checked={!!pickedNutrients?.find((el) => el === value)}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={() => handleRowClick(value)}
              sx={{ padding: 0, marginInlineEnd: 1 }}
              value={value}
            />
            <Box component='span' sx={{ verticalAlign: 'middle' }}>
              {formatLabel(nutrientLabels[value] || value)}
            </Box>
          </Box>
        ),
        exportFormatter: (value, row) => formatLabel(row.feed_name),
      },
      {
        title: t('feeds.avg'),
        field: 'avg',
        formatter: (value, row) => getAdditionalCalc(row, getAvg),
      },
      {
        title: t('feeds.cv'),
        field: 'cv',
        formatter: (value, row) => getAdditionalCalc(row, coefficientOfVariation),
      },
      {
        title: t('feeds.sd'),
        field: 'sd',
        formatter: (value, row) => getAdditionalCalc(row, getStandardDeviation),
      },
    ];
    data.forEach((el) => {
      valuesColumns.push({
        title: `${el.farmName} - ${el.FeedName}`,
        field: `${el.OADate}`,
        sort: true,
        formatter: (value, row) => {
          const label = nutrientLabels[row.label || row?.feed_name];
          const formattedLabel = formatLabel(label);
          return formatWeight(value, formattedLabel, formattedLabel, undefined, false, label);
        },
        headerFormatter: () => (
          <Box component='div' sx={{ minWidth: '170px' }}>
            <Box component='div'>{formatDate(el.OADate, true)}</Box>
          </Box>
        ),
      });
    });
    return valuesColumns;
  };

  const columns = getColumns();

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      keyField='feed_name'
      pagination
      rows={rows}
      stickyColumn={!isMobile}
      stickyHeader
      sx={sx}
      toolbar
    />
  );
};

export default FeedsTable;
