import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';
import Tree from '../shared/tree';

const FarmVisitResultsTree = (props) => {
  const { results = [], onClick, mobileView, selected, onClear } = props;

  const { formatDate } = useSettings();
  const { t } = useTranslation();

  const treeData = useMemo(
    () =>
      results.map((farm) => ({
        id: farm.farm_id,
        label: (
          <Box component='div'>
            <FarmIcon fontSize='small' /> {farm.farm_name}
          </Box>
        ),
        children: farm?.answers_index?.map((answer) => ({
          id: `${farm.farm_id}${answer.questionnaire_id}`,
          label: (
            <Box component='div'>
              <ArticleIcon fontSize='small' /> {answer.questionnaire_title}
            </Box>
          ),
          children: answer.answer_versions.map((version) => ({
            id: `${farm.farm_id}${answer.questionnaire_id}${version.oadate}`,
            label: (
              <Box component='div'>
                <DateIcon fontSize='small' />
                {formatDate(version.oadate, true, true)}
              </Box>
            ),
            selected: selected?.id === version.oadate,
            onClick: () =>
              onClick({
                OADate: version.oadate,
                Farm_ID: farm.farm_id,
                FarmName: farm.farm_name,
                Questionnaire_ID: answer.questionnaire_id,
              }),
          })),
        })),
      })),
    [results, selected]
  );

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('farmVisit.selectResult')}
        </Typography>
        {!!selected && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default FarmVisitResultsTree;
