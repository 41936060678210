import { FILTER_CURRENT_PSC_TMRS, SET_CURRENT_PSC_TMRS, SET_TMR_HISTORY_LIST } from '../actions/actionTypes';

const initialState = {
  historyList: [],
  selectedSamples: [],
  isLoaded: false,
};

const particleSizeFeedHistoryReducer = (state = initialState, action = {}) => {
  const { historyList, selectedSamples } = action;

  switch (action.type) {
    case SET_TMR_HISTORY_LIST:
      return {
        ...state,
        historyList,
        isLoaded: true,
      };
    case SET_CURRENT_PSC_TMRS:
      return {
        ...state,
        selectedSamples: [...state.selectedSamples, selectedSamples]?.sort((a, b) => a?.OADate - b?.OADate),
      };
    case FILTER_CURRENT_PSC_TMRS:
      return {
        ...state,
        selectedSamples,
      };
    default:
      return state;
  }
};

export default particleSizeFeedHistoryReducer;
