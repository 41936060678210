import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import debounce from 'debounce';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  globalUnits,
  energyUnits,
  decimalPrecisionFormats,
  decimalFormats,
  dateFormats,
  languages,
} from '../../constants/settings';
import { Select, Input } from '../shared';

const globalUnitOptions = [
  { label: 'Metric', value: globalUnits.METRIC },
  { label: 'Imperial', value: globalUnits.IMPERIAL },
];
const energyUnitOptions = [
  { label: 'Mcal', value: energyUnits.MCAL },
  { label: 'MJ', value: energyUnits.MJ },
];
const decimalPrecisionOptions = [
  { label: '0.0', value: decimalPrecisionFormats.ONE_DIGIT },
  { label: '0.00', value: decimalPrecisionFormats.TWO_DIGITS },
  { label: '0.000', value: decimalPrecisionFormats.THREE_DIGITS },
  { label: '0.0000', value: decimalPrecisionFormats.FOUR_DIGITS },
];
const decimalFormatOptions = [
  { label: ',', value: decimalFormats.COMA },
  { label: '.', value: decimalFormats.DOT },
];
const dateFormatOptions = [
  { label: 'M/D/YYYY', value: dateFormats.Md },
  { label: 'MM/DD/YYYY', value: dateFormats.MMdd },
  { label: 'D/M/YYYY', value: dateFormats.dM },
  { label: 'DD/MM/YYYY', value: dateFormats.ddMM },
];
const languageOptions = [
  { label: 'English', value: languages.ENGLISH },
  { label: 'Français', value: languages.FRENCH },
];

const SettingsForm = (props) => {
  const { initialValues = {}, onSubmit } = props;

  const { t } = useTranslation();

  const form = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const debouncedSubmit = useCallback(debounce(onSubmit, 800), [onSubmit]);

  const onChange = (e, child, debounced) => {
    form.handleChange(e);
    if (debounced) {
      debouncedSubmit(e.target.name, e.target.value);
    } else {
      onSubmit(e.target.name, e.target.value);
    }
  };

  return (
    <form onSubmit={form.onSubmit}>
      <Grid component='div' container spacing={2}>
        <Grid component='div' item md={4} sm={12} xs={12}>
          <Box component='div' sx={{ textAlign: 'center', mb: 2, fontWeight: 600 }}>
            {t('settings.units')}
          </Box>
          <Stack component='div' spacing={2}>
            <Select
              id='metric-english-select'
              label={t('settings.globalUnit')}
              name='GlobalUnit'
              onChange={onChange}
              options={globalUnitOptions}
              value={form.values.GlobalUnit}
            />
            <Select
              id='energy-select'
              label={t('settings.energy')}
              name='EnergyUnit'
              onChange={onChange}
              options={energyUnitOptions}
              value={form.values.EnergyUnit}
            />
            <Input
              id='currency-select'
              label={t('settings.currencyUnit')}
              maxLength={5}
              name='CurrencyUnit'
              onChange={(e, child) => onChange(e, child, true)}
              value={form.values.CurrencyUnit}
            />
            <Input
              id='minor-currency-select'
              label={t('settings.minorCurrencyUnit')}
              maxLength={5}
              name='MinorCurrencyUnit'
              onChange={(e, child) => onChange(e, child, true)}
              value={form.values.MinorCurrencyUnit}
            />
          </Stack>
        </Grid>
        <Grid component='div' item md={4} sm={12} xs={12}>
          <Box component='div' sx={{ textAlign: 'center', mb: 2, fontWeight: 600 }}>
            {t('settings.feedDecimalFormats')}
          </Box>
          <Stack component='div' spacing={2}>
            <Select
              id='forages-select'
              label={t('settings.forages')}
              name='ForagesDecimalPrecision'
              onChange={onChange}
              options={decimalPrecisionOptions}
              value={form.values.ForagesDecimalPrecision}
            />
            <Select
              id='concentrates-select'
              label='Concentrates'
              name='ConcentratesDecimalPrecision'
              onChange={onChange}
              options={decimalPrecisionOptions}
              value={form.values.ConcentratesDecimalPrecision}
            />
            <Select
              id='minerals-select'
              label={t('settings.minerals')}
              name='MineralsDecimalPrecision'
              onChange={onChange}
              options={decimalPrecisionOptions}
              value={form.values.MineralsDecimalPrecision}
            />
            <Select
              id='mixes-select'
              label={t('settings.mixes')}
              name='MixesDecimalPrecision'
              onChange={onChange}
              options={decimalPrecisionOptions}
              value={form.values.MixesDecimalPrecision}
            />
          </Stack>
        </Grid>
        <Grid component='div' item md={4} sm={12} xs={12}>
          <Box component='div' sx={{ textAlign: 'center', mb: 2, fontWeight: 600 }}>
            {t('settings.other')}
          </Box>
          <Stack component='div' spacing={2}>
            <Select
              id='decimal-format-select'
              label={t('settings.decimalFormat')}
              name='DecimalFormat'
              onChange={onChange}
              options={decimalFormatOptions}
              value={form.values.DecimalFormat}
            />
            <Select
              id='date-format-select'
              label={t('settings.dateFormat')}
              name='DateFormat'
              onChange={onChange}
              options={dateFormatOptions}
              value={form.values.DateFormat}
            />
            <Select
              id='language-select'
              label={t('settings.language')}
              name='Language'
              onChange={onChange}
              options={languageOptions}
              value={form.values.Language}
            />
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default SettingsForm;
