import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React from 'react';

const FilterModal = (props) => {
  const { filters = {}, farmOptions = [], actionOptions = [], statusOptions = [], onRemove, isMobile } = props;

  return (
    <Box component='div' sx={{ display: 'flex', alignItems: 'center', flexWrap: isMobile ? 'wrap' : 'initial' }}>
      {filters.farm && filters.farm !== 'ALL' && (
        <Chip
          label={farmOptions?.find((item) => item.value === filters.farm)?.label || ''}
          onDelete={() => onRemove('farm')}
          sx={{ mr: 1, borderRadius: '8px', mt: isMobile ? 1 : 0 }}
        />
      )}

      {filters.action && filters.action !== 'ALL' && (
        <Chip
          label={actionOptions?.find((item) => item.value === filters.action)?.label || ''}
          onDelete={() => onRemove('action')}
          sx={{ mr: 1, borderRadius: '8px', mt: isMobile ? 1 : 0 }}
        />
      )}

      {filters.status && filters.status !== 'ALL' && (
        <Chip
          label={statusOptions?.find((item) => item.value === filters.status)?.label || ''}
          onDelete={() => onRemove('status')}
          sx={{ borderRadius: '8px', mt: isMobile ? 1 : 0 }}
        />
      )}
    </Box>
  );
};

export default FilterModal;
