import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';

const Score = ({ value, onChange = () => {}, disabled }) => (
  <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {Array.from(Array(5).keys())?.map((v) => (
      <Button
        component='div'
        disabled={disabled && value !== v + 1}
        key={v}
        onClick={() => {
          if (!disabled) {
            onChange(v + 1);
          }
        }}
        sx={{
          width: 60,
          height: 60,
          marginRight: 1,
          fontWeight: 'bold',
          background: value === v + 1 ? 'default' : '#ffffff',
          fontSize: 16,
          mb: 1,
        }}
        variant={value === v + 1 ? 'contained' : 'outlined'}
      >
        {v + 1}
      </Button>
    ))}
  </Box>
);

export default Score;
