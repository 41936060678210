import UserService from '../services/userService';

import { SET_USER_SETTINGS } from './actionTypes';

export const setUserSettings = (settings) => ({ type: SET_USER_SETTINGS, settings });

export const getUserSettings = () => async (dispatch) => {
  try {
    const res = await UserService.getUserSettingsAsync();
    dispatch(setUserSettings(res));
  } catch (err) {
    console.error(err);
  }
};

export const updateUserSettings = (settings) => async (dispatch) => {
  try {
    await UserService.updateUserSettingsAsync(settings);
    dispatch(setUserSettings(settings));
  } catch (err) {
    throw new Error('Server Error');
  }
};
