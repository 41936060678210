import ClearIcon from '@mui/icons-material/PlaylistRemove';
import PublishIcon from '@mui/icons-material/Publish';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from '../shared';
import Tree from '../shared/tree';

const FarmVisitTree = (props) => {
  const { results = [], onClick, mobileView, selected, onClear } = props;

  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const filteredResults = useMemo(() => {
    if (results?.length) {
      if (search.length) {
        return results.filter((el) => el.Title?.toLowerCase().includes(search.toLowerCase()));
      }
      return results;
    }
    return results;
  }, [search, results]);

  const treeData = useMemo(
    () =>
      filteredResults.map((farm) => ({
        id: farm.id,
        label: (
          <Box component='div'>
            {farm?.Is_Published && (
              <PublishIcon fontSize='small' sx={{ color: (theme) => theme?.palette?.primary?.main }} />
            )}
            {farm.Title}
          </Box>
        ),
        selected: selected === farm.id,
        onClick: () => onClick(farm),
      })),
    [filteredResults, selected]
  );

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('adminFarmVisit.selectResult')}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            mt: 2,
            p: 1,
            background: '#ffffff',
            borderRadius: 1,
            border: (theme) => `1px solid ${theme?.palette?.primary?.grey}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TuneIcon color='secondary' />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Box>
            {/*<IconButton onClick={() => setOpen(!open)} size='small'>*/}
            {/*  {open ? <ArrowUp /> : <ArrowDown />}*/}
            {/*</IconButton>*/}
          </Box>
          {open && (
            <>
              <Box sx={{ mt: 2, maxWidth: 600 }}>
                <SearchInput
                  handleClear={() => setSearch('')}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='Search by farm name'
                  size='small'
                  sx={{ width: '100%' }}
                  value={search}
                />
              </Box>
            </>
          )}
        </Box>
        {!!selected && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default FarmVisitTree;
