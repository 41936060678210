import MuiBottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';

const BottomNavigation = ({ activeTab, onChange, tabs = [] }) => (
  <Paper elevation={3} sx={{ position: 'fixed', bottom: -1, left: 0, right: 0 }}>
    <MuiBottomNavigation onChange={onChange} showLabels sx={{ height: 64 }} value={activeTab}>
      {tabs?.map((tab, index) => (
        <BottomNavigationAction
          disabled={tab.disabled}
          icon={tab.icon}
          key={`${tab.value}_${index}`}
          label={tab.label}
          sx={{ pb: 2 }}
          value={tab.value}
        />
      ))}
    </MuiBottomNavigation>
  </Paper>
);

BottomNavigation.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      icon: PropTypes.any,
      disabled: PropTypes.bool,
    })
  ),
};

export default BottomNavigation;
