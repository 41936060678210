import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';

export default function Row(props) {
  const { row, handleRationClick } = props;

  const { formatDate } = useSettings();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' onClick={() => setOpen(!open)} size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.farm_name || '---'}
        </TableCell>
        <TableCell>{row.farm_address || '---'}</TableCell>
        <TableCell>{formatDate(row.last_silo_upload) || '---'}</TableCell>
        <TableCell>{row.last_silo_upload_by || '---'}</TableCell>
        <TableCell>{formatDate(row.expiration_date) || '---'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0, background: '#f9f9f9' }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box component='div'>
              <Table aria-label='purchases' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('farms.recipeName')}</TableCell>
                    <TableCell>{t('farms.lastImplementationDate')}</TableCell>
                    <TableCell>{t('farms.implementedBy')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.rations.map((ration) => (
                    <TableRow key={ration.ration_name}>
                      <TableCell scope='row' sx={{ border: 'none', width: '33.3%' }}>
                        <Link onClick={() => handleRationClick(ration, row.farm_id)} sx={{ cursor: 'pointer' }}>
                          {ration.ration_name || '---'} <LaunchIcon sx={{ fontSize: 14 }} />
                        </Link>
                      </TableCell>
                      <TableCell scope='row' sx={{ border: 'none', width: '33.3%' }}>
                        {formatDate(ration.last_implemented_oadate, true) || '---'}
                      </TableCell>
                      <TableCell scope='row' sx={{ border: 'none', width: '33.3%' }}>
                        {ration.last_implemented_by || '---'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
