export const formatToRequest = (Title = '', sections = []) => {
  const user = JSON.parse(localStorage.getItem('user'));
  // const Customer_ID = `${user?.customer_id}`;

  return {
    // Customer_ID,
    Title,
    FarmVisitSections: sections.map((section, index) => ({
      Order: index,
      Title: section.title,
      Description: section.description,
      FarmVisitQuestions: section.questions.map((question, idx) => ({
        Title: question.title,
        Description: question.description,
        Order: idx,
        Required: question.required,
        Type: question.type,
        FarmVisitQuestionOptions:
          question?.options?.map((option) => ({
            Label: option.label,
            Value: option.label,
          })) || [],
      })),
    })),
  };
};

export const formatToForm = (obj) => {
  const { questionnaire } = obj;
  return {
    title: questionnaire?.Title,
    sections: questionnaire?.FarmVisitSections?.sort((a, b) => a?.Order - b?.Order)?.map((sect) => ({
      title: sect?.Title,
      id: sect?.id ? `${sect?.id}` : Math.random().toString(),
      description: sect?.Description,
      questions: sect?.FarmVisitQuestions?.sort((a, b) => a?.Order - b?.Order)?.map((q) => ({
        title: q?.Title,
        description: q?.Description,
        type: q?.Type,
        required: q?.Required,
        id: q?.id ? `${q?.id}` : Math.random().toString(),
        options: q?.FarmVisitQuestionOptions?.map((opt) => ({
          label: opt?.Label,
          id: opt?.id ? `${opt?.id}` : Math.random().toString(),
        })),
      })),
    })),
  };
};
