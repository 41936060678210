/* eslint-disable */
import { SET_FARMS_ACCESS_LIST, SET_MILLS_LIST, SET_USERS_LIST } from './actionTypes';
import AdminService from '../services/adminService';

export const setMillsList = (millsList) => ({ type: SET_MILLS_LIST, millsList });
export const setFarmsList = (farmsList) => ({ type: SET_FARMS_ACCESS_LIST, farmsList });
export const setUsersList = (usersList) => ({ type: SET_USERS_LIST, usersList });

export const getAllMills = () => async (dispatch) => {
  try {
    const res = await AdminService.getAdminMills();
    dispatch(setMillsList(res?.data?.allMills));
  } catch (err) {
    console.error(err);
  }
};

export const getFarmUsers = (millObj) => async (dispatch) => {
  try {
    const res = await AdminService.getFarmUsers(millObj);
    dispatch(setFarmsList(res?.data?.data?.farms));
    dispatch(setUsersList(res?.data?.data?.users));
    return res?.data?.data;
  } catch (err) {
    console.error(err);
  }
};

export const addExternalUser = (obj) => async (dispatch) => {
  try {
    return await AdminService.addExtUser(obj);
  } catch (err) {
    console.error(err);
  }
};

export const updateUserDate = (obj) => async (dispatch) => {
  try {
    return await AdminService.updateUserDate(obj);
  } catch (err) {
    console.error(err);
  }
};

export const saveAllChanges = (obj) => async (dispatch) => {
  try {
    return await AdminService.updateFarmsAccess({ permitted_farms: obj });
  } catch (err) {
    console.error(err);
  }
};
