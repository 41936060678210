import DeleteIcon from '@mui/icons-material/DeleteForever';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { reorderDnd } from '../../helpers';
import { Accordion, Button, Input } from '../shared';

import Questions from './Questions';

const SectionWrap = (props) => {
  const {
    sections,
    setSections,
    handleUpdateSection,
    handleUpdateQuestion,
    handleUpdateOption,
    handleOption,
    handleQuestion,
    handleSection,
    mobileView,
  } = props;

  const { t } = useTranslation();

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === 'QUESTIONS') {
      const reorderedSections = reorderDnd(sections, result.source.index, result.destination.index);

      setSections(reorderedSections);
    } else {
      const res = reorderDnd(
        sections[parseInt(result.type, 10)].questions,
        result.source.index,
        result.destination.index
      );

      const reorderedQuestions = JSON.parse(JSON.stringify(sections));

      reorderedQuestions[result.type].questions = res;

      setSections(reorderedQuestions);
    }
  };

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        component='div'
        sx={{
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
          },
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' type='QUESTIONS'>
            {(provided) => (
              <Box component='div' ref={provided.innerRef} sx={{ width: '100%' }}>
                <Stack component='div' spacing={2}>
                  {sections?.map((section, index) => (
                    <Draggable draggableId={section.id} index={index} key={section.id}>
                      {(drProvided) => (
                        <div
                          ref={drProvided.innerRef}
                          style={drProvided.draggableProps.style}
                          {...drProvided.draggableProps}
                        >
                          <Accordion
                            defaultOpen
                            mobileView={mobileView}
                            title={
                              <>
                                <Box
                                  component='span'
                                  sx={{
                                    marginInlineEnd: '16px',
                                    display: 'flex',
                                  }}
                                  {...drProvided.dragHandleProps}
                                >
                                  <DragHandleIcon />
                                </Box>
                                {section.title}
                              </>
                            }
                          >
                            <Box
                              component='div'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                                gap: '16px',
                              }}
                            >
                              <Input
                                error={!section.title?.length}
                                id={`section-title-${section.id}`}
                                label={t('adminFarmVisit.titleSection')}
                                name='section-title'
                                onChange={(e) => handleUpdateSection(section, 'title', e.target.value)}
                                sx={{
                                  minWidth: '240px',
                                  flexGrow: 1,
                                }}
                                value={section.title}
                              />
                              <IconButton
                                aria-label='close'
                                color='inherit'
                                disabled={sections?.length === 1}
                                onClick={() => handleSection(section, 'delete')}
                                sx={{
                                  minWidth: '40px',
                                  marginLeft: '8px',
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>

                            <Input
                              id={`section-desc-${section.id}`}
                              label={t('adminFarmVisit.descSection')}
                              multiline
                              name='section-desc'
                              onChange={(e) => handleUpdateSection(section, 'description', e.target.value)}
                              sx={{
                                width: '100%',
                                mb: 2,
                              }}
                              value={section.description}
                            />
                            <Questions
                              handleOption={handleOption}
                              handleQuestion={handleQuestion}
                              handleUpdateOption={handleUpdateOption}
                              handleUpdateQuestion={handleUpdateQuestion}
                              mobileView={mobileView}
                              section={section}
                              sectionNum={index}
                            />
                          </Accordion>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Stack>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          onClick={() => handleSection(null, 'add')}
          sx={{
            mt: '16px',
            marginInline: mobileView ? '16px !important' : '0',
          }}
        >
          {t('adminFarmVisit.addSection')}
        </Button>
      </Box>
    </Box>
  );
};

export default SectionWrap;
