const formatRoles = (roles) => roles.map((role) => (typeof role === 'object' && role.name ? role.name : role));

const isAccessPermitted = ({ roles, excludeRoles, userRoles }) => {
  if ((!roles || !roles.length) && !userRoles?.length) {
    return false;
  }

  const ROLES = roles && formatRoles(roles);
  const EXCLUDE_ROLES = excludeRoles && formatRoles(excludeRoles);
  const USER_ROLES = userRoles && formatRoles(userRoles);

  if (ROLES) {
    return Array.isArray(ROLES) ? ROLES?.some((role) => USER_ROLES?.includes(role)) : USER_ROLES?.includes(ROLES);
  }

  if (EXCLUDE_ROLES) {
    return Array.isArray(EXCLUDE_ROLES)
      ? EXCLUDE_ROLES?.every((role) => !USER_ROLES?.includes(role))
      : !USER_ROLES?.includes(EXCLUDE_ROLES);
  }

  return !ROLES && !EXCLUDE_ROLES;
};

export default isAccessPermitted;
