import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function Copyright(props) {
  return (
    <Typography align='center' color='text.secondary' variant='body2' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://mui.com/'>
        AMTS.Silo
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
