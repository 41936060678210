import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../../hooks';

const boxSx = {
  display: 'flex',
  alignItems: 'center',
  pt: '4px',
  pb: '4px',
  borderBottom: '1px solid #eeeeee',
};

const SingleSampleFeedStat = ({ data }) => {
  const { t } = useTranslation();
  const { formatDecimal } = useSettings();

  if (!data) {
    return null;
  }

  const { TopPercent, SecondPanPercent, ThirdPanPercent, BottomPanPercent, pef } = data;

  return (
    <>
      <Box component='div'>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.topPanAmount')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(TopPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.secondPanAmount')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(SecondPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.thirdPanAmount')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(ThirdPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.bottomPanAmount')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(BottomPanPercent)}%`}</Typography>
        </Box>
        <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between', border: 'none' }}>
          <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
            {t('psc.pef')}
          </Typography>
          <Typography component='span'>{`${formatDecimal(pef)}%`}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default SingleSampleFeedStat;
