import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/system';
import React from 'react';

export const StyledDialog = styled(Dialog)(() => ({}));

export const StyledActions = styled(DialogActions)(() => ({
  justifyContent: 'space-between',
  padding: 16,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  // boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
}));

export const Transition = React.forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);
