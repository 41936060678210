import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

import StyledButton from './styled';

const Button = (props) => {
  const {
    type = 'submit',
    onClick = () => {},
    color = 'primary',
    outlined = false,
    disabled = false,
    loading = false,
    fullWidth = false,
    size = 'medium',
    children,
    sx = {},
    ...other
  } = props;

  return (
    <StyledButton
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      type={type}
      variant={outlined ? 'outlined' : 'contained'}
      {...other}
      sx={{
        ...sx,
        padding: size === 'small' ? '4px 8px' : '',
      }}
    >
      {loading ? <CircularProgress size={24} /> : children}
    </StyledButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['medium', 'small']),
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;
