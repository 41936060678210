import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

export const StyledTreeItem = styled((props) => <TreeItem {...props} />)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    borderRadius: 8,
    padding: '4px 8px',
    background: '#fff',
    marginTop: 8,
    border: `1px solid ${theme.palette.primary.grey}`,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 8,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.label}`]: {
    fontSize: '14px !important',
    '> div': {
      display: 'flex',
      alignItems: 'center',
      '> svg': {
        marginRight: 6,
      },
    },
  },
  [`& .${treeItemClasses.selected}`]: {
    color: theme.palette.primary.main,
    background: 'rgba(157, 182, 104, 0.08)',
    fontWeight: 'bold',
  },
  [`& .${treeItemClasses.expanded}`]: {
    '.tree-item-indicator': {
      display: 'none',
    },
  },
}));

export const TreeItemLabel = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '> div': {
    display: 'flex',
    alignItems: 'center',
    '> svg': {
      marginRight: '6px',
    },
  },
}));
