import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '8px 16px',
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: 600,
  minWidth: 100,
  background: ({ background }) => background || theme.palette?.primary.main,
  color: ({ color }) => color || theme.palette?.primary.contrastText,
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

export default StyledButton;
