import AdminService from '../services/adminService';

import { SET_ACTIVITY_LOG } from './actionTypes';

export const setActivityLog = (activityLog) => ({ type: SET_ACTIVITY_LOG, activityLog });

export const getActivityLog = (startDate, endDate) => async (dispatch) => {
  try {
    const res = await AdminService.getActivityLog(startDate, endDate);
    dispatch(setActivityLog(res?.data?.activity_logs));
  } catch (err) {
    console.error(err);
  }
};
