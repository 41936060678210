import { SET_ACTIVITY_LOG } from '../actions/actionTypes';

const initialState = {
  list: [],
};

const activityLogReducer = (state = initialState, action = {}) => {
  const { activityLog = [] } = action;

  switch (action.type) {
    case SET_ACTIVITY_LOG:
      return {
        ...state,
        list: activityLog || [],
      };
    default:
      return state;
  }
};

export default activityLogReducer;
