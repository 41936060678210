import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ButtonCard } from './styled';

const ButtonLinkCard = ({ onClick, title, subtitle, icon }) => (
  <ButtonCard onClick={onClick}>
    <Typography component='h5'>{title}</Typography>
    <Typography component='span'>{subtitle}</Typography>
    <Box component='div'>{icon}</Box>
  </ButtonCard>
);

export default ButtonLinkCard;
