import Box from '@mui/material/Box';
import React from 'react';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      aria-labelledby={`tab-${index}`}
      component='div'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && (
        <Box component='div' sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
