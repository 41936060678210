import {
  SET_RATION_INFO,
  SET_RATION_TEMPLATE,
  SET_RATION_TEMPLATE_LIST,
  SET_RATION_NUTRIENTS,
  SET_EXACT_FEED,
  SET_RATION_TOTAL_INFO,
} from '../actions/actionTypes';
import { nutrientLabels } from '../constants';
import { nutrientOrder } from '../constants/nutrientLabels';

const initialState = {
  currentTemplate: undefined,
  templateList: [],
  ration: [],
  info: {},
  nutrients: [],
  exactFeed: undefined,
  isLoaded: false,
  templateListLoaded: false,
};

const rationsReducer = (state = initialState, action = {}) => {
  const { currentTemplate, templateList, nutrients, ration, exactFeed, info } = action;
  let mappedNutrients = [];
  if (nutrients) {
    mappedNutrients = Object.keys(nutrients).map((n) => ({
      label: nutrientLabels[n],
      key: n,
      value: nutrients[n],
    }));
    const nutrIndices = {};
    nutrientOrder.forEach((label, index) => {
      nutrIndices[label] = index;
    });
    function getLabelIndex(item) {
      return nutrIndices[item.key] !== undefined ? nutrIndices[item.key] : nutrientOrder.length;
    }

    mappedNutrients = mappedNutrients.sort((a, b) => getLabelIndex(a) - getLabelIndex(b));
  }

  switch (action.type) {
    case SET_RATION_TEMPLATE:
      return {
        ...state,
        currentTemplate,
        isLoaded: true,
      };
    case SET_RATION_TEMPLATE_LIST:
      return {
        ...state,
        templateList,
        templateListLoaded: true,
      };
    case SET_RATION_NUTRIENTS:
      return {
        ...state,
        nutrients: mappedNutrients,
      };
    case SET_RATION_INFO:
      return {
        ...state,
        ration,
      };
    case SET_RATION_TOTAL_INFO:
      return {
        ...state,
        info,
      };
    case SET_EXACT_FEED:
      return {
        ...state,
        exactFeed,
      };
    default:
      return state;
  }
};

export default rationsReducer;
