/* eslint-disable consistent-return */
import ParticleSizeService from '../services/particleSizeService';

import { FILTER_CURRENT_PSC_FEEDS, SET_CURRENT_PSC_FEEDS, SET_FEED_HISTORY_LIST } from './actionTypes';

export const setFeedHistoryList = (historyList) => ({ type: SET_FEED_HISTORY_LIST, historyList });
export const setCurrentFeeds = (selectedFeeds) => ({ type: SET_CURRENT_PSC_FEEDS, selectedFeeds });
export const filterCurrentFeeds = (selectedFeeds) => ({ type: FILTER_CURRENT_PSC_FEEDS, selectedFeeds });

export const getFeedHistoryList = () => async (dispatch) => {
  try {
    const res = await ParticleSizeService.getFeedFarms();
    dispatch(setFeedHistoryList(res?.data?.feeds_farms || []));
  } catch (err) {
    console.error(err);
  }
};

export const getFeedSampleInfo = (feed) => async (dispatch) => {
  try {
    const res = await ParticleSizeService.getFeedById(feed);
    dispatch(setCurrentFeeds(res?.data?.feed));
  } catch (err) {
    console.error(err);
  }
};

export const resetCurrentFeeds = () => (dispatch) => {
  dispatch(filterCurrentFeeds([]));
};
