import Box from '@mui/material/Box';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '../shared';

const TemplateSelect = (props) => {
  const { onChange, list, currentTemplate, size } = props;

  const { t } = useTranslation();

  const options = [
    { label: t('all'), value: 0 },
    ...list.map((item) => ({ label: item?.template_name, value: item?.template_name })),
  ];

  return (
    <Box component='div' sx={{ minWidth: 200 }}>
      <Select
        id='template-select'
        label={t('template')}
        onChange={onChange}
        options={options}
        size={size}
        value={currentTemplate?.template_name || 0}
      />
    </Box>
  );
};

export default TemplateSelect;
