import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';

const Select = (props) => {
  const {
    value = null,
    defaultValue = undefined,
    onChange = () => {},
    label = '',
    id = '',
    name = '',
    options = [],
    helperText = '',
    autoWidth = false,
    disabled = false,
    minWidth = 120,
    sx = {},
    size,
  } = props;

  return (
    <FormControl fullWidth size={size} sx={{ minWidth, ...sx }}>
      <InputLabel id={`${id}-helper-label`}>{label}</InputLabel>
      <MuiSelect
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        autoWidth={autoWidth}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        label={label}
        labelId={`${id}-label`}
        name={name}
        onChange={onChange}
        sx={{ backgroundColor: '#ffffff' }}
        value={value}
      >
        {options?.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  minWidth: PropTypes.number,
};

export default Select;
