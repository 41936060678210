import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { setCurrentUser } from '../actions/authActions';
import { getCurrentUser } from '../services/axiosExternal';

const TOKEN_KEY = 'amtsAccessToken';

const useAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const path = location.pathname;

  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const setToken = async (token) => {
    Cookies.set(TOKEN_KEY, token);
  };

  const fetchUser = async () => {
    try {
      setLoading(true);

      const token = searchParams.get('token');

      if (token) {
        await setToken(token);
      }
      const amtsUser = await getCurrentUser();
      if (amtsUser) {
        dispatch(setCurrentUser(amtsUser));
        if (path === '/login') {
          navigate({
            pathname: '/',
          });
        }
        setAuthenticated(true);
      } else {
        if (path !== '/login') {
          navigate({
            pathname: '/login',
          });
        }
        setAuthenticated(false);
      }
      return amtsUser;
    } catch (err) {
      setAuthenticated(false);
      return undefined;
    } finally {
      setTimeout(() => {
        setLoading(false);
        setIsLoaded(true);
      }, 500);
    }
  };

  const logout = () => {
    const hostname = window.location.hostname;
    const domain = hostname === 'localhost' ? 'localhost' : hostname.substring(hostname.indexOf('.'));
    Cookies.remove(TOKEN_KEY, { path: '/', domain });
    setAuthenticated(false);
    dispatch(setCurrentUser(null));

    return (window.location = '/');
  };

  useEffect(() => {
    fetchUser().catch(console.error);
  }, []);

  return {
    authenticated,
    path,
    loading,
    isLoaded,
    logout,
  };
};

export default useAuth;
