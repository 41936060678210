/* eslint-disable */

export const toOADate = (function () {
  const epoch = new Date(1899, 11, 30);
  const msPerDay = 8.64e7;

  return function (d) {
    let v = (-1 * (epoch - d)) / msPerDay;

    // Deal with dates prior to 1899-12-30 00:00:00
    let dec = v - Math.floor(v);

    if (v < 0 && dec) {
      v = Math.floor(v) - dec;
    }

    return v;
  };
})();

export const fromOADate = (oaDate) => {
  // Treat integer part is whole days
  var days = parseInt(oaDate);
  // Treat decimal part as part of 24hr day, always +ve
  var ms = Math.abs((oaDate - days) * 8.64e7);
  // Add days and add ms
  const d = new Date(1899, 11, 30 + days, 0, 0, 0, ms);
  d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
  return d;
};

// find avg in array
export const getAvg = (arr = []) => arr.reduce((a, b) => Number(a) + Number(b), 0) / arr.length;

// SD for table
export const getStandardDeviation = (array) => {
  const n = array.length;
  const mean = getAvg(array);
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
};

// CV for table
export const coefficientOfVariation = (arr) => getStandardDeviation(arr) / getAvg(arr);

export const checkIsNaN = (val) => (Number.isNaN(val) ? '---' : val?.toFixed(2));

export const hexToRgba = (hex, opacity = 1) => {
  let c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex?.substring(1)?.split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `, ${opacity})`;
  }

  return 'rgba(0, 0, 0, 1)';
};

export const getUUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const reorderDnd = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
