import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data, unit = '', print = false }) => {
  const labels = data.map((q) => q.label);
  const values = data.map((q) => q.value);

  const barData = {
    options: {
      chart: {
        id: `line-chart-${Math.random()}`,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9db668'],
      xaxis: {
        categories: labels,
        labels: {
          formatter: (label) => label?.short || label?.full || '',
        },
      },
      tooltip: {
        y: {
          formatter: (value) => `${value} ${unit}`,
        },
        x: {
          formatter: (index) => {
            return labels[index - 1]?.full || labels[index - 1]?.short || '';
          },
        },
      },
    },
    series: [
      {
        name: 'Value',
        data: values,
      },
    ],
  };

  debugger;

  return (
    <Box
      component='div'
      sx={{
        minHeight: print ? '420px' : '100%',
      }}
    >
      <Chart height={print ? '100%' : '220%'} options={barData.options} series={barData.series} type='line' />
    </Box>
  );
};

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  unit: PropTypes.string,
  print: PropTypes.bool,
};

export default LineChart;
