import ArrowUpIcon from '@mui/icons-material/ExpandLess';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getComparator, stableSort } from '../../helpers/table';

import Row from './Row';

export default function FarmsTable(props) {
  const { data = [], handleRationClick, isMobile } = props;

  const { t } = useTranslation();

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  if (!data || !data?.length) {
    return null;
  }

  const columns = [
    { title: '', field: '', sort: false },
    { title: t('farms.farmName'), field: 'farm_name', sort: true },
    { title: t('farms.address'), field: 'farm_address', sort: true },
    { title: t('farms.lastImplementationDate'), field: 'last_silo_implemented_oadate', sort: true },
    { title: t('farms.implementedBy'), field: 'last_silo_upload_by', sort: true },
    { title: t('farms.accessValidUntil'), field: 'expiration_date', sort: true },
  ];

  const onSort = (field) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const renderSortIcon = (field) => {
    if (orderBy === field) {
      return order === 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />;
    }
    return <UnfoldMoreIcon />;
  };

  const rows = stableSort(data, getComparator(order, orderBy));

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: { md: 'auto', sm: `calc(${window?.screen?.width}px - 48px)` },
        maxHeight: { md: 'unset', xs: 'calc(100vh - 140px)' },
      }}
    >
      <Table aria-label='collapsible table' sx={{ overflow: 'auto' }}>
        <TableHead sx={{ backgroundColor: (theme) => theme?.palette?.primary?.main }}>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell
                key={`${column.field}_${column.title}`}
                onClick={() => {
                  if (column.sort) {
                    onSort(column.field);
                  }
                }}
                sx={{ color: '#ffffff', cursor: column.sort ? 'pointer' : 'default', width: index === 0 ? 50 : 'auto' }}
              >
                <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                  {column.title}
                  {!!column.sort && renderSortIcon(column.field)}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <Row handleRationClick={handleRationClick} index={index} key={row.farm_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
