import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';

import HistoryHistogram from '../HistoryHistogram';

import SingleSampleTMRInfo from './SingleSampleTMRInfo';
import SingleSampleTMRStat from './SingleSampleTMRStat';

const SingleSampleTMRComponent = ({ selectedSamples = [], currentInfo }) => (
  <Box component='div' sx={{ display: 'flex' }}>
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 350,
      }}
    >
      <Paper
        component='div'
        sx={{
          p: 2,
          mb: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <SingleSampleTMRInfo currentInfo={currentInfo || selectedSamples[0]} />
      </Paper>
      <Paper
        component='div'
        sx={{
          p: 2,
          mt: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <SingleSampleTMRStat data={selectedSamples ? selectedSamples[0] : null} />
      </Paper>
    </Box>
    <Paper
      elevation={1}
      sx={{
        pb: 1,
        pt: 0,
        pr: 1,
        ml: { xs: 0, sm: 0, md: 2 },
        mt: { xs: 2, sm: 2, md: 0 },
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '> div': { flex: 1 },
      }}
    >
      <HistoryHistogram data={selectedSamples ? selectedSamples[0] : null} />
    </Paper>
  </Box>
);

export default SingleSampleTMRComponent;
