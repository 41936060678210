import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import CheckIcon from '@mui/icons-material/Check';
import * as React from 'react';

import { StyledTreeItem, TreeItemLabel } from './styled';

const TreeItem = (props) => {
  const { selected, childSelected, ...other } = props;

  return (
    <StyledTreeItem
      icon={selected ? <CheckIcon /> : null}
      sx={{ color: selected ? '#9db668' : '#333333' }}
      {...other}
      label={
        <TreeItemLabel>
          {other.label}
          {childSelected && (
            <ArrowCircleDownOutlinedIcon
              className='tree-item-indicator'
              color='primary'
              sx={{ marginRight: '0px !important', fontSize: 18 }}
            />
          )}
        </TreeItemLabel>
      }
    />
  );
};

export default TreeItem;
