import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../../helpers';
import { useSettings } from '../../../hooks';

const boxSx = {
  display: 'flex',
  alignItems: 'center',
  pt: '4px',
  pb: '4px',
  borderBottom: '1px solid #eeeeee',
};

const BcsResultsSingleStat = ({ pickedResults, BCS }) => {
  const { t } = useTranslation();
  const { formatDecimal } = useSettings();

  const arrToNum = BCS ? BCS?.map((q) => Number(q)) : pickedResults?.[0]?.BCS?.map((q) => Number(q));

  const avg = getAvg(arrToNum);
  const n = arrToNum?.length;
  const min = Math.min(...arrToNum);
  const max = Math.max(...arrToNum);
  const range = max - min;
  const sd = getStandardDeviation(arrToNum)?.toFixed(2);
  const cv = coefficientOfVariation(arrToNum)?.toFixed(2);

  return (
    (!!pickedResults?.length || BCS) && (
      <>
        <Box component='div'>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              N
            </Typography>
            <Typography component='span'>{n}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.avg')}
            </Typography>
            <Typography component='span'>{formatDecimal(avg)}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.min')}
            </Typography>
            <Typography component='span'>{formatDecimal(min)}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.max')}
            </Typography>
            <Typography component='span'>{formatDecimal(max)}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.sd')}
            </Typography>
            <Typography component='span'>{formatDecimal(sd)}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.cv')}
            </Typography>
            <Typography component='span'>{formatDecimal(cv)}</Typography>
          </Box>
          <Box component='div' sx={{ ...boxSx, justifyContent: 'space-between', border: 'none' }}>
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('bcs.range')}
            </Typography>
            <Typography component='span'>{formatDecimal(range)}</Typography>
          </Box>
        </Box>
      </>
    )
  );
};

export default BcsResultsSingleStat;
