import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CattleIcon from '../../../assets/HoofIcon';
import { useSettings } from '../../../hooks';
import Tree from '../../shared/tree';

const BcsResultsTree = (props) => {
  const { resultsList = [], handleClick, mobileView, currentResults, onClear } = props;

  const { formatDate } = useSettings();
  const { t } = useTranslation();

  const treeData = useMemo(
    () =>
      resultsList.map((farm) => ({
        id: farm.farm_id,
        label: (
          <Box component='div'>
            <FarmIcon fontSize='small' /> {farm.farm_name}
          </Box>
        ),
        children: farm.locations.map((loc) => ({
          id: `${farm.farm_id}${loc.location_name}`,
          label: (
            <Box component='div'>
              <LocationIcon fontSize='small' /> {loc.location_name}
            </Box>
          ),
          children: loc.cattles.map((cattle) => ({
            id: `${farm.farm_id}${loc.location_name}${cattle.cattle_name}`,
            label: (
              <Box component='div'>
                <CattleIcon fontSize='small' /> {cattle.cattle_name}
              </Box>
            ),
            children: cattle.bcs_versions.map((impl) => ({
              id: `${farm.farm_id}${loc.location_name}${cattle.cattle_name}${impl.oadate}`,
              label: (
                <Box component='div'>
                  <DateIcon fontSize='small' /> {formatDate(impl.oadate, true, true)}
                </Box>
              ),
              selected: currentResults?.find(
                (q) =>
                  q?.OADate === impl?.oadate &&
                  q?.Farm_ID === farm?.farm_id &&
                  q?.LocationName === loc?.location_name &&
                  q?.CattleName === cattle?.cattle_name
              ),
              onClick: () =>
                handleClick({
                  OADate: impl.oadate,
                  Farm_ID: farm.farm_id,
                  FarmName: farm.farm_name,
                  LocationName: loc.location_name,
                  CattleName: cattle.cattle_name,
                }),
            })),
          })),
        })),
      })),
    [resultsList, currentResults]
  );

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('bcs.selectResult')}
        </Typography>
        {currentResults && !!currentResults?.length && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default BcsResultsTree;
