/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterCurrentMixes, getFeedsById, getMixesList, resetMixesStore } from '../../../actions/mixesActions';
import { setExactFeed } from '../../../actions/rationsActions';
import LoadScreen from '../../../components/load-screen';
import { MixesTree, MixesTable, MixesFeedList } from '../../../components/mixes';
import { SideMenu, Toggle, BottomNavigation } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { mixesUnits } from '../../../constants/enums';
import { useLoading } from '../../../hooks';
import { mixesSelector, rationsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TREE: 'tree',
  TABLE: 'table',
};

const MixesPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const mixes = useSelector(mixesSelector);
  const ration = useSelector(rationsSelector);

  const { mixList, feedList, currentMixes, isLoaded } = mixes;

  const { exactFeed } = ration;

  const [unit, setUnit] = useState(mixesUnits.DRY_MATTER);
  const [currentInfo, setCurrentInfo] = useState(undefined);
  // const [collapsed, setCollapse] = useState(true);
  const [collapsed, setCollapse] = useState(!exactFeed && !currentMixes?.length);
  const [currentPage, setCurrentPage] = useState(exactFeed ? tabs.TABLE : tabs.TREE);
  const [fetching, setFetching] = useState(!!exactFeed);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const handleFeedClick = async (feed) => {
    if (currentMixes.find((q) => q.Feed_ID === feed.Feed_ID && q.OADate === feed.OADate)) {
      dispatch(
        filterCurrentMixes(currentMixes.filter((mix) => mix.Feed_ID !== feed.Feed_ID || mix.OADate !== feed.OADate))
      );
      setFetching(false);
    } else {
      try {
        setFetching(true);
        setCurrentInfo(feed);
        await dispatch(getFeedsById(feed));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const getFeedInfo = async (mList) => {
    const filteredFeed = mList
      ?.find((q) => q.farm_id === exactFeed.farmId)
      ?.feeds?.find((fd) => fd?.feed_name === exactFeed?.feedName);

    await handleFeedClick({
      Farm_ID: exactFeed?.farmId,
      farmName: exactFeed?.farmName,
      FeedName: filteredFeed?.feed_name,
      display_formula: filteredFeed?.display_formula,
      Feed_ID: filteredFeed?.feed_id,
      OADate: filteredFeed?.OADates?.[0]?.oadate,
    });

    dispatch(setExactFeed(undefined));
  };

  const onLoad = async () => {
    try {
      startLoading();
      const mList = await dispatch(getMixesList());
      if (exactFeed) {
        await getFeedInfo(mList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetMixesStore());
      dispatch(setExactFeed(undefined));
    };
  }, []);

  const onClear = () => {
    dispatch(filterCurrentMixes([]));
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: { sm: '8px 16px 48px', xs: '8px 0 48px' },
  });

  const navigationTabs = [
    { value: tabs.TREE, label: t('mixes.mixesList'), icon: <TreeIcon /> },
    { value: tabs.TABLE, label: t('mixes.mixView'), icon: <TableIcon /> },
  ];

  return (
    <Container maxWidth={false} sx={{ p: { lg: 0, md: 0, sm: 0, sx: 0 }, position: 'relative' }}>
      {((loading && !isLoaded) || (mobileView && fetching)) && <LoadScreen />}
      {mobileView ? (
        <>
          <Box component='div' sx={getMobileStyles(tabs.TREE)}>
            <Box component='div' sx={{ pb: 2 }}>
              <MixesTree
                currentMixes={currentMixes}
                handleClick={handleFeedClick}
                mixList={mixList}
                mobileView={mobileView}
                onClear={onClear}
              />
              {isLoaded && !mixList?.length && <NoResults text={t('mixes.noResults')} />}
            </Box>
          </Box>
          <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
            <Box component='div' sx={{ margin: '16px 0' }}>
              <Box
                component='div'
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
              >
                <h2>{t('mixes.title')}</h2>
                <Toggle
                  handleChange={setUnit}
                  options={[
                    { label: t('asFed'), value: mixesUnits.AS_FED },
                    { label: t('dryMatter'), value: mixesUnits.DRY_MATTER },
                  ]}
                  value={unit}
                />
              </Box>
              {/*   <MixesFeedList data={currentMixes} unit={unit} /> */}
              <MixesTable data={currentMixes} isMobile={mobileView} loading={fetching} unit={unit} />
            </Box>
          </Box>
          <Paper elevation={3} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation
              activeTab={currentPage}
              onChange={(event, newValue) => {
                if (currentInfo) {
                  setCurrentPage(newValue);
                }
              }}
              tabs={navigationTabs}
            />
          </Paper>
        </>
      ) : (
        <>
          {!!mixList?.length && (
            <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
              <MixesTree
                currentMixes={currentMixes}
                handleClick={handleFeedClick}
                mixList={mixList}
                onClear={onClear}
              />
            </SideMenu>
          )}

          <Container maxWidth={false} sx={{ p: containerPadding }}>
            <Box
              component='div'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: !currentMixes?.length && !collapsed ? 0 : 2,
              }}
            >
              <h2>{t('mixes.title')}</h2>
              {!!feedList?.length && (
                <Toggle
                  handleChange={setUnit}
                  options={[
                    { label: t('asFed'), value: mixesUnits.AS_FED },
                    { label: t('dryMatter'), value: mixesUnits.DRY_MATTER },
                  ]}
                  value={unit}
                />
              )}
            </Box>
            {isLoaded && !mixList?.length && <NoResults text={t('mixes.noResults')} />}
            {!currentMixes?.length && !collapsed && !exactFeed && (
              <Box component='div' sx={{ width: 'fit-content' }}>
                <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                  {t('mixes.warningAlert')}
                </MuiAlert>
              </Box>
            )}

            <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box component='div' sx={{ flex: '1', maxWidth: '100%' }}>
                <MixesTable data={currentMixes} loading={fetching} unit={unit} />
              </Box>
            </Box>
          </Container>
        </>
      )}
    </Container>
  );
};

export default MixesPage;
