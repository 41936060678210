import React, { createContext, useState, useCallback } from 'react';

const AlertContext = createContext({
  isOpen: false,
  variant: 'success',
  message: '',
  showAlert: () => {},
  closeAlert: () => {},
});

const AlertProvider = ({ children }) => {
  const [isOpen, toggleAlert] = useState(false);
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState('');

  const showAlert = (variantValue, messageValue) => {
    setVariant(variantValue);
    setMessage(messageValue);
    toggleAlert(true);
  };

  const closeAlert = () => {
    toggleAlert(false);
    setTimeout(() => setMessage(''), 500);
    setTimeout(() => setVariant('success'), 500);
  };

  const contextValue = {
    isOpen,
    variant,
    message,
    showAlert: useCallback(showAlert, []),
    closeAlert: useCallback(closeAlert, []),
  };

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};

export default AlertProvider;
export { AlertContext };
