import activityLog from './activityLog';
import bcsResults from './bcsResults';
import comparisons from './comparisons';
import currentUser from './currentUser';
import farmVisitResults from './farmVisitResults';
import farms from './farms';
import farmsAccess from './farmsAccess';
import feeds from './feeds';
import mixes from './mixes';
import particleSizeFeedHistory from './particleSizeFeedHistory';
import particleSizeSampleFeed from './particleSizeSampleFeed';
import particleSizeTMRHistory from './particleSizeTMRHistory';
import rations from './rations';
import settings from './settings';

const reducers = {
  currentUser,
  settings,
  farms,
  rations,
  mixes,
  feeds,
  comparisons,
  farmsAccess,
  farmVisitResults,
  activityLog,
  bcsResults,
  particleSizeFeedHistory,
  particleSizeSampleFeed,
  particleSizeTMRHistory,
};

export default reducers;
