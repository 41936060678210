import Stack from '@mui/material/Stack';
import React from 'react';

import SampleFeedCard from './SampleFeedCard';

const SampleTMRList = ({ selectedFeeds = [] }) => (
  <Stack component='div' spacing={2} sx={{ pb: 2 }}>
    {selectedFeeds?.map((sample, idx) => (
      <SampleFeedCard data={sample} key={`card_${idx}`} />
    ))}
  </Stack>
);

export default SampleTMRList;
