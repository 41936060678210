import { urls } from '../constants';
import { initialState } from '../reducers/settings';

import axios from './axios';

// Farms

const getUsersFarms = () => axios.get(urls.user.farms);

const getUsersFarmsAsync = async () => {
  const response = await axios.get(urls.user.farms);
  return response;
};

const getUsersFarmRations = () => axios.get(urls.user.farmsRation);

// Settings

const getUserSettingsAsync = async () => {
  const response = await axios.get(urls.user.settings);
  if (response) {
    const {
      data: { user_settings = {} },
    } = response;

    return user_settings;
  } else {
    await axios.post(urls.user.settings, initialState);
    const res = await axios.get(urls.user.settings);
    const {
      data: { user_settings = {} },
    } = res;

    return user_settings;
  }
};

// const updateUserSettingsAsync = async (settings) => {
//   const response = await axios.post(urls.user.settings, { ...settings }, { headers: authHeader() });
//   return response;
// };

const updateUserSettingsAsync = async (settings) => {
  const response = await axios.patch(urls.user.settings, { ...settings });
  return response;
};

// Rations

const getRationById = async (ration) => {
  const response = await axios.post(urls.user.rations, { ...ration });
  return response;
};

const getUserTemplates = async () => {
  const response = await axios.get(urls.user.templates);
  return response;
};

const getNutrientById = async (nutrient) => {
  const response = await axios.post(urls.user.nutrients, { ...nutrient });
  return response;
};

const getMixesList = async () => {
  const response = await axios.get(urls.user.mixes);
  return response;
};

const getFeedsList = async () => {
  const response = await axios.get(urls.user.feedsList);
  return response;
};

const getFeedsById = async (obj) => {
  const response = await axios.post(urls.user.feeds, { ...obj });
  return response;
};

const getFeedView = async (obj) => {
  const response = await axios.post(urls.user.feedView, { ...obj });
  return response;
};

const userService = {
  getUsersFarms,
  getUsersFarmsAsync,
  getUsersFarmRations,
  getUserSettingsAsync,
  getRationById,
  getNutrientById,
  getUserTemplates,
  updateUserSettingsAsync,
  getMixesList,
  getFeedsList,
  getFeedView,
  getFeedsById,
};

export default userService;
