import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const AlertRef = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />);

const Alert = (props) => {
  const { open, onClose, variant, message, vertical = 'bottom', horizontal = 'center' } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={handleClose} open={open}>
      <AlertRef onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </AlertRef>
    </Snackbar>
  );
};

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  message: PropTypes.string,
  vertical: PropTypes.oneOf(['top', 'bottom']),
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
};

export default Alert;
