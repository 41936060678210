import Cookies from 'js-cookie';

export const authHeaders = () => {
  const token = Cookies.get('amtsAccessToken');

  if (token) {
    return {
      'x-access-token': token,
      'content-type': 'application/json',
    };
  }

  return {};
};

export const isTokenError = (res) => res?.status === 401;
