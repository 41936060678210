/* eslint-disable max-len */
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../../helpers';
import { useSettings } from '../../../hooks';
import { Skeleton, Table } from '../../shared';

const SampleTMRTable = (props) => {
  const { data, loading } = props;
  const { t } = useTranslation();

  const { formatDate, formatDecimal } = useSettings();

  const avgFormatter = (field) => {
    const result = getAvg(data?.map((item) => Number(item[field])));
    return {
      label: t('avg'),
      formatter: formatDecimal(result),
    };
  };

  const sdFormatter = (field) => {
    const result = getStandardDeviation(data?.map((item) => Number(item[field])));
    return {
      label: t('sd'),
      formatter: formatDecimal(result),
    };
  };

  const cvFormatter = (field) => {
    const result = coefficientOfVariation(data?.map((item) => Number(item[field])));
    return {
      label: t('cv'),
      formatter: formatDecimal(result),
    };
  };

  const columns = [
    {
      title: t('info'),
      field: 'farmName',
      formatter: (value, row) => (
        <Box component='div' sx={{ whiteSpace: 'pre-line' }}>
          {`${row?.RecipeName}\n${formatDate(row?.OADate, true, true)}`}
        </Box>
      ),
    },
    {
      title: t('psc.topPan'),
      field: 'TopPercent',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.secondPan'),
      field: 'SecondPanPercent',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.thirdPan'),
      field: 'ThirdPanPercent',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.bottomPan'),
      field: 'BottomPanPercent',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.pef'),
      field: 'pef',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.peNDF'),
      field: 'peNDF',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
    {
      title: t('psc.peCHO_C'),
      field: 'peCHO_C',
      formatter: (value) => `${formatDecimal(value)}%`,
    },
  ];

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      customFormatters={[avgFormatter, sdFormatter, cvFormatter]}
      keyField='FeedName'
      rows={data}
    />
  );
};

export default SampleTMRTable;
