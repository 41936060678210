import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { bcsStepOptions } from '../../constants/bcs';
import { Button, Select } from '../shared';
import { ViewPdfModal, ConfirmationModal } from '../shared/modal';

import ScoreButton from './ScoreButton';

const referenceFiles = [
  { url: 'beef_bcs.pdf', title: 'Body Condition Scoring Beef Cows' },
  { url: 'condition_scores.pdf', title: 'Examples of Cows at Various Body' },
  { url: 'dairy_cattle_bcs.pdf', title: 'Body Condition Scoring in Dairy Cattle' },
];

const Step3 = (props) => {
  const { t } = useTranslation();

  const { onSubmit, loading, formValues, onBack, resetScores } = props;
  const { BCSSetting = {}, NumAnimals = 100, BCS = [] } = formValues;

  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(BCS || []);
  const [value, setValue] = useState(BCS[0] || '');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [resetModalOpen, toggleResetModal] = useState(false);

  const number = useMemo(() => {
    if (!BCSSetting || !BCSSetting.Percent_to_BCS) {
      return 10;
    }
    return NumAnimals * BCSSetting.Percent_to_BCS > 10 ? Math.round(NumAnimals * BCSSetting.Percent_to_BCS) : 10;
  }, [formValues]);

  const onBackClick = () => {
    if (activeStep === 0) {
      onBack();
    } else {
      setActiveStep(activeStep - 1);
      setValue(data[activeStep - 1]);
    }
  };

  const switchToNextStep = (v) => {
    const temp = [...data];
    temp[activeStep] = v;
    setData(temp);
    setActiveStep(activeStep + 1);
    setValue(temp[activeStep + 1] || '');
  };

  const onNextClick = () => {
    if (activeStep === number - 1) {
      const temp = [...data];
      temp[activeStep] = value;
      onSubmit(temp);
    } else {
      switchToNextStep(value);
    }
  };

  const onReset = () => {
    setData([]);
    setValue('');
    setActiveStep(0);
    resetScores();
    toggleResetModal(false);
  };

  const buttons = useMemo(() => {
    let result = [];
    const systemId = BCSSetting?.BCSSystemId || 1;
    const option = bcsStepOptions[systemId];

    if (option && option.step) {
      for (let i = 1; i <= 5; i += option.step) {
        result?.push({ label: i, value: i });
      }
    } else {
      result = [
        { label: t('bcs.tooThin'), value: 2 },
        { label: t('bcs.justRight'), value: 3 },
        { label: t('bcs.tooFat'), value: 4 },
      ];
    }

    return result;
  }, [BCSSetting]);

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const options = useMemo(
    () => new Array(number)?.fill('')?.map((item, idx) => ({ value: idx, label: `${idx + 1}` })),
    [number]
  );

  return (
    <Box component='div' sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box component='div' sx={{ width: { xs: '100%', sm: '100%', md: '60%' } }}>
        <Box component='div' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2, mt: 2 }}>
          {BCS && BCS?.length ? (
            <>
              <Select
                id='template-select'
                label={''}
                minWidth={70}
                onChange={(e) => {
                  setActiveStep(e.target.value);
                  setValue(data[e.target.value]);
                }}
                options={options}
                sx={{ width: '70px' }}
                value={activeStep}
              />
              <Typography
                component='div'
                sx={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  color: (theme) => theme?.palette?.primary?.greyDark,
                }}
              >
                {`/${number}`}
              </Typography>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography
                component='div'
                sx={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  color: (theme) => theme?.palette?.primary?.greyDark,
                }}
              >
                {`${data?.length}/${number}`}
              </Typography>
              <Typography
                component='div'
                sx={{
                  marginTop: 2,
                  fontSize: 16,
                  color: (theme) => theme?.palette?.primary?.greyDark,
                }}
              >
                {`Cow #${activeStep + 1}`}
              </Typography>
            </Box>
          )}
        </Box>

        <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              mt: 1,
              mb: 1,
              maxWidth: '470px',
            }}
          >
            {buttons?.map((btn) => (
              <ScoreButton
                active={value === btn.value}
                key={btn.value}
                onClick={() => {
                  setValue(btn.value);
                  if (activeStep + 1 !== number) {
                    switchToNextStep(btn.value);
                  } else {
                    const temp = [...data];
                    temp[activeStep] = btn.value;
                    setData(temp);
                  }
                }}
                text={btn.label}
                type={Number(BCSSetting?.BCSSystemId) === 4 ? 'string' : 'number'}
              />
            ))}
          </Box>
        </Box>

        <Box component='div'>
          <Typography component='div' sx={{ fontWeight: 'bold', mb: 1 }}>{`${t('bcs.referenceImages')}:`}</Typography>
          {referenceFiles?.map((file, index) => (
            <Box
              component='div'
              key={index}
              onClick={() => setPdfUrl(file.url)}
              sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                color: (theme) => theme?.palette?.primary?.main,
                cursor: 'pointer',
              }}
            >
              <PictureAsPdfIcon fontSize='small' sx={{ mr: 1 }} />
              <Typography component='span'>{file.title}</Typography>
            </Box>
          ))}
        </Box>

        {mobileView ? (
          <Box component='div' sx={{ mt: 3 }}>
            <LinearProgress
              sx={{ flex: 1 }}
              value={activeStep + 1 === number && value ? 100 : (activeStep / number) * 100}
              variant='determinate'
            />
            <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
              <Button loading={loading} onClick={onBackClick} outlined type='submit'>
                {t('back')}
              </Button>
              <Button disabled={loading} onClick={() => toggleResetModal(true)} outlined type='submit'>
                {t('reset')}
              </Button>
              <Button disabled={loading || !value} loading={loading} onClick={onNextClick} type='submit'>
                {activeStep + 1 === number ? t('submit') : t('next')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
            <Box component='div' sx={{ display: 'flex' }}>
              <Button loading={loading} onClick={onBackClick} outlined sx={{ mr: 1 }} type='submit'>
                {t('back')}
              </Button>
              <Button disabled={loading} onClick={() => toggleResetModal(true)} outlined type='submit'>
                {t('reset')}
              </Button>
            </Box>
            <LinearProgress
              sx={{ flex: 1, ml: 2, mr: 2 }}
              value={activeStep + 1 === number && value ? 100 : (data?.length / number) * 100}
              variant='determinate'
            />
            <Button disabled={loading || !value} loading={loading} onClick={onNextClick} type='submit'>
              {activeStep + 1 === number ? t('submit') : t('next')}
            </Button>
          </Box>
        )}
      </Box>

      <ViewPdfModal onClose={() => setPdfUrl(null)} open={!!pdfUrl} title={t('bcs.referenceImages')} url={pdfUrl} />

      <ConfirmationModal
        onClose={() => toggleResetModal(false)}
        onSubmit={onReset}
        open={resetModalOpen}
        submitText={t('reset')}
        text={t('bcs.resetScoring')}
      />
    </Box>
  );
};

export default Step3;
