import { sum } from 'lodash';

const descendingComparator = (a, b, orderBy) => {
  if (a[orderBy] > b[orderBy]) {
    return -1;
  }
  if (a[orderBy] < b[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const getDecimalPrecision = (arr = []) => {
  const decimal = arr.map((n) => {
    const digits = n.split('.')[1];
    return digits ? digits.length : 0;
  });
  return decimal && !!decimal.length ? Math.max(...decimal) : 2;
};

export const getTotal = (array = []) => {
  const arr = array?.map((item) => item?.replace(',', '.'));
  const decimalPrecision = getDecimalPrecision(arr);
  const result = sum(arr.map((n) => Number(n)));
  return result?.toFixed(decimalPrecision);
};
