/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import percentile from 'percentile';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';

import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';

import {
  BcsHistogram,
  BcsResultsCandlechart,
  BcsResultsInfo,
  BcsResultsList,
  BcsResultsSingleStat,
  BcsResultsTable,
  BcsResultsTree,
} from '../../../components/body-condition-score';
import LoadScreen from '../../../components/load-screen';
import { SideMenu, BottomNavigation } from '../../../components/shared';
import { TabPanel, Tabs } from '../../../components/shared/tabs';

import { useLoading } from '../../../hooks';

import {
  filterCurrentResults,
  getBcsResultsInfo,
  getBcsResultsList,
  resetBcsResultsStore,
} from '../../../actions/bcsResultsActions';
import { bcsResultsSelector } from '../../../reducers/selectors';

import { fromOADate, getAvg } from '../../../helpers';

import { containerPadding } from '../../../styles/theme/shared';
import NoResults from '../../../components/shared/no-results';
import { routes } from '../../../constants';

const tabs = {
  TABLE: 0,
  GRAPH: 1,
  TREE: 2,
};

const BCSResultsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bcsResults = useSelector(bcsResultsSelector);

  const [collapsed, setCollapse] = useState(true);
  const [currentPage, setCurrentPage] = useState(tabs.TREE);
  const [currentInfo, setCurrentInfo] = useState();
  const [fetching, setFetching] = useState(false);
  const [isTrend, setIsTrend] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs.TABLE);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const { resultsList, pickedResults, isLoaded } = bcsResults;

  const handleResultClick = async (info) => {
    if (
      pickedResults.find(
        (q) =>
          q.CattleName === info.CattleName &&
          q.Farm_ID === info.Farm_ID &&
          q.LocationName === info.LocationName &&
          q.OADate === info.OADate
      )
    ) {
      dispatch(
        filterCurrentResults(
          pickedResults.filter(
            (q) =>
              !(
                q.CattleName === info.CattleName &&
                q.Farm_ID === info.Farm_ID &&
                q.LocationName === info.LocationName &&
                q.OADate === info.OADate
              )
          )
        )
      );
      setFetching(false);
    } else {
      try {
        setFetching(true);
        setCurrentInfo(info);
        await dispatch(getBcsResultsInfo(info));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getBcsResultsList());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetBcsResultsStore());
    };
  }, []);

  const linearRegression = (y, x) => {
    const lr = {};
    const n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {
      sum_x += x[i];
      sum_y += y[i];
      sum_xy += x[i] * y[i];
      sum_xx += x[i] * x[i];
      sum_yy += y[i] * y[i];
    }

    lr.slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    lr.intercept = (sum_y - lr.slope * sum_x) / n;
    lr.r2 = Math.pow(
      (n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
      2
    );

    return lr;
  };

  const onClear = () => {
    dispatch(filterCurrentResults([]));
  };

  const data = useMemo(() => {
    if (pickedResults.length > 1) {
      const sortedData = pickedResults.sort((a, b) => a.OADate - b.OADate);
      const isAllSameGroup =
        sortedData.filter(
          (el) =>
            sortedData[0].Farm_ID === el.Farm_ID &&
            sortedData[0].CattleName === el.CattleName &&
            sortedData[0].LocationName === el.LocationName
        )?.length === sortedData?.length;
      const isTrendEnabled = isTrend;
      const resultsWithoutOutliers = [];
      const allOutliers = [];
      const candleData = [];
      const meanData = [];

      pickedResults.forEach((el, index) => {
        const bcs = el?.BCS.map((q) => Number(q));
        const top = percentile(75, bcs);
        const bottom = percentile(25, bcs);
        const diff = top - bottom;
        const upperFence = top + 1.5 * diff;
        const lowerFence = bottom - 1.5 * diff;
        const withoutOutliers = [];

        bcs.forEach((score) => {
          if (score > upperFence || score < lowerFence) {
            allOutliers.push({
              x: isTrendEnabled ? fromOADate(el.OADate) : index + 1,
              y: score,
            });
          } else {
            withoutOutliers.push(score);
          }
        });

        resultsWithoutOutliers.push({
          ...el,
          BCS: withoutOutliers,
        });
      });

      resultsWithoutOutliers.forEach((el, index) => {
        const bcs = el?.BCS.map((q) => Number(q));
        const top = percentile(75, bcs);
        const min = Math.min(...bcs);
        const max = Math.max(...bcs);
        const bottom = percentile(25, bcs);
        const median = percentile(50, bcs);
        const avg = getAvg(bcs);

        candleData.push({
          x: isTrendEnabled ? fromOADate(el.OADate) : index + 1,
          y: [min, bottom, median, top, max],
        });

        meanData.push({
          x: isTrendEnabled ? fromOADate(el.OADate) : index + 1,
          y: avg,
        });
      });

      const yval = resultsWithoutOutliers.map((el) => {
        const bcs = el?.BCS.map((q) => Number(q));
        return percentile(50, bcs);
      });
      const xval = resultsWithoutOutliers.map((q) => fromOADate(q.OADate)?.getTime());
      const lr = linearRegression(yval, xval);
      const sortedWithoutOutliers = resultsWithoutOutliers.sort((a, b) => a.OADate - b.OADate);
      const firstEL = sortedWithoutOutliers?.[0];
      const lastDate = sortedWithoutOutliers?.[sortedWithoutOutliers?.length - 1];

      const trendData = [
        {
          x: fromOADate(firstEL.OADate),
          y: (lr.intercept + lr.slope * fromOADate(firstEL.OADate)?.getTime())?.toFixed(2),
        },
        {
          x: fromOADate(lastDate.OADate),
          y: (lr.intercept + lr.slope * fromOADate(lastDate.OADate)?.getTime())?.toFixed(2),
        },
      ];

      return {
        candleData,
        meanData,
        trendData,
        isAllSameGroup,
        isTrendEnabled,
        sortedData,
        allOutliers,
        resultsWithoutOutliers,
      };
    } else {
      return { pickedResults };
    }
  }, [pickedResults, isTrend]);

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('bcs.tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('bcs.scoreView'),
      icon: <TableIcon />,
      disabled: !pickedResults.length,
    },
    {
      value: tabs.GRAPH,
      label: t('bcs.graph'),
      icon: <ChartIcon />,
      disabled: !pickedResults.length,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          p: {
            lg: 0,
            md: 0,
            sm: 0,
            sx: 0,
          },
          position: 'relative',
        }}
      >
        {((loading && !isLoaded) || fetching) && <LoadScreen />}
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <BcsResultsTree
                    resultsList={resultsList}
                    handleClick={handleResultClick}
                    currentResults={pickedResults}
                    onClear={onClear}
                    mobileView
                  />
                  {isLoaded && !resultsList?.length && (
                    <NoResults text={t('bcs.noResults')} url={routes.BCS_NEW_SCORE} />
                  )}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    margin: '8px 0 16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('bcs.bcsScore')}
                </Typography>
                {/*   <BcsResultsList data={data} />*/}
                <BcsResultsTable data={pickedResults} />
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.GRAPH)}>
                {pickedResults.length === 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('bcs.graphTitle')}
                    </Typography>
                    <Paper>
                      <BcsHistogram
                        systemId={pickedResults[0]?.BCSSetting?.BCSSystem?.id}
                        data={pickedResults[0]?.BCS}
                      />
                    </Paper>
                  </Box>
                )}
                {pickedResults.length > 1 && (
                  <Box component='div'>
                    <Typography
                      variant='h6'
                      component='h6'
                      sx={{
                        margin: '8px 0 0',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('bcs.graphTitle')}
                    </Typography>
                    <Paper sx={{ mt: 2 }}>
                      <BcsResultsCandlechart isTrend={isTrend} setIsTrend={setIsTrend} data={data} />
                    </Paper>
                  </Box>
                )}
              </Box>
              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              {!!resultsList?.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <BcsResultsTree
                    resultsList={resultsList}
                    handleClick={handleResultClick}
                    currentResults={pickedResults}
                    onClear={onClear}
                  />
                </SideMenu>
              )}
              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box component='div' sx={{ width: '100%' }}>
                    <h2>{t('bcs.results')}</h2>
                    {isLoaded && !resultsList?.length && (
                      <NoResults text={t('bcs.noResults')} url={routes.BCS_NEW_SCORE} />
                    )}
                    {!pickedResults?.length && !collapsed && (
                      <Box component='div' sx={{ width: 'fit-content' }}>
                        <MuiAlert variant='outlined' severity='warning' icon={<ArrowBackIcon />}>
                          {t('bcs.warningAlert')}
                        </MuiAlert>
                      </Box>
                    )}
                    {pickedResults.length > 1 && (
                      <Box component='div'>
                        <Tabs
                          activeTab={activeTab}
                          onChange={setActiveTab}
                          tabs={[
                            {
                              label: t('bcs.table'),
                              value: tabs.TABLE,
                              icon: <TableIcon />,
                            },
                            {
                              label: t('bcs.graph'),
                              value: tabs.GRAPH,
                              icon: <ChartIcon />,
                            },
                          ]}
                        />

                        <TabPanel value={activeTab} index={tabs.TABLE}>
                          <BcsResultsTable data={pickedResults} />
                        </TabPanel>

                        <TabPanel value={activeTab} index={tabs.GRAPH}>
                          <Paper sx={{ pt: 1 }}>
                            <BcsResultsCandlechart isTrend={isTrend} setIsTrend={setIsTrend} data={data} />
                          </Paper>
                        </TabPanel>
                      </Box>
                    )}
                    {currentInfo && pickedResults.length === 1 && (
                      <Box component='div' sx={{ display: 'flex' }}>
                        <Box
                          component='div'
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minWidth: 350,
                          }}
                        >
                          <Paper
                            component='div'
                            sx={{
                              p: 2,
                              mb: 1,
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <BcsResultsInfo currentInfo={currentInfo} />
                          </Paper>
                          <Paper
                            component='div'
                            sx={{
                              p: 2,
                              mt: 1,
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <BcsResultsSingleStat pickedResults={pickedResults} />
                          </Paper>
                        </Box>
                        <Paper
                          elevation={1}
                          sx={{
                            pb: 1,
                            pt: 0,
                            pr: 1,
                            ml: {
                              xs: 0,
                              sm: 0,
                              md: 2,
                            },
                            mt: {
                              xs: 2,
                              sm: 2,
                              md: 0,
                            },
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '> div': { flex: 1 },
                          }}
                        >
                          {pickedResults.length === 1 && (
                            <BcsHistogram
                              systemId={pickedResults[0]?.BCSSetting?.BCSSystem?.id}
                              data={pickedResults[0]?.BCS}
                            />
                          )}
                        </Paper>
                      </Box>
                    )}
                  </Box>
                </Box>
                {pickedResults?.length > 0 && (
                  <Fab
                    sx={{
                      margin: 0,
                      top: 'auto',
                      right: 20,
                      bottom: 20,
                      left: 'auto',
                      position: 'fixed',
                      fontWeight: 'bold',
                    }}
                    variant='extended'
                    onClick={() => window.print()}
                    color='primary'
                    aria-label='add'
                  >
                    <PrintIcon sx={{ mr: 1 }} />
                    {t('print')}
                  </Fab>
                )}
              </Container>
            </>
          )}
        </div>
      </Container>
      <div className='print-charts'>
        <Box
          component='div'
          sx={{
            width: '800px',
            height: '100%',
            '> div': {
              minHeight: '600px',
            },
          }}
        >
          {pickedResults.length === 1 && (
            <BcsHistogram systemId={pickedResults[0]?.BCSSetting?.BCSSystem?.id} data={pickedResults[0]?.BCS} />
          )}
          {pickedResults.length > 1 && <BcsResultsCandlechart isTrend={isTrend} setIsTrend={setIsTrend} data={data} />}
        </Box>
      </div>
    </>
  );
};

export default BCSResultsPage;
