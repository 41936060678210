import Container from '@mui/material/Container';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserSettings } from '../../../actions/userActions';
import { SettingsForm } from '../../../components/settings';
import { useLoading, useAlert } from '../../../hooks';
import { settingsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const SettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSettings = useSelector(settingsSelector);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { showAlert } = useAlert();

  const onSubmit = async (field, value) => {
    startLoading();
    try {
      await dispatch(updateUserSettings({ [field]: value }));
    } catch (err) {
      showAlert('error', err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{t('settings.title')}</h2>
      <SettingsForm initialValues={userSettings} loading={loading} onSubmit={onSubmit} />
    </Container>
  );
};

export default SettingsPage;
