import { FILTER_CURRENT_PSC_FEEDS, SET_CURRENT_PSC_FEEDS, SET_FEED_HISTORY_LIST } from '../actions/actionTypes';

const initialState = {
  historyList: [],
  selectedFeeds: [],
  isLoaded: false,
};

const particleSizeFeedHistoryReducer = (state = initialState, action = {}) => {
  const { historyList, selectedFeeds } = action;

  switch (action.type) {
    case SET_FEED_HISTORY_LIST:
      return {
        ...state,
        historyList,
        isLoaded: true,
      };
    case SET_CURRENT_PSC_FEEDS:
      return {
        ...state,
        selectedFeeds: [...state.selectedFeeds, selectedFeeds],
      };
    case FILTER_CURRENT_PSC_FEEDS:
      return {
        ...state,
        selectedFeeds,
      };
    default:
      return state;
  }
};

export default particleSizeFeedHistoryReducer;
