/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { filterCurrentSamples } from '../../actions/pscTMRHistoryActions';
import { routes } from '../../constants';
import { useAlert, useLoading } from '../../hooks';
import { farmsSelector } from '../../reducers/selectors';
import ParticleSizeService from '../../services/particleSizeService';
import { Modal, Select } from '../shared';

import SampleFeedForm from './SampleFeedForm';

const initialValues = {
  Farm_ID: '',
  LocationName: '',
  CattleName: '',
  RecipeName: '',
  TopAmount: '',
  SecondPanAmount: '',
  ThirdPanAmount: '',
  BottomPanAmount: '',
  ShakerTypeId: '2',
};

const SingleSampleTMRModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userFarms = useSelector(farmsSelector);
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const { startLoading, loading, stopLoading } = useLoading(true);

  const { open, onClose } = props;

  const { farmsList } = userFarms;

  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCattle, setSelectedCattle] = useState();
  const [selectedRecipe, setSelectedRecipe] = useState();

  const locationOptions = useMemo(() => {
    if (!selectedFarm) {
      return [];
    }
    const farm = farmsList?.find((f) => f.farm_id === selectedFarm);
    return farm && farm.locations
      ? farm.locations?.map((location) => ({
          label: location?.location_name || '',
          value: location?.location_name,
        }))
      : [];
  }, [selectedFarm]);

  const cattleOptions = useMemo(() => {
    if (!selectedFarm || !selectedLocation) {
      return [];
    }
    const farm = farmsList?.find((f) => f.farm_id === selectedFarm);
    if (!farm || !farm.locations) {
      return [];
    }
    const location = farm.locations.find((l) => l.location_name === selectedLocation);
    return location && location.cattles
      ? location.cattles?.map((cattle) => ({
          label: cattle?.cattle_name || '',
          value: cattle?.cattle_name,
        }))
      : [];
  }, [selectedFarm, selectedLocation]);

  const recipeOptions = useMemo(() => {
    if (!selectedFarm || !selectedLocation || !selectedCattle) {
      return [];
    }
    const farm = farmsList?.find((f) => f.farm_id === selectedFarm);
    if (!farm || !farm.locations) {
      return [];
    }
    const location = farm.locations.find((l) => l.location_name === selectedLocation);
    const cattle = location.cattles.find((l) => l.cattle_name === selectedCattle);
    const implementation = new Set();
    if (cattle && cattle.implementations) {
      cattle.implementations?.forEach((impl) => {
        implementation.add(impl?.recipe);
      });
    }
    return Array.from(implementation)?.map((el) => ({
      label: el || '',
      value: el,
    }));
  }, [selectedFarm, selectedLocation, selectedCattle]);

  const onSubmit = async (formObj) => {
    startLoading();
    const FarmName = farmsList.find((el) => el.farm_id === formObj.Farm_ID)?.farm_name;
    try {
      const values = {
        Farm_ID: formObj.Farm_ID,
        LocationName: formObj.LocationName,
        CattleName: formObj.CattleName,
        RecipeName: formObj.RecipeName,
        TopAmount: Number(formObj.TopAmount) || 0,
        SecondPanAmount: Number(formObj.SecondPanAmount) || 0,
        ThirdPanAmount: Number(formObj.ThirdPanAmount) || 0,
        BottomPanAmount: Number(formObj.BottomPanAmount) || 0,
        ShakerTypeId: Number(formObj.ShakerTypeId) || 0,
      };

      const res = await ParticleSizeService.createTMRSample(values);
      dispatch(
        filterCurrentSamples([
          {
            ...res?.data?.tmr,
            FarmName,
          },
        ])
      );
      showAlert('success', t('psc.successTMRAlert'));
    } catch (err) {
      console.error(err);
      showAlert('error', JSON.stringify(err));
    } finally {
      stopLoading();
      onClose();
      navigate(routes.PSC_TMR_HISTORY);
    }
  };

  const form = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    if (!open) {
      form.resetForm();
      setSelectedFarm(null);
      setSelectedLocation(null);
      setSelectedCattle(null);
      setSelectedRecipe(null);
      stopLoading();
    }
  }, [open]);

  const submitDisabled =
    !form.values.Farm_ID ||
    !form.values.LocationName ||
    !form.values.CattleName ||
    !form.values.RecipeName ||
    isNaN(form.values.BottomPanAmount) ||
    isNaN(form.values.TopAmount) ||
    isNaN(form.values.SecondPanAmount) ||
    isNaN(form.values.ThirdPanAmount) ||
    !form.values.SecondPanAmount.length ||
    !form.values.BottomPanAmount.length ||
    !form.values.ThirdPanAmount.length ||
    !form.values.TopAmount.length;

  const handleSubmit = () => {
    form.handleSubmit();
  };

  return (
    <Modal
      closeText={t('cancel')}
      maxWidth='xs'
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      submitDisabled={submitDisabled || loading}
      submitLoading={loading}
      title={t('psc.singleSampleTMR')}
    >
      <Box component='div' sx={{ pt: 1 }}>
        <Stack component='div' spacing={2}>
          <Select
            id='farm-select'
            label={t('farm')}
            name='Farm_ID'
            onChange={(e) => {
              form.handleChange(e);
              form.setFieldValue('Feed_ID', '');
              setSelectedFarm(e.target.value);
              setSelectedLocation(null);
              setSelectedCattle(null);
            }}
            options={farmsList?.map((farm) => ({
              label: farm?.farm_name,
              value: farm?.farm_id,
            }))}
            value={form.values.Farm_ID}
          />
          <Select
            disabled={!selectedFarm}
            id='location-select'
            label={t('location')}
            name='LocationName'
            onChange={(e) => {
              form.handleChange(e);
              form.setFieldValue('CattleName', '');
              setSelectedLocation(e.target.value);
              setSelectedCattle(null);
            }}
            options={locationOptions}
            value={form.values.LocationName}
          />
          <Select
            disabled={!selectedLocation}
            id='cattle-select'
            label={t('cattle')}
            name='CattleName'
            onChange={(e) => {
              form.handleChange(e);
              form.setFieldValue('RecipeName', '');
              setSelectedRecipe(null);
              setSelectedCattle(e.target.value);
            }}
            options={cattleOptions}
            value={form.values.CattleName}
          />
          <Select
            disabled={!selectedCattle}
            id='recipe-select'
            label={t('recipe')}
            name='RecipeName'
            onChange={(e) => {
              form.handleChange(e);
              setSelectedRecipe(e.target.value);
            }}
            options={recipeOptions}
            value={form.values.RecipeName}
          />
          {selectedRecipe && <SampleFeedForm form={form} />}
        </Stack>
      </Box>
    </Modal>
  );
};

export default SingleSampleTMRModal;
