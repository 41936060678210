/* eslint-disable no-restricted-globals */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';

const regex = /^\d*\.?\d{0,1}$/;

const SampleFeedForm = (props) => {
  const { t } = useTranslation();

  const { form } = props;
  const { formatLabel } = useSettings();

  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '> span': {
      lineHeight: 1,
    },
    mt: '4px',
  };
  const inputStyle = {
    maxWidth: 60,
    input: {
      fontSize: '14px',
    },
  };

  return (
    <Box
      component='div'
      sx={{
        background: '#ffffff',
        padding: '4px 16px 20px',
        borderRadius: '6px',
        border: (theme) => `1px solid ${theme?.palette?.primary?.greyDark}`,
      }}
    >
      <Box component='div' sx={rowStyle}>
        <Typography
          component='span'
          sx={{
            mr: 1,
            fontWeight: 'bold',
          }}
        >
          {t('psc.topPanAmountG')}
        </Typography>
        <TextField
          error={form.values.TopAmount && isNaN(form.values.TopAmount)}
          id='pcs-m-1'
          name='TopAmount'
          onChange={(e) => {
            const value = e.target?.value;
            if (regex.test(value)) {
              form.setFieldValue('TopAmount', value);
            }
          }}
          sx={inputStyle}
          value={form.values.TopAmount}
          variant='standard'
        />
      </Box>
      <Box component='div' sx={rowStyle}>
        <Typography
          component='span'
          sx={{
            mr: 1,
            fontWeight: 'bold',
          }}
        >
          {t('psc.secondPanAmountG')}
        </Typography>
        <TextField
          error={form.values.SecondPanAmount && isNaN(form.values.SecondPanAmount)}
          id='pcs-m-1'
          name='SecondPanAmount'
          onChange={(e) => {
            const value = e.target?.value;
            if (regex.test(value)) {
              form.setFieldValue('SecondPanAmount', value);
            }
          }}
          sx={inputStyle}
          value={form.values.SecondPanAmount}
          variant='standard'
        />
      </Box>
      <Box component='div' sx={rowStyle}>
        <Typography
          component='span'
          sx={{
            mr: 1,
            fontWeight: 'bold',
          }}
        >
          {t('psc.thirdPanAmountG')}
        </Typography>
        <TextField
          error={form.values.ThirdPanAmount && isNaN(form.values.ThirdPanAmount)}
          id='pcs-m-1'
          name='ThirdPanAmount'
          onChange={(e) => {
            const value = e.target?.value;
            if (regex.test(value)) {
              form.setFieldValue('ThirdPanAmount', value);
            }
          }}
          sx={inputStyle}
          value={form.values.ThirdPanAmount}
          variant='standard'
        />
      </Box>
      <Box component='div' sx={rowStyle}>
        <Typography
          component='span'
          sx={{
            mr: 1,
            fontWeight: 'bold',
          }}
        >
          {t('psc.bottomPanAmountG')}
        </Typography>
        <TextField
          error={form.values.BottomPanAmount && isNaN(form.values.BottomPanAmount)}
          id='pcs-m-1'
          name='BottomPanAmount'
          onChange={(e) => {
            const value = e.target?.value;
            if (regex.test(value)) {
              form.setFieldValue('BottomPanAmount', value);
            }
          }}
          sx={inputStyle}
          value={form.values.BottomPanAmount}
          variant='standard'
        />
      </Box>
    </Box>
  );
};

export default SampleFeedForm;
