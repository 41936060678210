import findDeep from 'deepdash/findDeep';
import React from 'react';

import TreeItem from './TreeItem';
import TreeWrap from './TreeWrap';

const Tree = (props) => {
  const { data, mobileView } = props;

  const renderTree = (node) => {
    let childSelected = false;
    if (node && node.children) {
      childSelected = !!findDeep(node, (i) => !!i.selected, { childrenPath: 'children' });
    }

    return (
      <TreeItem
        childSelected={childSelected}
        key={`${node.id}_${Math.random()}`}
        label={node.label}
        nodeId={node.id}
        onClick={node.onClick}
        selected={node.selected}
      >
        {Array.isArray(node.children) ? node.children.map(renderTree) : null}
      </TreeItem>
    );
  };

  return <TreeWrap sx={{ minWidth: mobileView ? 'unset' : 320 }}>{data.map((node) => renderTree(node))}</TreeWrap>;
};

export default Tree;
