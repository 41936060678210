import axios from 'axios';
import Cookies from 'js-cookie';

import { authHeaders, isTokenError } from './authUtils';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

AxiosInstance?.interceptors.request.use((req) => {
  const config = { ...req, headers: authHeaders() };
  return config;
});

AxiosInstance?.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isTokenError(error?.response)) {
      // Cookies.remove('amtsAccessToken');
      // window.location.href = '/';
    }
  }
);

export default AxiosInstance;
