import { SET_FARMS_ACCESS_LIST, SET_USERS_LIST, SET_MILLS_LIST } from '../actions/actionTypes';

const initialState = {
  millsList: [],
  farmsList: [],
  usersList: [],
};

const farmsAccessReducer = (state = initialState, action = {}) => {
  const { millsList = [], farmsList = [], usersList = [] } = action;

  switch (action.type) {
    case SET_MILLS_LIST:
      return {
        ...state,
        millsList,
      };
    case SET_FARMS_ACCESS_LIST:
      return {
        ...state,
        farmsList,
      };
    case SET_USERS_LIST:
      return {
        ...state,
        usersList,
      };
    default:
      return state;
  }
};

export default farmsAccessReducer;
