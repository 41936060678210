import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';

import SampleTMRColumnChart from './SampleTMRColumnChart';
import SampleTMRpeNDFChart from './SampleTMRPeNDFChart';
import SampleTMRPefChart from './SampleTMRPefChart';

const PaperWrap = (props) => (
  <Paper
    elevation={1}
    sx={{
      mb: 2,
      pb: 1,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '> div': { flex: 1 },
    }}
  >
    {props.children}
  </Paper>
);

const SampleTMRChartsContainer = ({ data = [] }) => (
  <Box component='div'>
    <PaperWrap>
      <SampleTMRColumnChart data={data} />
    </PaperWrap>
    <PaperWrap>
      <SampleTMRPefChart data={data} />
    </PaperWrap>
    <PaperWrap>
      <SampleTMRpeNDFChart data={data} />
    </PaperWrap>
  </Box>
);

export default SampleTMRChartsContainer;
