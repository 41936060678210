import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DatePicker, Modal } from '../shared';

const ChangeDateModal = (props) => {
  const { open, onClose, handleSubmit, handleChangeAllFarms, mobileView, currentUser } = props;

  const { t } = useTranslation();

  const [pickedDate, setPickedDate] = useState(new Date());

  useEffect(() => {
    setPickedDate(new Date(currentUser?.date));
  }, [currentUser, open]);

  return (
    <Modal
      closeText={t('cancel')}
      onClose={onClose}
      onSubmit={() => {
        handleSubmit(pickedDate, currentUser);
        onClose();
      }}
      open={open}
      submitText={t('submit')}
      title={currentUser?.email}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: mobileView ? 'column' : 'row',
          marginBlock: '16px',
        }}
      >
        <DatePicker label={t('farmsAccess.validUntil')} onChange={setPickedDate} value={pickedDate} />
        {mobileView && (
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              mt: 2,
            }}
          >
            <Button onClick={() => handleChangeAllFarms(currentUser, true)}>{t('farmsAccess.selectAllFarms')}</Button>
            <Button onClick={() => handleChangeAllFarms(currentUser, false)} outlined>
              {t('farmsAccess.removeAllFarms')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ChangeDateModal;
