import {
  FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED,
  PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT,
  PARTICLE_SIZE_SINGLE_FEED_SET_LIST,
} from '../actions/actionTypes';

const initialState = {
  feedsList: [],
  currentFeeds: [],
  isLoaded: false,
};

const particleSizeSampleFeed = (state = initialState, action = {}) => {
  const { feedsList, currentFeeds } = action;

  switch (action.type) {
    case PARTICLE_SIZE_SINGLE_FEED_SET_LIST:
      return {
        ...state,
        feedsList,
        isLoaded: true,
      };
    case PARTICLE_SIZE_SINGLE_FEED_SET_CURRENT:
      return {
        ...state,
        currentFeeds: [...state.currentFeeds, currentFeeds],
      };
    case FILTER_CURRENT_PARTICLE_SIZE_SINGLE_FEED:
      return {
        ...state,
        currentFeeds,
      };
    default:
      return state;
  }
};

export default particleSizeSampleFeed;
