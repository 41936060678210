import { FILTER_CURRENT_BCS_RESULT, SET_BCS_RESULTS_LIST, SET_CURRENT_BCS_RESULT } from '../actions/actionTypes';

const initialState = {
  resultsList: [],
  pickedResults: [],
  isLoaded: false,
};

const bcsResultsReducer = (state = initialState, action = {}) => {
  const { resultsList, pickedResults } = action;

  switch (action.type) {
    case SET_BCS_RESULTS_LIST:
      return {
        ...state,
        resultsList,
        isLoaded: true,
      };
    case SET_CURRENT_BCS_RESULT:
      return {
        ...state,
        pickedResults: [...state.pickedResults, pickedResults],
      };
    case FILTER_CURRENT_BCS_RESULT:
      return {
        ...state,
        pickedResults,
      };
    default:
      return state;
  }
};

export default bcsResultsReducer;
