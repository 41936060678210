import { globalUnits, energyUnits, formatLabels } from './settings';

const nutrientLabels = {
  Inputted_Milk_Production: 'Inputted Milk Production',
  Actual_Milk_Production: 'Actual Milk Production',
  IOFC: 'IOFC',
  IOpurFC: 'IOpurFC',
  Cost: 'Cost',
  Cost_Per_ADG: 'Cost per ADG',
  PurchasedCost: 'PurchasedCost',
  perDM: 'DM (%)',
  DM: 'DM (%)',
  DMI: 'DMI',
  DMI_per_BW: 'DMI (%BW)',
  DMI_NRC: 'DMI_NRC',
  DMI_NASEM: 'DMI_NASEM',
  DMI_NASEM_Fiber: 'DMI_NASEM_Fiber',
  ForageNDF_perBW: 'ForageNDF (%BW)',
  Forage: 'Forage (%DM)',
  MEProd: 'ME Prod',
  MPProd: 'MP Prod',
  MEng: 'ME',
  MP: 'MP',
  ME_req_perDMI: 'ME req. per kg predicted DMI',
  MP_req_perDMI: 'MP req. per kg predicted DMI',
  MPSupp: 'MP Supply (g)',
  MPSupp_per_DMI: 'MP Supply (g/DMI)',
  RumenNH3: 'Rumen NH3',
  Peptides: 'Peptides',
  NFC: 'NFC',
  peNDF: 'peNDF',
  peCHO_C: 'peCHO C',
  peCHO_C_BW: 'peCHO C (%BW)',
  Lactic: 'Lactic',
  Sugar: 'Sugar',
  Starch: 'Starch',
  Sugar_plus_Starch: 'Sugar + Starch',
  SolFiber: 'Sol. Fiber',
  CHO_C: 'CHO-C',
  Ferm_CHO: 'Ferm. CHO',
  Ferm_CHO_DM: 'Ferm. CHO (%DM)',
  Ferm_Fiber_DM: 'Ferm. Fiber (%DM)',
  Ferm_Starch_DM: 'Ferm. Starch (%DM)',
  Ferm_Starch_Starch: 'Ferm. Starch (%Starch)',
  Ferm_Sugar_DM: 'Ferm. Sugar (%DM)',
  Ferm_SolFiber_DM: 'Ferm. Sol. Fiber (%DM)',
  DTC_BCS: 'DTC_BCS',
  MUN: 'MUN',
  UreaCost: 'Urea Cost',
  EE: 'EE',
  LCFA: 'LCFA',
  TotalUNSAT: 'Total UNSAT',
  LYS_perMP: 'LYS (%MP)',
  MET_perMP: 'MET (%MP)',
  LYStoMET: 'LYS:MET',
  LYS_perReq: 'LYS (%Rqd)',
  MET_perReq: 'MET (%Rqd)',
  LYS: 'LYS (g)',
  MET: 'MET (g)',
  Pred_Milk__Protein_Yield_g: 'Predicted Milk Protein (g)',
  Actual_Milk__Protein_Yield_g: 'Actual Milk Protein (g)',
  Pred_Milk__Protein_Yield_per: 'Predicted Milk Protein (%)',
  Pred_Milk_True_Protein_Yield_g: 'Predicted Milk True Protein (g)',
  Actual_Milk_True_Protein_Yield_g: 'Actual Milk True Protein (g)',
  Pred_Milk_True_Protein_Yield_per: 'Predicted Milk True Protein (%)',
  Pred_Milk_Fat_Yield_g: 'Predicted Milk Fat (g)',
  Actual_Milk_Fat_Yield_g: 'Actual Milk Fat (g)',
  Pred_Milk_Fat_Yield_per: 'Predicted Milk Fat (%)',
  Pred_Milk_Yield: 'Predicted Milk Production',
  Component_Yield: 'Component Yield (Fat+Protein)',
  Component_Yield_perBW: 'Component Yield (Fat+Protein) %BW',
  AMTS_Casein_Yield: 'AMTS Casein Yield',
  AMTS_VS_Cheddar_Yield: 'AMTS VS Cheddar Yield',
  CNCPS_Rumen_pH: 'CNCPS Rumen pH',
  AMTS_Rumen_pH: 'AMTS Rumen pH',
  AMTS_Rumen_pH_Hrs_Below_5_8: 'AMTS Rumen pH < 5.8 Hrs',
  SolP: 'SP',
  MEConc: 'ME Conc.',
  NElConc: 'NEl Conc.',
  NEgConc: 'NEg Conc.',
  NEmConc: 'NEm Conc.',
  Ash: 'Ash',
  ForageNDF_perNDF: 'ForageNDF (%NDF)',
  NDF: 'NDF (%DM)',
  ADF: 'ADF (%DM)',
  TDN: 'TDN (%)',
  CP: 'CP (%)',
  NEl_3X: 'NEl_3X',
  NEl_3X_Conc: 'NEl_3X Conc',
  Monensin: 'Monensin (mg/day)',
  Monensin_ppm: 'Monensin (ppm)',
  DCAD1: 'DCAD1 (meq/kg)',
  Ca_Rqd: 'Ca (%Rqd)',
  P_Rqd: 'P (%Rqd)',
  K_Rqd: 'K (%Rqd)',
  Mg_Rqd: 'Mg (%Rqd)',
  S_Rqd: 'S (%Rqd)',
  Na_Rqd: 'Na (%Rqd)',
  Cl_Rqd: 'Cl (%Rqd)',
  Fe_Rqd: 'Fe (%Rqd)',
  Zn_Rqd: 'Zn (%Rqd)',
  Co_Rqd: 'Co (%Rqd)',
  I_Rqd: 'I (%Rqd)',
  Mn_Rqd: 'Mn (%Rqd)',
  Se_Rqd: 'Se (%Rqd)',
  Cu_Rqd: 'Cu (%Rqd)',
  VitA_Rqd: 'Vit-A (%Rqd)',
  VitD_Rqd: 'Vit-D (%Rqd)',
  VitE_Rqd: 'Vit-E (%Rqd)',
  Ca_Rqd_NASEM: 'Ca (%Rqd) NASEM',
  P_Rqd_NASEM: 'P (%Rqd) NASEM',
  K_Rqd_NASEM: 'K (%Rqd) NASEM',
  Mg_Rqd_NASEM: 'Mg (%Rqd) NASEM',
  S_Rqd_NASEM: 'S (%Rqd) NASEM',
  Na_Rqd_NASEM: 'Na (%Rqd) NASEM',
  Cl_Rqd_NASEM: 'Cl (%Rqd) NASEM',
  Fe_Rqd_NASEM: 'Fe (%Rqd) NASEM',
  Zn_Rqd_NASEM: 'Zn (%Rqd) NASEM',
  Co_Rqd_NASEM: 'Co (%Rqd) NASEM',
  I_Rqd_NASEM: 'I (%Rqd) NASEM',
  Mn_Rqd_NASEM: 'Mn (%Rqd) NASEM',
  Se_Rqd_NASEM: 'Se (%Rqd) NASEM',
  Cu_Rqd_NASEM: 'Cu (%Rqd) NASEM',
  VitA_Rqd_NASEM: 'Vit-A (%Rqd) NASEM',
  VitD_Rqd_NASEM: 'Vit-D (%Rqd) NASEM',
  VitE_Rqd_NASEM: 'Vit-E (%Rqd) NASEM',
  Ca_DM: 'Ca (%DM)',
  P_DM: 'P (%DM)',
  K_DM: 'K (%DM)',
  Mg_DM: 'Mg (%DM)',
  S_DM: 'S (%DM)',
  Na_DM: 'Na (%DM)',
  Cl_DM: 'Cl (%DM)',
  Fe_ppm: 'Fe (ppm)',
  Zn_ppm: 'Zn (ppm)',
  Co_ppm: 'Co (ppm)',
  I_ppm: 'I (ppm)',
  Mn_ppm: 'Mn (ppm)',
  Se_ppm: 'Se (ppm)',
  Cu_ppm: 'Cu (ppm)',
  VitA_KIU_kg: 'Vit-A',
  VitD_KIU_kg: 'Vit-D',
  VitE_IU_kg: 'Vit-E',
  Ca: 'Ca (g)',
  P: 'P (g)',
  K: 'K (g)',
  Mg: 'Mg (g)',
  S: 'S (g)',
  Na: 'Na (g)',
  Cl: 'Cl (g)',
  Fe: 'Fe (mg)',
  Zn: 'Zn (mg)',
  Co: 'Co (mg)',
  I: 'I (mg)',
  Mn: 'Mn (mg)',
  Se: 'Se (mg)',
  Cu: 'Cu (mg)',
  VitA: 'Vit-A (KIU)',
  VitD: 'Vit-D (KIU)',
  VitE: 'Vit-E (IU)',
  Biotin: 'Biotin (ppm)',
  Niacin: 'Niacin (%DM)',
  Niacin_ppm: 'Niacin (ppm)',
  Chromium: 'Chromium (ppm)',
  Choline: 'Choline (ppm)',
  Lasalocid: 'Lasalocid (mg/day)',
  Lasalocid_ppm: 'Lasalocid (ppm)',
  Chlortetracycline: 'Chlortetracycline (mg/day)',
  Chlortetracycline_ppm: 'Chlortetracycline (ppm)',
  Salt: 'Salt (%DM)',
  DCAD2: 'DCAD2 (meq/kg)',
  Org_Zn: 'Org_Zn (%Zn)',
  Org_Se: 'Org_Se (%Se)',
  Org_Co: 'Org_Co (%Co)',
  Org_Mn: 'Org_Mn (%Mn)',
  Org_Cu: 'Org_Cu (%Cu)',
  Org_Chromium: 'Org_Chromium (%Chromium)',
  ARG_perMP: 'ARG (%MP)',
  THR_perMP: 'THR (%MP)',
  LEU_perMP: 'LEU (%MP)',
  ILE_perMP: 'ILE (%MP)',
  VAL_perMP: 'VAL (%MP)',
  HIS_perMP: 'HIS (%MP)',
  PHE_perMP: 'PHE (%MP)',
  TRP_perMP: 'TRP (%MP)',
  ARG_perReq: 'ARG (%Rqd)',
  THR_perReq: 'THR (%Rqd)',
  LEU_perReq: 'LEU (%Rqd)',
  ILE_perReq: 'ILE (%Rqd)',
  VAL_perReq: 'VAL (%Rqd)',
  HIS_perReq: 'HIS (%Rqd)',
  PHE_perReq: 'PHE (%Rqd)',
  TRP_perReq: 'TRP (%Rqd)',
  ARG: 'ARG (g)',
  THR: 'THR (g)',
  LEU: 'LEU (g)',
  ILE: 'ILE (g)',
  Val: 'VAL (g)',
  VAL: 'VAL (g)',
  HIS: 'HIS (g)',
  PHE: 'PHE (g)',
  TRP: 'TRP (g)',
  MilkFeverRisk: 'Milk Fever Risk (%)',
  DrinkingWaterIntake: 'Drinking Water Intake',
  TotalWaterIntake: 'Total Water Intake',
  Total_Manure_N: 'Total Manure N (g)',
  Total_Manure_P: 'Total Manure P (g)',
  Ca_Added: 'Ca Added (g)',
  P_Added: 'P Added (g)',
  K_Added: 'K Added (g)',
  Mg_Added: 'Mg Added (g)',
  S_Added: 'S Added (g)',
  Na_Added: 'Na Added (g)',
  Cl_Added: 'Cl Added (g)',
  Fe_Added: 'Fe Added (mg)',
  Zn_Added: 'Zn Added (mg)',
  Co_Added: 'Co Added (mg)',
  I_Added: 'I Added (mg)',
  Mn_Added: 'Mn Added (mg)',
  Se_Added: 'Se Added (mg)',
  Cu_Added: 'Cu Added (mg)',
  VitA_Added: 'Vit-A Added (KIU)',
  VitD_Added: 'Vit-D Added (KIU)',
  VitE_Added: 'Vit-E Added (IU)',
  Inputted_RUP: 'Inputted RUP (g)',
  Inputted_RDP: 'Inputted RDP (g)',
  Inputted_RUP_CP: 'Inputted RUP (%CP)',
  Inputted_RUP_DM: 'Inputted RUP (%DM)',
  Inputted_RDP_CP: 'Inputted RDP (%CP)',
  Inputted_RDP_DM: 'Inputted RDP (%DM)',
  Urea: 'Urea (g)',
  Vegetable_Fat: 'Vegetable Fat (g)',
  Animal_Protein: 'Animal Protein (g)',
  Inputted_NEg: 'Inputted NEg (Mcal)',
  Inputted_NEg_Conc: 'Inputted NEg (Mcal/kg)',
  Inputted_NEm: 'Inputted NEm (Mcal)',
  Inputted_NEm_perKg: 'Inputted NEm (Mcal/Kg)',
  Inputted_NEl: 'Inputted NEl (Mcal)',
  Inputted_NEl_perKg: 'Inputted NEl (Mcal/Kg)',
  Inputted_TDN: 'Inputted TDN (g)',
  Inputted_TDN_perDM: 'Inputted TDN (%DM)',
  Organic_Fe: 'Organic Fe (mg)',
  Carotene: 'Carotene (mg)',
  Beta_Carotene: 'Beta Carotene (mg)',
  Thiamine: 'Thiamine (mg)',
  Amprolium: 'Amprolium (mg)',
  Bambermycin: 'Bambermycin (mg)',
  Fenbendazole: 'Fenbendazole (mg)',
  MGA: 'MGA (mg)',
  Neomycin_Base: 'Neomycin Base (mg)',
  Rabon: 'Rabon (mg)',
  Rumatel: 'Rumatel (mg)',
  Tylosin: 'Tylosin (mg)',
  Tilmicosin: 'Tilmicosin (mg)',
  EDDI: 'EDDI (mg)',
  Altosid: 'Altosid (mg)',
  Clarifly: 'Clarifly (mg)',
  Zilpaterol: 'Zilpaterol (mg)',
  Natural_Vit_E: 'Natural Vit E (IU)',
  Gosypol: 'Gosypol (mg)',
  DHA: 'DHA (g)',
  EPA: 'EPA (g)',
  DHA_EPA_Ratio: 'DHA:EPA Ratio',
  DPA: 'DPA (g)',
  Molybdenum: 'Molybdenum (mg)',
  Aflatoxin_B1: 'Aflatoxin B1 (mg)',
  Aflatoxin_B2: 'Aflatoxin B2 (mg)',
  Aflatoxin_G1: 'Aflatoxin G1 (mg)',
  Aflatoxin_G2: 'Aflatoxin G2 (mg)',
  Vomitoxin: 'Vomitoxin (mg)',
  DON_3_Acetyl: 'DON 3-Acetyl (mg)',
  DON_15_Acetyl: 'DON 15-Acetyl (mg)',
  Zearalenone: 'Zearalenone (mg)',
  Ochratoxin: 'Ochratoxin (mg)',
  T2: 'T2 (mg)',
  Fumonisin_B1: 'Fumonisin B1 (mg)',
  Fumonisin_B2: 'Fumonisin B2 (mg)',
  Fumonisin_B3: 'Fumonisin B3 (mg)',
  Mold: 'Mold (CFU)',
  Ergot: 'Ergot (mg)',
  Slaframine: 'Slaframine (mg)',
  Patulin: 'Patulin (mg)',
  Citrinin: 'Citrinin (CFU)',
  P_Avail: 'Inputted P Avail (g)',
  uNDF: 'uNDF (g)',
  C12_0_Intake: 'C12:0 Intake (g)',
  C14_0_Intake: 'C14:0 Intake (g)',
  C16_0_Intake: 'C16:0 Intake (g)',
  C16_1_Intake: 'C16:1 Intake (g)',
  C18_0_Intake: 'C18:0 Intake (g)',
  C18_1_Trans_Intake: 'C18:1 Trans Intake (g)',
  C18_1_Cis_Intake: 'C18:1 Cis Intake (g)',
  C18_2_Intake: 'C18:2 Intake (g)',
  C18_3_Intake: 'C18:3 Intake (g)',
  C18_4_Intake: 'C18:4 Intake (g)',
  C16_to_C18_Intake: 'C16:0 to C18:1 (c+t) Intake',
  OtherLipid_Intake: 'Other Lipid Intake (g)',
  IntTFA_Intake: 'IntTFA Intake (g)',
  LCFA_Intake: 'LCFA Intake (g)',
  RUFAL_Intake: 'RUFAL Intake (g)',
  Urea_kg: 'Urea (kg)',
  RUP: 'RUP (g)',
  RDP: 'RDP (g)',
  RUP_CP: 'RUP (%CP)',
  RUP_DM: 'RUP (%DM)',
  RDP_CP: 'RDP (%CP)',
  RDP_DM: 'RDP (%DM)',
  Forage_NDF_DM: 'Forage NDF (%DM)',
  Lignin_DM: 'Lignin (%DM)',
  uNDF_DM: 'uNDF (%DM)',
  Ca_Added_perDM: 'Ca Added (%DM)',
  P_Added_perDM: 'P Added (%DM)',
  K_Added_perDM: 'K Added (%DM)',
  Mg_Added_perDM: 'Mg Added (%DM)',
  S_Added_perDM: 'S Added (%DM)',
  Na_Added_perDM: 'Na Added (%DM)',
  Cl_Added_perDM: 'Cl Added (%DM)',
  Fe_Added_ppm: 'Fe Added (ppm)',
  Zn_Added_ppm: 'Zn Added (ppm)',
  Co_Added_ppm: 'Co Added (ppm)',
  I_Added_ppm: 'I Added (ppm)',
  Mn_Added_ppm: 'Mn Added (ppm)',
  Se_Added_ppm: 'Se Added (ppm)',
  Cu_Added_ppm: 'Cu Added (ppm)',
  VitA_Added_KIU_kg: 'Vit-A Added (KIU/kg)',
  VitD_Added_KIU_kg: 'Vit-D Added (KIU/kg)',
  VitE_Added_IU_kg: 'Vit-E Added (IU/kg)',
  Milk_Urea_mg_dl: 'Milk Urea (mg/dl)',
  Total_NDF_perBW: 'Total NDF (%BW)',
  MET_to_ME: 'MET:ME',
  LYS_to_ME: 'LYS:ME',
  ARG_to_ME: 'ARG:ME',
  THR_to_ME: 'THR:ME',
  LEU_to_ME: 'LEU:ME',
  ILE_to_ME: 'ILE:ME',
  VAL_to_ME: 'VAL:ME',
  HIS_to_ME: 'HIS:ME',
  PHE_to_ME: 'PHE:ME',
  TRP_to_ME: 'TRP:ME',
  EAA_to_ME: 'EAA:ME',
  Milk_Production_perBW: 'Milk Production (%BW)',
  ECM: 'ECM',
  ECM_perBW: 'ECM (%BW)',
  Feed_Efficiency: 'Feed Efficiency',
  Concentrate_Intake_perBW: 'Concentrate Intake (%BW)',
  EAA: 'EAA (g)',
  Total_EAA_Rqd: 'Total EAA Req. (g)',
  CH4: 'CH4 (Mcal)',
  Butyric: 'Butyric',
  Butyric_g: 'Butyric (g)',
  Butyric_perDM: 'Butyric (%DM)',
  Acetic_perDM: 'Acetic (%DM)',
  Propionic_perDM: 'Propionic (%DM)',
  uNDF_per_BW: 'uNDF (%BW)',
  CHO_C_per_DM: 'CHO-C (%DM)',
  dNDF_HR: 'dNDF-HR (%NDF)',
  dNDF_1HR: 'dNDF-1HR (%NDF)',
  dNDF_12HR: 'dNDF-12HR (%NDF)',
  dNDF_30HR: 'dNDF-40HR (%NDF)',
  dNDF_72HR: 'dNDF-72HR (%NDF)',
  dNDF_120HR: 'dNDF-120HR (%NDF)',
  dNDF_240HR: 'dNDF-240HR (%NDF)',
  uNDF_HR_perBW: 'uNDF-HR (%BW)',
  uNDF_1HR_perBW: 'uNDF-1HR (%BW)',
  uNDF_12HR_perBW: 'uNDF-12HR (%BW)',
  uNDF_30HR_perBW: 'uNDF-30HR (%BW)',
  uNDF_72HR_perBW: 'uNDF-72HR (%BW)',
  uNDF_120HR_perBW: 'uNDF-120HR (%BW)',
  uNDF_240HR_perBW: 'uNDF-240HR (%BW)',
  Molybdenum_ppm: 'Molybdenum (ppm)',
  Allowable_Milk_perBW: 'Allowable Milk (%BW)',
  aNDFom_kg: 'aNDFom',
  TFA_perDM: 'TFA (%DM)',
  Forage_uNDF_HR_kg: 'Forage uNDF-HR',
  Forage_uNDF_30HR_kg: 'Forage uNDF-30HR',
  Forage_dNDF_30HR_kg: 'Forage dNDF-30HR',
  Forage_kg: 'Forage',
  Microbial_MP_perMP: 'Microbial MP (%MP)',
  Forage_dNDF_HR_kg: 'Forage dNDF-HR',
  ME_Milk_to_Feed: 'ME Milk:Feed',
  MP_Milk_to_Feed: 'MP Milk:Feed',
  ME_ADG_plus_Preg: 'ME ADG + Preg',
  MP_ADG_plus_Preg: 'MP ADG + Preg',
  Milk_to_Cover_Costs: 'Milk to Cover Feed Costs (kg)',
  Methane: 'Methane (L)',
  Methane_perMilk: 'Methane per Allowable Milk (L/kg)',
  Methane_perDMI: 'Methane per DMI (L/kg)',
};

const nutrientOrder = [
  'Source',
  'SourceID',
  'Mill_ID',
  'Price',
  'Purchased',
  'Density',
  'WorkingPricePer100',
  'WorkingPriceAF',
  'WorkingPriceDM',
  'Lab',
  'LabSampleID',
  'LabSubmissionDate',
  'DM',
  'Forage',
  'CP',
  'SP',
  'NPN',
  'NPN_CP',
  'ADIP',
  'NDIP',
  'IUN',
  'Acetic',
  'Propionic',
  'Butyric',
  'Lactic',
  'OtherOas',
  'Sugar',
  'Starch',
  'SolFiber',
  'ADF',
  'NDF',
  'CHO_B1_kd',
  'CHO_B3_kd',
  'uNDF',
  'dStarch2',
  'dStarch7',
  'pef',
  'Lignin',
  'Lignin_DM',
  'Ash',
  'EE',
  'TFA_EE',
  'MassCheck',
  'Glycerol',
  'C12_0',
  'C14_0',
  'C16_0',
  'C16_1',
  'C18_0',
  'C18_1_Trans',
  'C18_1_Cis',
  'C18_2',
  'C18_3',
  'C18_4',
  'DHA',
  'EPA',
  'DPA',
  'OtherLipid',
  'Ca',
  'P',
  'Mg',
  'K',
  'S',
  'Na',
  'Cl',
  'P_Avail',
  'Fe',
  'Zn',
  'Cu',
  'Mn',
  'Se',
  'Co',
  'I',
  'Choline',
  'Chromium',
  'Org_Chromium',
  'Org_Zinc',
  'Org_Copper',
  'Org_Manganese',
  'Org_Selenium',
  'Org_Cobalt',
  'Organic_Fe',
  'Molybdenum',
  'VitA',
  'VitD',
  'VitE',
  'Niacin',
  'Biotin',
  'Menadione',
  'Vit_C',
  'Riboflavin',
  'PantothenicAcid',
  'Pyridoxine',
  'FolicAcid',
  'Vit_B12',
  'Carotene',
  'Beta_Carotene',
  'Thiamine',
  'Met',
  'Lys',
  'Arg',
  'Thr',
  'Leu',
  'Ile',
  'Val',
  'His',
  'Phe',
  'Trp',
  'CHO_A1_kd',
  'CHO_A2_kd',
  'CHO_A3_kd',
  'CHO_A4_kd',
  'CHO_B2_kd',
  'CHO_C_kd',
  'Prt_A1_kd',
  'Prt_A2_kd',
  'Prt_B1_kd',
  'Prt_B2_kd',
  'Prt_C_kd',
  'CHO_A1_ID',
  'CHO_A2_ID',
  'CHO_A3_ID',
  'CHO_A4_ID',
  'CHO_B1_ID',
  'CHO_B2_ID',
  'CHO_B3_ID',
  'CHO_C_ID',
  'Prot_A_ID',
  'Prot_B1_ID',
  'Prot_B2_ID',
  'Prot_B3_ID',
  'Prot_C_ID',
  'Fat_ID',
  'C12_0_ID',
  'C14_0_ID',
  'C16_0_ID',
  'C16_1_ID',
  'C18_0_ID',
  'C18_1_Trans_ID',
  'C18_1_Cis_ID',
  'C18_2_ID',
  'C18_3_ID',
  'C18_4_ID',
  'DHA_ID',
  'EPA_ID',
  'DPA_ID',
  'OtherLipid_ID',
  'Monensin',
  'Monensin_ppm',
  'Lasalocid',
  'Decoquinate',
  'Yeast',
  'Beta Agonist',
  'Virginiamycin',
  'Aureomycin',
  'Chlortetracycline',
  'Oxytetracycline',
  'Salinomycin',
  'Zinc Bacitracin',
  'Enzymes',
  'Toxin Binders',
  'Flavor',
  'Amprolium',
  'Bambermycin',
  'Fenbendazole',
  'MGA',
  'Neomycin_Base',
  'Rabon',
  'Rumatel',
  'Tylosin',
  'Tilmicosin',
  'Altosid',
  'Clarifly',
  'Zilpaterol',
  'BMD',
  'Sulfamethazine',
  'Laidlomycin',
  'Poloxalene',
  'Ractopamine',
  'Ca_BioAvail',
  'P_BioAvail',
  'Mg_BioAvail',
  'K_BioAvail',
  'S_BioAvail',
  'Na_BioAvail',
  'Cl_BioAvail',
  'Fe_BioAvail',
  'Zn_BioAvail',
  'Cu_BioAvail',
  'Mn_BioAvail',
  'Se_BioAvail',
  'Co_BioAvail',
  'I_BioAvail',
  'VitA_BioAvail',
  'VitD_BioAvail',
  'VitE_BioAvail',
  'Ca_Rqd_NASEM',
  'P_Rqd_NASEM',
  'K_Rqd_NASEM',
  'Mg_Rqd_NASEM',
  'S_Rqd_NASEM',
  'Na_Rqd_NASEM',
  'Cl_Rqd_NASEM',
  'Fe_Rqd_NASEM',
  'Zn_Rqd_NASEM',
  'Co_Rqd_NASEM',
  'I_Rqd_NASEM',
  'Mn_Rqd_NASEM',
  'Se_Rqd_NASEM',
  'Cu_Rqd_NASEM',
  'VitA_Rqd_NASEM',
  'VitD_Rqd_NASEM',
  'VitE_Rqd_NASEM',
  'NEl_3X',
  'RUP',
  'RUP_CP',
  'RDP',
  'RDP_CP',
  'Inputted_NEg',
  'Inputted_NEm',
  'Inputted_TDN',
  'TSI',
  'Aflatoxin_B1',
  'Aflatoxin_B2',
  'Aflatoxin_G1',
  'Aflatoxin_G2',
  'Vomitoxin',
  'DON_3_Acetyl',
  'DON_15_Acetyl',
  'Zearalenone',
  'Ochratoxin',
  'T2',
  'Fumonisin_B1',
  'Fumonisin_B2',
  'Fumonisin_B3',
  'Mold',
  'Ergot',
  'Slaframine',
  'Patulin',
  'Citrinin',
  'dNDF_12HR',
  'dNDF_30HR',
  'dNDF_72HR',
  'dNDF_120HR',
  'dNDF_240HR',
  'Alt_ID_1',
  'Alt_ID_2',
  'Alt_ID_3',
  'Alt_ID_4',
  'TAG_ID',
  'LDC_Opt_Min',
  'LDC_Opt_Max',
  'LBC_Opt_Min',
  'LBC_Opt_Max',
  'DC_Opt_Min',
  'DC_Opt_Max',
  'RH_Opt_Min',
  'RH_Opt_Max',
  'GF_Opt_Min',
  'GF_Opt_Max',
  'DP_Opt_Min',
  'DP_Opt_Max',
  'LDC_DefaultAmt',
  'LBC_DefaultAmt',
  'DC_DefaultAmt',
  'RH_DefaultAmt',
  'GF_DefaultAmt',
  'DP_DefaultAmt',
  'Urea',
  'CP_NPN',
  'Vegetable_Fat',
  'Animal_Protein',
  'Salt',
  'Gosypol',
  'EDDI',
  'LipolysisRate',
  'AdjFactor',
];

const getRationOutputLabel = (outputName, weightUnit, energyUnit, animalType) => {
  switch (outputName) {
    case 'Inputted Milk Production':
      return weightUnit === globalUnits.METRIC
        ? 'Inputted Milk Production' + ' ' + '(kg/day)'
        : 'Inputted Milk Production' + ' ' + '(lbs/day)';
    case 'Actual Milk Production':
      return weightUnit === globalUnits.METRIC
        ? 'Actual Milk Production' + ' ' + '(kg/day)'
        : 'Actual Milk Production' + ' ' + '(lbs/day)';
    case 'IOFC':
      return 'IOFC';
    case 'IOpurFC':
      return 'IOpurFC';
    case 'Cost':
      return 'Cost/hd';
    case 'Cost per ADG':
      return weightUnit === globalUnits.METRIC ? 'Cost/kg Inputted ADG' : 'Cost/lb Inputted ADG';
    case 'PurchasedCost':
      return 'Purchased Cost/hd';
    case 'DM (%)':
      return 'DM (%)';
    case 'DM (kg)':
      return weightUnit === globalUnits.METRIC ? 'DM (kg)' : 'DM (lbs)';
    case 'As Fed (kg)':
      return weightUnit === globalUnits.METRIC ? 'As Fed (kg)' : 'As Fed (lbs)';
    case 'DMI':
      return weightUnit === globalUnits.METRIC ? 'Dry Matter Intake (kg/day)' : 'Dry Matter Intake (lbs/day)';
    case 'DMI (%BW)':
      return 'DMI' + ' ' + '(%BW)';
    case 'DMI_NRC':
      return weightUnit === globalUnits.METRIC ? 'DMI (NRC Predicted) (kg/day)' : 'DMI (NRC Predicted) (lbs/day)';
    case 'DMI_NASEM':
      return weightUnit === globalUnits.METRIC ? 'DMI (NASEM Predicted) (kg/day)' : 'DMI (NASEM Predicted) (lbs/day)';
    case 'DMI_NASEM_Fiber':
      return weightUnit === globalUnits.METRIC
        ? 'DMI (NASEM Fiber Predicted) (kg/day)'
        : 'DMI (NASEM Fiber Predicted) (lbs/day)';
    case 'ForageNDF (%BW)':
      return 'Forage NDF (%BW)';
    case 'Forage (%DM)':
      return 'Forage (%DM)';
    case 'ME Prod':
      if (animalType?.includes('Lactating')) {
        return weightUnit === globalUnits.METRIC ? 'ME Allowable Milk (kg/day)' : 'ME Allowable Milk (lbs/day)';
      } else if (!!animalType) {
        return weightUnit === globalUnits.METRIC ? 'ME Allowable Gain (kg/day)' : 'ME Allowable Gain (lbs/day)';
      }
      return weightUnit === globalUnits.METRIC ? 'ME Allowable Milk/Gain (kg/day)' : 'ME Allowable Milk/Gain (lbs/day)';
    case 'MP Prod':
      if (animalType?.includes('Lactating')) {
        return weightUnit === globalUnits.METRIC ? 'MP Allowable Milk (kg/day)' : 'MP Allowable Milk (lbs/day)';
      } else if (!!animalType) {
        return weightUnit === globalUnits.METRIC ? 'MP Allowable Gain (kg/day)' : 'MP Allowable Gain (lbs/day)';
      }
      return weightUnit === globalUnits.METRIC ? 'MP Allowable Milk/Gain (kg/day)' : 'MP Allowable Milk/Gain (lbs/day)';
    case 'ME':
      return 'ME' + ' ' + '(%Rqd)';
    case 'MP':
      return 'MP' + ' ' + '(%Rqd)';
    case 'ME req. per kg predicted DMI':
      if (weightUnit === energyUnits.MCAL) {
        if (weightUnit === globalUnits.METRIC) {
          return 'ME req. per predicted DMI' + ' (' + 'Mcal/kg' + ')';
        } else {
          return 'ME req. per predicted DMI' + ' (' + 'Mcal/lb' + ')';
        }
      } else {
        if (weightUnit === globalUnits.METRIC) {
          return 'ME req. per predicted DMI' + ' (' + 'MJ/kg' + ')';
        } else {
          return 'ME req. per predicted DMI' + ' (' + 'MJ/lb' + ')';
        }
      }
    case 'MP req. per kg predicted DMI':
      return weightUnit === globalUnits.METRIC
        ? 'MP req. per predicted DMI' + ' (' + 'g/kg' + ')'
        : 'MP req. per predicted DMI' + ' (' + 'g/lb' + ')';
    case 'MP Supply (g)':
      return 'MP Supply (g)';
    case 'MP Supply (g/DMI)':
      return weightUnit === globalUnits.METRIC ? 'MP supply (g/kg DMI)' : 'MP supply (g/lb DMI)';
    case 'Rumen NH3':
      return 'Rumen NH3 (%Rqd)';
    case 'Peptides':
      return 'Peptides (%Rqd)';
    case 'NFC':
      return 'NFC (%DM)';
    case 'peNDF':
      return 'peNDF (%DM)';
    case 'peCHO C':
      return 'peCHO C' + ' (' + '%DM' + ')';
    case 'peCHO C (%BW)':
      return 'peCHO C' + ' (' + '%BW' + ')';
    case 'Lactic':
      return 'Lactic (%DM)';
    case 'Sugar':
      return 'Sugar (%DM)';
    case 'Starch':
      return 'Starch (%DM)';
    case 'Sugar + Starch':
      return 'Sugar' + ' + ' + 'Starch' + ' ' + '(%DM)';
    case 'Sol. Fiber':
      return 'Soluble Fiber (%DM)';
    case 'CHO-C':
      return 'CHO-C (g)';
    case 'Ferm. CHO':
      return 'Fermentable CHO (%CHO)';
    case 'Ferm. CHO (%DM)':
      return 'Fermentable CHO (%DM)';
    case 'Ferm. Fiber (%DM)':
      return 'Ferm. Fiber (%DM)';
    case 'Ferm. Starch (%DM)':
      return 'Ferm. Starch (%DM)';
    case 'Ferm. Starch (%Starch)':
      return 'Ferm. Starch (%Starch)';
    case 'Ferm. Sugar (%DM)':
      return 'Ferm. Sugar (%DM)';
    case 'Ferm. Sol. Fiber (%DM)':
      return 'Ferm. Sol. Fiber (%DM)';
    case 'DTC_BCS':
      return 'Days To Change BCS';
    case 'MUN':
      return 'MUN (mg/dl)';
    case 'Urea Cost':
      return 'Urea Cost' + ' (' + energyUnit + ')';
    case 'EE':
      return 'EE (%DM)';
    case 'LCFA':
      return 'LCFA (%DM)';
    case 'Total UNSAT':
      return 'Total Unsaturate (%DM)';
    case 'LYS (%MP)':
      return 'LYS (%MP)';
    case 'MET (%MP)':
      return 'MET (%MP)';
    case 'LYS:MET':
      return 'LYS:MET';
    case 'LYS (%Rqd)':
      return 'LYS (%Rqd)';
    case 'MET (%Rqd)':
      return 'MET (%Rqd)';
    case 'LYS (g)':
      return 'LYS (g)';
    case 'MET (g)':
      return 'MET (g)';
    case 'Predicted Milk True Protein (g)':
      return 'Predicted Milk True Protein (g)';
    case 'Actual Milk True Protein (g)':
      return 'Actual Milk True Protein (g)';
    case 'Predicted Milk True Protein (%)':
      return 'Predicted Milk True Protein (%)';
    case 'Predicted Milk Fat (g)':
      return 'Predicted Milk Fat (g)';
    case 'Actual Milk Fat (g)':
      return 'Actual Milk Fat (g)';
    case 'Predicted Milk Fat (%)':
      return 'Predicted Milk Fat (%)';
    case 'Predicted Milk Production':
      return weightUnit === globalUnits.METRIC
        ? 'Predicted Milk Production' + ' ' + '(kg/day)'
        : 'Predicted Milk Production' + ' ' + '(lbs/day)';
    case 'Component Yield (Fat+Protein)':
      return weightUnit === globalUnits.METRIC
        ? 'Component Yield (Fat+Protein)' + ' ' + '(kg/day)'
        : 'Component Yield (Fat+Protein)' + ' ' + '(lbs/day)';
    case 'Component Yield (Fat+Protein) %BW':
      return weightUnit === globalUnits.METRIC
        ? 'Component Yield (Fat+Protein)' + ' ' + '(%BW)'
        : 'Component Yield (Fat+Protein)' + ' ' + '(%BW)';
    case 'AMTS Casein Yield':
      return 'AMTS Casein Yield (g)';
    case 'AMTS VS Cheddar Yield':
      return weightUnit === globalUnits.METRIC
        ? 'AMTS VS Cheddar Yield' + ' ' + '(kg Cheddar/100 kg Milk)'
        : 'AMTS VS Cheddar Yield' + ' ' + '(lbs Cheddar/100 lbs Milk)';
    case 'CNCPS Rumen pH':
      return 'CNCPS Rumen pH';
    case 'AMTS Rumen pH':
      return 'AMTS Rumen pH';
    case 'AMTS Rumen pH < 5.8 Hrs':
      return 'AMTS Rumen pH < 5.8 Hrs';
    case 'RDP (%DM)':
      return 'RDP (%DM)';
    case 'SP':
      return 'SP (%CP)';
    case 'ME Conc.':
      if (weightUnit === energyUnits.MCAL) {
        return weightUnit === globalUnits.METRIC ? 'ME (Mcal/kg)' : 'ME (Mcal/lb)';
      } else {
        return weightUnit === globalUnits.METRIC ? 'ME (MJ/kg)' : 'ME (MJ/lb)';
      }
    case 'NEl Conc.':
      if (weightUnit === energyUnits.MCAL) {
        return weightUnit === globalUnits.METRIC ? 'NEl (Mcal/kg)' : 'NEl (Mcal/lb)';
      } else {
        return weightUnit === globalUnits.METRIC ? 'NEl (MJ/kg)' : 'NEl (MJ/lb)';
      }
    case 'NEg Conc.':
      if (weightUnit === energyUnits.MCAL) {
        return weightUnit === globalUnits.METRIC ? 'NEg (Mcal/kg)' : 'NEg (Mcal/lb)';
      } else {
        return weightUnit === globalUnits.METRIC ? 'NEg (MJ/kg)' : 'NEg (MJ/lb)';
      }
    case 'NEm Conc.':
      if (weightUnit === energyUnits.MCAL) {
        return weightUnit === globalUnits.METRIC ? 'NEm (Mcal/kg)' : 'NEm (Mcal/lb)';
      } else {
        return weightUnit === globalUnits.METRIC ? 'NEm (MJ/kg)' : 'NEm (MJ/lb)';
      }
    case 'Ash':
      return 'Ash (%DM)';
    case 'ForageNDF (%NDF)':
      return 'Forage NDF (%NDF)';
    case 'NDF (%DM)':
      return 'aNDFom' + ' ' + '(%DM)';
    case 'ADF (%DM)':
      return 'ADF (%DM)';
    case 'TDN (%)':
      return 'TDN (%)';
    case 'Inputted TDN (%DM)':
      return 'Inputted TDN (%DM)';
    case 'CP (%)':
      return 'CP (%)';
    case 'NEl_3X':
      return weightUnit === energyUnits.MCAL ? 'NEl-3X (Mcal)' : 'NEl-3X (MJ)';
    case 'NEl_3X Conc':
      if (weightUnit === energyUnits.MCAL) {
        return weightUnit === globalUnits.METRIC ? 'NEl-3X (Mcal/kg)' : 'NEl-3X (Mcal/lb)';
      } else {
        return weightUnit === globalUnits.METRIC ? 'NEl-3X (MJ/kg)' : 'NEl-3X (MJ/lb)';
      }
    case 'Monensin (ppm)':
      return 'Monensin (ppm)';
    case 'DCAD1 (meq/kg)':
      return 'DCAD1 (meq/kg)';
    case 'Ca (%Rqd)':
      return 'Ca (%Rqd)';
    case 'P (%Rqd)':
      return 'P (%Rqd)';
    case 'K (%Rqd)':
      return 'K (%Rqd)';
    case 'Mg (%Rqd)':
      return 'Mg (%Rqd)';
    case 'S (%Rqd)':
      return 'S (%Rqd)';
    case 'Na (%Rqd)':
      return 'Na (%Rqd)';
    case 'Cl (%Rqd)':
      return 'Cl (%Rqd)';
    case 'Fe (%Rqd)':
      return 'Fe (%Rqd)';
    case 'Zn (%Rqd)':
      return 'Zn (%Rqd)';
    case 'Co (%Rqd)':
      return 'Co (%Rqd)';
    case 'I (%Rqd)':
      return 'I (%Rqd)';
    case 'Mn (%Rqd)':
      return 'Mn (%Rqd)';
    case 'Se (%Rqd)':
      return 'Se (%Rqd)';
    case 'Cu (%Rqd)':
      return 'Cu (%Rqd)';
    case 'Vit-A (%Rqd)':
      return 'Vit-A (%Rqd)';
    case 'Vit-D (%Rqd)':
      return 'Vit-D (%Rqd)';
    case 'Vit-E (%Rqd)':
      return 'Vit-E (%Rqd)';
    case 'Ca (%Rqd) NASEM':
      return 'Ca (%Rqd)' + ' ' + 'NASEM';
    case 'P (%Rqd) NASEM':
      return 'P (%Rqd)' + ' ' + 'NASEM';
    case 'K (%Rqd) NASEM':
      return 'K (%Rqd)' + ' ' + 'NASEM';
    case 'Mg (%Rqd) NASEM':
      return 'Mg (%Rqd)' + ' ' + 'NASEM';
    case 'S (%Rqd) NASEM':
      return 'S (%Rqd)' + ' ' + 'NASEM';
    case 'Na (%Rqd) NASEM':
      return 'Na (%Rqd)' + ' ' + 'NASEM';
    case 'Cl (%Rqd) NASEM':
      return 'Cl (%Rqd)' + ' ' + 'NASEM';
    case 'Fe (%Rqd) NASEM':
      return 'Fe (%Rqd)' + ' ' + 'NASEM';
    case 'Zn (%Rqd) NASEM':
      return 'Zn (%Rqd)' + ' ' + 'NASEM';
    case 'Co (%Rqd) NASEM':
      return 'Co (%Rqd)' + ' ' + 'NASEM';
    case 'I (%Rqd) NASEM':
      return 'I (%Rqd)' + ' ' + 'NASEM';
    case 'Mn (%Rqd) NASEM':
      return 'Mn (%Rqd)' + ' ' + 'NASEM';
    case 'Se (%Rqd) NASEM':
      return 'Se (%Rqd)' + ' ' + 'NASEM';
    case 'Cu (%Rqd) NASEM':
      return 'Cu (%Rqd)' + ' ' + 'NASEM';
    case 'Vit-A (%Rqd) NASEM':
      return 'Vit-A (%Rqd)' + ' ' + 'NASEM';
    case 'Vit-D (%Rqd) NASEM':
      return 'Vit-D (%Rqd)' + ' ' + 'NASEM';
    case 'Vit-E (%Rqd) NASEM':
      return 'Vit-E (%Rqd)' + ' ' + 'NASEM';
    case 'Ca (%DM)':
      return 'Ca (%DM)';
    case 'P (%DM)':
      return 'P (%DM)';
    case 'K (%DM)':
      return 'K (%DM)';
    case 'Mg (%DM)':
      return 'Mg (%DM)';
    case 'S (%DM)':
      return 'S (%DM)';
    case 'Na (%DM)':
      return 'Na (%DM)';
    case 'Cl (%DM)':
      return 'Cl (%DM)';
    case 'Fe (ppm)':
      return 'Fe (ppm)';
    case 'Zn (ppm)':
      return 'Zn (ppm)';
    case 'Co (ppm)':
      return 'Co (ppm)';
    case 'I (ppm)':
      return 'I (ppm)';
    case 'Mn (ppm)':
      return 'Mn (ppm)';
    case 'Se (ppm)':
      return 'Se (ppm)';
    case 'Cu (ppm)':
      return 'Cu (ppm)';
    case 'Vit-A':
      return weightUnit === globalUnits.METRIC ? 'Vit-A (KIU/kg)' : 'Vit-A (KIU/lb)';
    case 'Vit-D':
      return weightUnit === globalUnits.METRIC ? 'Vit-D (KIU/kg)' : 'Vit-D (KIU/lb)';
    case 'Vit-E':
      return weightUnit === globalUnits.METRIC ? 'Vit-E (IU/kg)' : 'Vit-E (IU/lb)';
    case 'Ca (g)':
      return 'Ca (g)';
    case 'P (g)':
      return 'P (g)';
    case 'K (g)':
      return 'K (g)';
    case 'Mg (g)':
      return 'Mg (g)';
    case 'S (g)':
      return 'S (g)';
    case 'Na (g)':
      return 'Na (g)';
    case 'Cl (g)':
      return 'Cl (g)';
    case 'Fe (mg)':
      return 'Fe (mg)';
    case 'Zn (mg)':
      return 'Zn (mg)';
    case 'Co (mg)':
      return 'Co (mg)';
    case 'I (mg)':
      return 'I (mg)';
    case 'Mn (mg)':
      return 'Mn (mg)';
    case 'Se (mg)':
      return 'Se (mg)';
    case 'Cu (mg)':
      return 'Cu (mg)';
    case 'Vit-A (KIU)':
      return 'Vit-A (KIU)';
    case 'Vit-D (KIU)':
      return 'Vit-D (KIU)';
    case 'Vit-E (IU)':
      return 'Vit-E (IU)';
    case 'Biotin (ppm)':
      return weightUnit === globalUnits.METRIC ? 'Biotin (ppm)' : 'Biotin (mg/lb)';
    case 'Niacin (%DM)':
      return 'Niacin (%DM)';
    case 'Niacin (ppm)':
      return 'Niacin' + ' ' + '(ppm)';
    case 'Chromium (ppm)':
      return 'Chromium (ppm)';
    case 'Choline (ppm)':
      return 'Choline (ppm)';
    case 'Monensin (mg/day)':
      return 'Monensin (mg/day)';
    case 'Lasalocid (mg/day)':
      return 'Lasalocid (mg/day)';
    case 'Lasalocid (ppm)':
      return 'Lasalocid (ppm)';
    case 'Chlortetracycline (mg/day)':
      return 'Chlortetracycline' + ' ' + '(mg/day)';
    case 'Chlortetracycline (ppm)':
      return 'Chlortetracycline' + ' ' + '(ppm)';
    case 'Salt (%DM)':
      return 'Salt (%DM)';
    case 'DCAD2 (meq/kg)':
      return 'DCAD2 (meq/kg)';
    case 'Org_Zn (%Zn)':
      return 'Org. Zinc (%Zn)';
    case 'Org_Se (%Se)':
      return 'Org. Selenium (%Se)';
    case 'Org_Co (%Co)':
      return 'Org. Cobalt (%Co)';
    case 'Org_Mn (%Mn)':
      return 'Org. Manganese (%Mn)';
    case 'Org_Cu (%Cu)':
      return 'Org. Copper (%Cu)';
    case 'Org_Chromium (%Chromium)':
      return 'Org. Chromium (%Chromium)';
    case 'ARG (%MP)':
      return 'ARG' + ' ' + '(%MP)';
    case 'THR (%MP)':
      return 'THR' + ' ' + '(%MP)';
    case 'LEU (%MP)':
      return 'LEU' + ' ' + '(%MP)';
    case 'ILE (%MP)':
      return 'ILE' + ' ' + '(%MP)';
    case 'VAL (%MP)':
      return 'VAL' + ' ' + '(%MP)';
    case 'HIS (%MP)':
      return 'HIS' + ' ' + '(%MP)';
    case 'PHE (%MP)':
      return 'PHE' + ' ' + '(%MP)';
    case 'TRP (%MP)':
      return 'TRP' + ' ' + '(%MP)';
    case 'ARG (%Rqd)':
      return 'ARG' + ' ' + '(%Rqd)';
    case 'THR (%Rqd)':
      return 'THR' + ' ' + '(%Rqd)';
    case 'LEU (%Rqd)':
      return 'LEU' + ' ' + '(%Rqd)';
    case 'ILE (%Rqd)':
      return 'ILE' + ' ' + '(%Rqd)';
    case 'VAL (%Rqd)':
      return 'VAL' + ' ' + '(%Rqd)';
    case 'HIS (%Rqd)':
      return 'HIS' + ' ' + '(%Rqd)';
    case 'PHE (%Rqd)':
      return 'PHE' + ' ' + '(%Rqd)';
    case 'TRP (%Rqd)':
      return 'TRP' + ' ' + '(%Rqd)';
    case 'ARG (g)':
      return 'ARG' + ' ' + '(g)';
    case 'THR (g)':
      return 'THR' + ' ' + '(g)';
    case 'LEU (g)':
      return 'LEU' + ' ' + '(g)';
    case 'ILE (g)':
      return 'ILE' + ' ' + '(g)';
    case 'VAL (g)':
      return 'VAL' + ' ' + '(g)';
    case 'HIS (g)':
      return 'HIS' + ' ' + '(g)';
    case 'PHE (g)':
      return 'PHE' + ' ' + '(g)';
    case 'TRP (g)':
      return 'TRP' + ' ' + '(g)';
    case 'Milk Fever Risk (%)':
      return 'Milk Fever Risk (%)';
    case 'Drinking Water Intake':
      return weightUnit === globalUnits.METRIC
        ? 'Drinking Water Intake' + ' ' + '(kg/day)'
        : 'Drinking Water Intake' + ' ' + '(lbs/day)';
    case 'Total Water Intake':
      return weightUnit === globalUnits.METRIC
        ? 'Total Water Intake' + ' ' + '(kg/day)'
        : 'Total Water Intake' + ' ' + '(lbs/day)';
    case 'Total Manure N (g)':
      return 'Total Manure N' + ' ' + '(g)';
    case 'Total Manure P (g)':
      return 'Total Manure P' + ' ' + '(g)';
    case 'Ca Added (g)':
      return 'Ca Added (g)';
    case 'P Added (g)':
      return 'P Added (g)';
    case 'K Added (g)':
      return 'K Added (g)';
    case 'Mg Added (g)':
      return 'Mg Added (g)';
    case 'S Added (g)':
      return 'S Added (g)';
    case 'Na Added (g)':
      return 'Na Added (g)';
    case 'Cl Added (g)':
      return 'Cl Added (g)';
    case 'Fe Added (mg)':
      return 'Fe Added (mg)';
    case 'Zn Added (mg)':
      return 'Zn Added (mg)';
    case 'Co Added (mg)':
      return 'Co Added (mg)';
    case 'I Added (mg)':
      return 'I Added (mg)';
    case 'Mn Added (mg)':
      return 'Mn Added (mg)';
    case 'Se Added (mg)':
      return 'Se Added (mg)';
    case 'Cu Added (mg)':
      return 'Cu Added (mg)';
    case 'Vit-A Added (KIU)':
      return 'Vit-A Added (KIU)';
    case 'Vit-D Added (KIU)':
      return 'Vit-D Added (KIU)';
    case 'Vit-E Added (IU)':
      return 'Vit-E Added (IU)';
    case 'Inputted RUP (g)':
      return 'Inputted RUP' + ' ' + '(g)';
    case 'Inputted RDP (g)':
      return 'Inputted RDP' + ' ' + '(g)';
    case 'Inputted RUP (%CP)':
      return 'Inputted RUP' + ' ' + '(%CP)';
    case 'Inputted RUP (%DM)':
      return 'Inputted RUP' + ' ' + '(%DM)';
    case 'Inputted RDP (%CP)':
      return 'Inputted RDP' + ' ' + '(%CP)';
    case 'Inputted RDP (%DM)':
      return 'Inputted RDP' + ' ' + '(%DM)';
    case 'Urea (g)':
      return 'Urea' + ' ' + '(g)';
    case 'Vegetable Fat (g)':
      return 'Vegetable Fat' + ' ' + '(g)';
    case 'Animal Protein (g)':
      return 'Animal Protein' + ' ' + '(g)';
    case 'Inputted NEg (Mcal)':
      return weightUnit === energyUnits.MCAL ? 'Inputted NEg (Mcal)' : 'Inputted NEg (MJ)';
    case 'Inputted NEg (Mcal/kg)':
      const kgLabelNEg = weightUnit === energyUnits.MCAL ? 'Inputted NEg (Mcal/kg)' : 'Inputted NEg (MJ/kg)';
      const lbsLabelNEg = weightUnit === energyUnits.MCAL ? 'Inputted NEg (Mcal/lb)' : 'Inputted NEg (MJ/lb)';
      return weightUnit === globalUnits.METRIC ? kgLabelNEg : lbsLabelNEg;
    case 'Inputted NEm (Mcal)':
      return weightUnit === energyUnits.MCAL ? 'Inputted NEm (Mcal)' : 'Inputted NEm (MJ)';
    case 'Inputted NEm (Mcal/Kg)':
      const kgLabelNEm = weightUnit === energyUnits.MCAL ? 'Inputted NEm (Mcal/kg)' : 'Inputted NEm (MJ/kg)';
      const lbsLabelNEm = weightUnit === energyUnits.MCAL ? 'Inputted NEm (Mcal/lb)' : 'Inputted NEm (MJ/lb)';
      return weightUnit === globalUnits.METRIC ? kgLabelNEm : lbsLabelNEm;
    case 'Inputted NEl (Mcal)':
      return weightUnit === energyUnits.MCAL ? 'Inputted NEl (Mcal)' : 'Inputted NEl (MJ)';
    case 'Inputted NEl (Mcal/Kg)':
      const kgLabelNEl = weightUnit === energyUnits.MCAL ? 'Inputted NEl (Mcal/kg)' : 'Inputted NEl (MJ/kg)';
      const lbsLabelNEl = weightUnit === energyUnits.MCAL ? 'Inputted NEl (Mcal/lb)' : 'Inputted NEl (MJ/lb)';
      return weightUnit === globalUnits.METRIC ? kgLabelNEl : lbsLabelNEl;
    case 'Inputted TDN (g)':
      return 'Inputted TDN' + ' ' + '(g)';
    case 'Organic Fe (mg)':
      return 'Organic Fe' + ' ' + '(mg)';
    case 'Carotene (mg)':
      return 'Carotene' + ' ' + '(mg)';
    case 'Beta Carotene (mg)':
      return 'Beta Carotene' + ' ' + '(mg)';
    case 'Thiamine (mg)':
      return 'Thiamine' + ' ' + '(mg)';
    case 'Amprolium (mg)':
      return 'Amprolium' + ' ' + '(mg)';
    case 'Bambermycin (mg)':
      return 'Bambermycin' + ' ' + '(mg)';
    case 'Fenbendazole (mg)':
      return 'Fenbendazole' + ' ' + '(mg)';
    case 'MGA (mg)':
      return 'MGA' + ' ' + '(mg)';
    case 'Neomycin Base (mg)':
      return 'Neomycin Base' + ' ' + '(mg)';
    case 'Rabon (mg)':
      return 'Rabon' + ' ' + '(mg)';
    case 'Rumatel (mg)':
      return 'Rumatel' + ' ' + '(mg)';
    case 'Tylosin (mg)':
      return 'Tylosin' + ' ' + '(mg)';
    case 'Tilmicosin (mg)':
      return 'Tilmicosin' + ' ' + '(mg)';
    case 'EDDI (mg)':
      return 'EDDI' + ' ' + '(mg)';
    case 'Altosid (mg)':
      return 'Altosid' + ' ' + '(mg)';
    case 'Clarifly (mg)':
      return 'Clarifly' + ' ' + '(mg)';
    case 'Zilpaterol (mg)':
      return 'Zilpaterol' + ' ' + '(mg)';
    case 'Natural Vit E (IU)':
      return 'Natural Vit E' + ' ' + '(IU)';
    case 'Gosypol (mg)':
      return 'Gosypol' + ' ' + '(mg)';
    case 'DHA (g)':
      return 'DHA' + ' ' + '(g)';
    case 'EPA (g)':
      return 'EPA' + ' ' + '(g)';
    case 'DHA:EPA Ratio':
      return 'DHA:EPA Ratio';
    case 'DPA (g)':
      return 'DPA' + ' ' + '(g)';
    case 'Molybdenum (mg)':
      return 'Molybdenum' + ' ' + '(mg)';
    case 'Aflatoxin B1 (mg)':
      return 'Aflatoxin B1' + ' ' + '(mg)';
    case 'Aflatoxin B2 (mg)':
      return 'Aflatoxin B2' + ' ' + '(mg)';
    case 'Aflatoxin G1 (mg)':
      return 'Aflatoxin G1' + ' ' + '(mg)';
    case 'Aflatoxin G2 (mg)':
      return 'Aflatoxin G2' + ' ' + '(mg)';
    case 'Vomitoxin (mg)':
      return 'Vomitoxin' + ' ' + '(mg)';
    case 'DON 3-Acetyl (mg)':
      return 'DON 3-Acetyl' + ' ' + '(mg)';
    case 'DON 15-Acetyl (mg)':
      return 'DON 15-Acetyl' + ' ' + '(mg)';
    case 'Zearalenone (mg)':
      return 'Zearalenone' + ' ' + '(mg)';
    case 'Ochratoxin (mg)':
      return 'Ochratoxin' + ' ' + '(mg)';
    case 'T2 (mg)':
      return 'T2' + ' ' + '(mg)';
    case 'Fumonisin B1 (mg)':
      return 'Fumonisin B1' + ' ' + '(mg)';
    case 'Fumonisin B2 (mg)':
      return 'Fumonisin B2' + ' ' + '(mg)';
    case 'Fumonisin B3 (mg)':
      return 'Fumonisin B3' + ' ' + '(mg)';
    case 'Mold (CFU)':
      return 'Mold' + ' ' + '(CFU)';
    case 'Ergot (mg)':
      return 'Ergot' + ' ' + '(mg)';
    case 'Slaframine (mg)':
      return 'Slaframine' + ' ' + '(mg)';
    case 'Patulin (mg)':
      return 'Patulin' + ' ' + '(mg)';
    case 'Citrinin (CFU)':
      return 'Citrinin' + ' ' + '(CFU)';
    case 'Inputted P Avail (g)':
      return 'Inputted P Avail' + ' ' + '(g)';
    case 'uNDF (g)':
      return 'uNDF' + ' ' + '(g)';
    case 'C16:0 to C18:1 (c+t) Intake':
      return 'C16:0 to C18:1 (c+t) Intake';
    case 'C12:0 Intake (g)':
      return 'C12:0 Intake (g)';
    case 'C14:0 Intake (g)':
      return 'C14:0 Intake (g)';

    case 'C16:1 Intake (g)':
      return 'C16:1 Intake (g)';
    case 'C18:0 Intake (g)':
      return 'C18:0 Intake (g)';
    case 'C18:1 Trans Intake (g)':
      return 'C18:1 Trans Intake (g)';
    case 'C18:1 Cis Intake (g)':
      return 'C18:1 Cis Intake (g)';
    case 'C18:2 Intake (g)':
      return 'C18:2 Intake' + ' ' + '(g)';
    case 'C18:3 Intake (g)':
      return 'C18:3 Intake' + ' ' + '(g)';
    case 'C18:4 Intake (g)':
      return 'C18:4 Intake (g)';
    case 'Other Lipid Intake (g)':
      return 'Other Lipid Intake (g)';
    case 'IntTFA Intake (g)':
      return 'IntTFA Intake (g)';
    case 'LCFA Intake (g)':
      return 'LCFA Intake (g)';
    case 'RUFAL Intake (g)':
      return 'RUFAL Intake' + ' ' + '(g)';
    case 'Urea (kg)':
      return weightUnit === globalUnits.METRIC ? 'Urea' + ' ' + '(kg)' : 'Urea' + ' ' + '(lbs)';
    case 'RUP (g)':
      return 'RUP (g)';
    case 'RDP (g)':
      return 'RDP (g)';
    case 'RUP (%CP)':
      return 'RUP (%CP)';
    case 'RUP (%DM)':
      return 'RUP (%DM)';
    case 'RDP (%CP)':
      return 'RDP (%CP)';
    case 'Forage NDF (%DM)':
      return 'Forage NDF (%DM)';
    case 'Lignin (%DM)':
      return 'Lignin (%DM)';
    case 'uNDF (%DM)':
      return 'uNDF (%DM)';
    case 'Ca Added (%DM)':
      return 'Ca Added (%DM)';
    case 'P Added (%DM)':
      return 'P Added (%DM)';
    case 'K Added (%DM)':
      return 'K Added (%DM)';
    case 'Mg Added (%DM)':
      return 'Mg Added (%DM)';
    case 'S Added (%DM)':
      return 'S Added (%DM)';
    case 'Na Added (%DM)':
      return 'Na Added (%DM)';
    case 'Cl Added (%DM)':
      return 'Cl Added (%DM)';
    case 'Fe Added (ppm)':
      return 'Fe Added (ppm)';
    case 'Zn Added (ppm)':
      return 'Zn Added (ppm)';
    case 'Co Added (ppm)':
      return 'Co Added (ppm)';
    case 'I Added (ppm)':
      return 'I Added (ppm)';
    case 'Mn Added (ppm)':
      return 'Mn Added (ppm)';
    case 'Se Added (ppm)':
      return 'Se Added (ppm)';
    case 'Cu Added (ppm)':
      return 'Cu Added (ppm)';
    case 'Vit-A Added (KIU/kg)':
      return weightUnit === globalUnits.METRIC ? 'Vit-A Added (KIU/kg)' : 'Vit-A Added (KIU/lbs)';
    case 'Vit-D Added (KIU/kg)':
      return weightUnit === globalUnits.METRIC ? 'Vit-D Added (KIU/kg)' : 'Vit-D Added (KIU/lbs)';
    case 'Vit-E Added (IU/kg)':
      return weightUnit === globalUnits.METRIC ? 'Vit-E Added (IU/kg)' : 'Vit-E Added (IU/lbs)';
    case 'Milk Urea (mg/dl)':
      return 'Milk Urea (mg/dl)';
    case 'Total NDF (%BW)':
      return 'Total NDF (%BW)';
    case 'MET:ME':
      return 'MET:ME';
    case 'LYS:ME':
      return 'LYS:ME';
    case 'ARG:ME':
      return 'ARG:ME';
    case 'THR:ME':
      return 'THR:ME';
    case 'LEU:ME':
      return 'LEU:ME';
    case 'ILE:ME':
      return 'ILE:ME';
    case 'VAL:ME':
      return 'VAL:ME';
    case 'HIS:ME':
      return 'HIS:ME';
    case 'PHE:ME':
      return 'PHE:ME';
    case 'TRP:ME':
      return 'TRP:ME';
    case 'EAA:ME':
      return 'EAA:ME';
    case 'Milk Production (%BW)':
      return 'Milk Production (%BW)';
    case 'ECM':
      return weightUnit === globalUnits.METRIC ? 'ECM' + ' (' + 'kg/day' + ')' : 'ECM' + ' (' + 'lbs/day' + ')';
    case 'ECM (%BW)':
      return 'ECM (%BW)';
    case 'Feed Efficiency':
      return 'Feed Efficiency (ECM/DMI)';
    case 'Concentrate Intake (%BW)':
      return 'Concentrate Intake (%BW)';
    case 'EAA (g)':
      return 'EAA (g)';
    case 'Total EAA Req. (g)':
      return 'Total EAA Req. (g)';
    case 'CH4 (Mcal)':
      return 'CH4 (Mcal)';
    case 'Butyric':
      return weightUnit === globalUnits.METRIC ? 'Butyric' + ' (' + 'kg' + ')' : 'Butyric' + ' (' + 'lbs' + ')';
    case 'Butyric (g)':
      return 'Butyric' + ' ' + '(g)';
    case 'Butyric (%DM)':
      return 'Butyric' + ' (' + '%DM' + ')';
    case 'Acetic (%DM)':
      return 'Acetic' + ' (' + '%DM' + ')';
    case 'Propionic (%DM)':
      return 'Propionic' + ' (' + '%DM' + ')';
    case 'uNDF (%BW)':
      return 'uNDF (%BW)';
    case 'CHO-C (%DM)':
      return 'CHO-C (%DM)';
    case 'dNDF-12HR (%NDF)':
      return 'dNDF-12HR (%NDF)';
    case 'dNDF-30HR (%NDF)':
      return 'dNDF-30HR (%NDF)';
    case 'dNDF-72HR (%NDF)':
      return 'dNDF-72HR (%NDF)';
    case 'dNDF-120HR (%NDF)':
      return 'dNDF-120HR (%NDF)';
    case 'dNDF-240HR (%NDF)':
      return 'dNDF-240HR (%NDF)';
    case 'uNDF-12HR (%BW)':
      return 'uNDF-12HR (%BW)';
    case 'uNDF-30HR (%BW)':
      return 'uNDF-30HR (%BW)';
    case 'uNDF-72HR (%BW)':
      return 'uNDF-72HR (%BW)';
    case 'uNDF-120HR (%BW)':
      return 'uNDF-120HR (%BW)';
    case 'uNDF-240HR (%BW)':
      return 'uNDF-240HR (%BW)';
    case 'Molybdenum (ppm)':
      return 'Molybdenum (ppm)';
    case 'Allowable Milk (%BW)':
      return 'Allowable Milk (%BW)';
    case 'aNDFom':
      return weightUnit === globalUnits.METRIC ? 'aNDFom' + ' (' + 'kg' + ')' : 'aNDFom' + ' (' + 'lbs' + ')';
    case 'C16:0 Intake (g)':
      return 'C16:0 Intake (g)';
    case 'TFA (%DM)':
      return 'TFA (%DM)';
    case 'Forage uNDF-30HR':
      return weightUnit === globalUnits.METRIC
        ? 'Forage uNDF-30HR' + ' (' + 'kg' + ')'
        : 'Forage uNDF-30HR' + ' (' + 'lbs' + ')';
    case 'Forage dNDF-30HR':
      return weightUnit === globalUnits.METRIC
        ? 'Forage dNDF-30HR' + ' (' + 'kg' + ')'
        : 'Forage dNDF-30HR' + ' (' + 'lbs' + ')';
    case 'Forage':
      return weightUnit === globalUnits.METRIC ? 'Forage' + ' (' + 'kg' + ')' : 'Forage' + ' (' + 'lbs' + ')';
    case 'Microbial MP (%MP)':
      return 'Microbial MP (%MP)';
    case 'ME Milk:Feed':
      return 'ME Milk:Feed';
    case 'MP Milk:Feed':
      return 'MP Milk:Feed';
    case 'ME ADG + Preg':
      return 'ME ADG + Preg';
    case 'MP ADG + Preg':
      return 'MP ADG + Preg';
    case 'Milk to Cover Feed Costs (kg)':
      return weightUnit === globalUnits.METRIC
        ? 'Milk to Cover Feed Costs' + ' (' + 'kg' + ')'
        : 'Milk to Cover Feed Costs' + ' (' + 'lbs' + ')';
    default:
      if (weightUnit === globalUnits.IMPERIAL && outputName?.match(/[^()]*(?=\))/g)) {
        const unit = outputName?.match(/[^()]*(?=\))/g)[0];
        if (Object.keys(formatLabels).includes(unit)) {
          return outputName?.replace(`(${unit})`, `(${formatLabels[unit]})`);
        }
        return outputName;
      }
      return outputName;
  }
};

export default nutrientLabels;
export { getRationOutputLabel, nutrientOrder };
