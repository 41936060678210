import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonLinkCard } from '../../../components/shared/cards';
import routes from '../../../constants/routes';
import { containerPadding } from '../../../styles/theme/shared';

const BCSPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const links = [
    {
      title: t('bcs.newScore'),
      subtitle: t('bcs.newScoreSubtitle'),
      icon: <AddCircleIcon />,
      url: routes.BCS_NEW_SCORE,
    },
    {
      title: t('bcs.results'),
      subtitle: t('bcs.resultsSubtitle'),
      icon: <TableRowsIcon />,
      url: routes.BCS_RESULTS,
    },
  ];

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{t('bcs.title')}</h2>
      <Grid
        component='div'
        container
        spacing={{ xs: 3, sm: 3, md: 4 }}
        sx={{ mt: { xs: '-24px', sm: '-24px', md: '-16px' } }}
      >
        {links?.map((item) => (
          <Grid component='div' item key={item.url} md={6} sm={12} xs={12}>
            <ButtonLinkCard {...item} onClick={() => navigate(item.url)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BCSPage;
