import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button';

import { Transition, StyledActions, StyledDialog } from './styled';

const Modal = (props) => {
  const {
    open,
    onClose = () => {},
    fullWidth = true,
    maxWidth = 'sm',
    title = '',
    closeText = '',
    submitText = '',
    onSubmit = null,
    children,
    sxBody = {},
    submitDisabled = false,
    submitLoading = false,
    ...other
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledDialog
      {...other}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ paddingLeft: '0px !important', paddingRight: '8px !important' }}>
          <DialogTitle id='responsive-dialog-title' sx={{ flex: 1, fontWeight: 'bold' }}>
            {title}
          </DialogTitle>
          <IconButton aria-label='close' color='inherit' edge='start' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ backgroundColor: theme?.palette?.primary?.greyLight, ...sxBody }}>{children}</DialogContent>
      <StyledActions>
        <Button onClick={onClose} variant='outlined'>
          {closeText || t('close')}
        </Button>
        {onSubmit && (
          <Button disabled={submitDisabled} loading={submitLoading} onClick={onSubmit}>
            {submitText || t('submit')}
          </Button>
        )}
      </StyledActions>
    </StyledDialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  submitLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl', false]),
  title: PropTypes.string,
  closeText: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.oneOfType([PropTypes.func]),
};

export default Modal;
