/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import './styles.scss';

const TablePrint = forwardRef((props, ref) => {
  const { columns = [], rows = [] } = props;

  const columnFormatter = (column, row) => {
    if (column.exportFormatter) {
      return column.exportFormatter(row[column.field], row);
    }
    return column.formatter ? column.formatter(row[column.field], row) : row[column.field];
  };

  return (
    <div className='table-print__container' ref={ref}>
      <table>
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={`${column.title}_${index}`}>
                {column.headerFormatter ? column.headerFormatter(column.title) : column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <tr key={Math.random() * Math.random()}>
              {columns?.map((column) => (
                <td key={Math.random() * Math.random()}>{columnFormatter(column, row)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default TablePrint;
