import MoneyIcon from '@mui/icons-material/PaidOutlined';
import WeightIcon from '@mui/icons-material/ScaleOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { sum } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getTotal } from '../../helpers/table';
import { useSettings } from '../../hooks';
import { settingsSelector } from '../../reducers/selectors';

import FeedCard from './FeedCard';

export default function FeedList({ data = [], handleFeedClick, info }) {
  const userSettings = useSelector(settingsSelector);

  const { t } = useTranslation();
  const { formatLabel, formatWeight, formatCost } = useSettings();

  const totalFormatter = useCallback(
    (field) => {
      const result = info?.[field]
        ? formatWeight(info[field], null, '(lbs)', true)
        : getTotal(data?.map((item) => formatWeight(item[field], item?.feed_type, '(lbs)')));
      return result?.replace('.', userSettings.DecimalFormat);
    },
    [data, userSettings]
  );

  return (
    <Stack component='div' spacing={2}>
      <Box component='div' sx={{ mb: 1 }}>
        <Typography color='primary' component='div' sx={{ fontWeight: 'bold', mb: 1 }} variant='div'>
          {`${t('total')}:`}
        </Typography>

        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
            <WeightIcon />
            <Typography component='span' sx={{ ml: 1, fontWeight: 'bold' }}>
              {formatLabel(`${t('rations.DM')} (kg)`)}
            </Typography>
          </Box>
          <Typography component='span'>{totalFormatter('DM_amount')}</Typography>
        </Box>
        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
            <WeightIcon />
            <Typography component='span' sx={{ ml: 1, fontWeight: 'bold' }}>
              {formatLabel(`${t('rations.asFed')} (kg)`)}
            </Typography>
          </Box>
          <Typography component='span'>{totalFormatter('AF_amount')}</Typography>
        </Box>
        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
            <MoneyIcon />
            <Typography component='span' sx={{ ml: 1, fontWeight: 'bold' }}>
              {t('cost')}
            </Typography>
          </Box>
          <Typography component='span'>{formatCost(info?.cost ?? sum(data?.map((item) => item.cost)))}</Typography>
        </Box>
      </Box>

      {data?.map((farm) => (
        <FeedCard key={farm.feed_name} {...farm} handleFeedClick={handleFeedClick} />
      ))}
    </Stack>
  );
}
