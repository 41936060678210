import UserService from '../services/userService';

import {
  SET_RATION_INFO,
  SET_RATION_NUTRIENTS,
  SET_RATION_TEMPLATE,
  SET_RATION_TEMPLATE_LIST,
  SET_EXACT_FEED,
  SET_RATION_TOTAL_INFO,
} from './actionTypes';

export const setRation = (ration) => ({ type: SET_RATION_INFO, ration });
export const setRationInfo = (info) => ({ type: SET_RATION_TOTAL_INFO, info });
export const setRationTemplate = (currentTemplate) => ({ type: SET_RATION_TEMPLATE, currentTemplate });
export const setTemplatesList = (templateList) => ({ type: SET_RATION_TEMPLATE_LIST, templateList });
export const setNutrients = (nutrients) => ({ type: SET_RATION_NUTRIENTS, nutrients });
export const setExactFeedOrMix = (exactFeed) => ({ type: SET_EXACT_FEED, exactFeed });

export const getRationById = (obj) => async (dispatch) => {
  try {
    const res = await UserService.getRationById(obj);
    dispatch(setRation(res?.data?.all_feeds));
    dispatch(
      setRationInfo({
        DM_amount: res?.data?.total_DM_amount,
        AF_amount: res?.data?.total_AF_amount,
        cost: res?.data?.total_costPerTon,
      })
    );
  } catch (err) {
    console.error(err);
  }
};

export const resetRation = () => (dispatch) => {
  dispatch(setRation([]));
  dispatch(setRationInfo({}));
  dispatch(setNutrients({}));
};

export const getTemplateList = () => async (dispatch) => {
  try {
    const res = await UserService.getUserTemplates();
    dispatch(setTemplatesList(res?.data?.templates));
  } catch (err) {
    console.error(err);
  }
};

export const setUserTemplateList = (template) => async (dispatch) => {
  try {
    dispatch(setRationTemplate(template));
  } catch (err) {
    throw new Error('Server Error');
  }
};

export const getNutrientsById = (obj) => async (dispatch) => {
  try {
    const res = await UserService.getNutrientById(obj);
    dispatch(setNutrients(res?.data?.data?.nutrients));
  } catch (err) {
    console.error(err);
  }
};

export const setExactFeed = (obj) => (dispatch) => {
  dispatch(setExactFeedOrMix(obj));
};
