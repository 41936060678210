/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { filterCurrentFeeds } from '../../actions/pscFeedHistoryActions';
import { getFeedList } from '../../actions/pscSingleSampleFeedActions';
import { routes } from '../../constants';
import { useAlert, useLoading } from '../../hooks';
import { particleSizeSampleFeedSelector } from '../../reducers/selectors';
import ParticleSizeService from '../../services/particleSizeService';
import { Modal, Select, Skeleton } from '../shared';

import SampleFeedForm from './SampleFeedForm';

const initialValues = {
  Farm_ID: '',
  Feed_ID: '',
  TopAmount: '',
  SecondPanAmount: '',
  ThirdPanAmount: '',
  BottomPanAmount: '',
  ShakerTypeId: '1',
};

const SingleSampleFeedModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feeds = useSelector(particleSizeSampleFeedSelector);
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const { startLoading, loading, stopLoading } = useLoading(true);

  const { open, onClose } = props;

  const { feedsList, isLoaded } = feeds;

  const [selectedFarm, setSelectedFarm] = useState();
  const [selectedFeed, setSelectedFeed] = useState();

  const onSubmit = async (formObj) => {
    startLoading();

    try {
      const FeedName = feedsList
        .find((el) => el.farm_id === formObj.Farm_ID)
        ?.feeds.find((feed) => feed.feed_id === formObj.Feed_ID)?.feed_name;
      const FarmName = feedsList.find((el) => el.farm_id === formObj.Farm_ID)?.farm_name;
      const values = {
        Farm_ID: formObj.Farm_ID,
        Feed_ID: formObj.Feed_ID,
        FeedName,
        TopAmount: Number(formObj.TopAmount) || 0,
        SecondPanAmount: Number(formObj.SecondPanAmount) || 0,
        ThirdPanAmount: Number(formObj.ThirdPanAmount) || 0,
        BottomPanAmount: Number(formObj.BottomPanAmount) || 0,
        ShakerTypeId: Number(formObj.ShakerTypeId) || 0,
      };

      const res = await ParticleSizeService.createFeedSample(values);
      dispatch(
        filterCurrentFeeds([
          {
            ...res?.data?.feed,
            FarmName,
          },
        ])
      );
      showAlert('success', t('psc.successAlert'));
    } catch (err) {
      console.error(err);
      showAlert('error', JSON.stringify(err));
    } finally {
      stopLoading();
      onClose();
      navigate(routes.PSC_FEED_HISTORY);
    }
  };

  const form = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getFeedList());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (open && !isLoaded) {
      onLoad().catch(console.error);
    }
    if (!open && isLoaded) {
      form.resetForm();
      setSelectedFeed(null);
      setSelectedFarm(null);
      stopLoading();
    }
  }, [open, isLoaded]);

  const feedOptions = useMemo(() => {
    if (!selectedFarm) {
      return [];
    }
    const farm = feedsList?.find((f) => f.farm_id === selectedFarm);
    return farm && farm.feeds
      ? farm.feeds?.map((feed) => ({
          label: feed?.feed_name || '',
          value: feed?.feed_id,
        }))
      : [];
  }, [selectedFarm]);

  const submitDisabled =
    !form.values.Farm_ID ||
    !form.values.Feed_ID ||
    isNaN(form.values.BottomPanAmount) ||
    isNaN(form.values.TopAmount) ||
    isNaN(form.values.SecondPanAmount) ||
    isNaN(form.values.ThirdPanAmount) ||
    !form.values.SecondPanAmount.length ||
    !form.values.BottomPanAmount.length ||
    !form.values.ThirdPanAmount.length ||
    !form.values.TopAmount.length;

  const handleSubmit = () => {
    form.handleSubmit();
  };

  return (
    <Modal
      closeText={t('cancel')}
      maxWidth='xs'
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      submitDisabled={submitDisabled || loading}
      submitLoading={loading && isLoaded}
      title={t('psc.singleSampleFeed')}
    >
      {loading && !isLoaded ? (
        <Skeleton height={56} rows={2} />
      ) : (
        <Box component='div' sx={{ pt: 1 }}>
          <Stack component='div' spacing={2}>
            <Select
              id='farm-select'
              label={t('farm')}
              name='Farm_ID'
              onChange={(e) => {
                form.handleChange(e);
                form.setFieldValue('Feed_ID', '');
                setSelectedFarm(e.target.value);
                setSelectedFeed(null);
              }}
              options={feedsList?.map((farm) => ({
                label: farm?.farm_name,
                value: farm?.farm_id,
              }))}
              value={form.values.Farm_ID}
            />
            <Select
              disabled={!selectedFarm}
              id='feed-select'
              label={t('psc.feed')}
              name='Feed_ID'
              onChange={(e) => {
                form.handleChange(e);
                setSelectedFeed(e.target.value);
              }}
              options={feedOptions}
              value={form.values.Feed_ID}
            />
            {selectedFeed && <SampleFeedForm form={form} />}
          </Stack>
        </Box>
      )}
    </Modal>
  );
};

export default SingleSampleFeedModal;
