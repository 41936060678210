import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const StyledCard = styled(Box)(({ theme }) => ({
  paddingBottom: 8,
  position: 'relative',
  h6: {
    background: theme.palette?.primary?.main,
    color: theme.palette?.primary?.contrastText,
    fontWeight: 'bold',
    padding: '4px 12px',
    borderRadius: '4px 4px 0 0',
  },
  span: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  '> div': {
    marginTop: 8,
    padding: '0px 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    position: 'absolute',
    height: 30,
    bottom: 12,
    right: 12,
  },
  svg: {
    marginRight: 4,
  },
}));

export const ButtonCard = styled(Button)(({ theme }) => ({
  padding: theme?.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  background: '#ffffff',
  border: `2px solid ${theme?.palette?.primary?.grey}`,
  borderRadius: 12,
  h5: {
    color: '#333333',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: theme?.spacing(1),
    textAlign: 'left',
  },
  span: {
    color: theme?.palette?.primary?.greyDark,
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'none',
  },
  div: {
    width: '100%',
    marginTop: theme?.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    svg: {
      fontSize: 46,
    },
  },
}));
