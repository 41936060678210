import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { routerReducer as routing } from 'react-router-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import './i18n';
import App from './App';
import { AlertProvider } from './providers';
import reducers from './reducers';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const combinedReducers = combineReducers({ ...reducers, routing });
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <I18nextProvider>
      <AlertProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertProvider>
    </I18nextProvider>
  </Provider>
);

reportWebVitals();
serviceWorker.register();
