import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../../helpers';
import { useSettings } from '../../../hooks';
import { Skeleton, Table } from '../../shared';

const BcsResultsTable = (props) => {
  const { data, loading } = props;
  const { t } = useTranslation();

  const { formatDate, formatDecimal } = useSettings();

  const toNumbArr = (row) => row.map((q) => Number(q));

  const avg = (row) => getAvg(toNumbArr(row));
  const n = (row) => row?.length;
  const min = (row) => Math.min(...toNumbArr(row));
  const max = (row) => Math.max(...toNumbArr(row));
  const range = (row) => max(toNumbArr(row)) - min(toNumbArr(row));
  const sd = (row) => getStandardDeviation(toNumbArr(row))?.toFixed(2);
  const cv = (row) => coefficientOfVariation(toNumbArr(row))?.toFixed(2);

  const columns = [
    {
      title: t('info'),
      field: 'FarmName',
      formatter: (value, row) => (
        <Box component='div' sx={{ whiteSpace: 'pre-line' }}>
          {`${row?.FarmName}\n${row?.LocationName}\n${row?.CattleName}\n${formatDate(row?.OADate, true, true)}`}
        </Box>
      ),
      exportFormatter: (value, row) => `${row?.CattleName} – ${formatDate(row?.OADate, true, true)}`,
    },
    {
      title: 'N',
      field: 'number',
      formatter: (value, row) => n(row?.BCS),
    },
    {
      title: t('bcs.avg'),
      field: 'avg',
      formatter: (value, row) => formatDecimal(avg(row?.BCS)),
    },
    {
      title: t('bcs.min'),
      field: 'min',
      formatter: (value, row) => formatDecimal(min(row?.BCS)),
    },
    {
      title: t('bcs.max'),
      field: 'max',
      formatter: (value, row) => formatDecimal(max(row?.BCS)),
    },
    {
      title: t('bcs.cv'),
      field: 'cv',
      formatter: (value, row) => formatDecimal(cv(row?.BCS)),
    },
    {
      title: t('bcs.sd'),
      field: 'sd',
      formatter: (value, row) => formatDecimal(sd(row?.BCS)),
    },
    {
      title: t('bcs.range'),
      field: 'range',
      formatter: (value, row) => formatDecimal(range(row?.BCS)),
    },
  ];

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      keyField='feed_name'
      rows={data}
      stickyHeader
      sx={{ maxHeight: { md: 'calc(100vh - 330px)', xs: 'calc(100vh - 140px)' } }}
      toolbar
    />
  );
};

export default BcsResultsTable;
