import Button from '@mui/material/Button';
import React from 'react';

const ScoreButton = ({ active, text, onClick, type = 'number' }) => (
  <Button
    onClick={onClick}
    sx={{
      width: type === 'number' ? 60 : 'auto',
      height: type === 'number' ? 60 : 'auto',
      margin: 1,
      fontWeight: 'bold',
      background: active ? 'default' : '#ffffff',
      fontSize: 16,
    }}
    variant={active ? 'contained' : 'outlined'}
  >
    {text}
  </Button>
);

export default ScoreButton;
