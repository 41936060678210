import Typography from '@mui/material/Typography';
import React from 'react';

const FeedsInfo = ({ currentInfo }) => {
  const feedInfo = `${currentInfo?.farmName} - ${currentInfo?.FeedName}`;

  return (
    currentInfo && (
      <Typography
        component='div'
        sx={{
          mb: 2,
          fontWeight: 'bold',
          fontSize: 14,
          color: ({ palette }) => palette?.primary?.greyDark,
        }}
      >
        {feedInfo}
      </Typography>
    )
  );
};

export default FeedsInfo;
