import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NoResults = ({ url, text }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box component='div' sx={{ width: 'fit-content' }}>
      <MuiAlert icon={<HelpOutlineIcon />} severity='warning' variant='outlined'>
        <Box as='span'>{text}</Box>
        {url && (
          <Box as='span' sx={{ ml: 1 }}>
            {t('noResults.create')}
            <Typography
              component='span'
              onClick={() => navigate(url)}
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                cursor: 'pointer',
                color: '#9db668',
                marginLeft: '4px',
                textDecoration: 'underline',
              }}
            >
              {`${t('noResults.here')}`}
            </Typography>
          </Box>
        )}
      </MuiAlert>
    </Box>
  );
};

NoResults.PropTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
};

export default NoResults;
