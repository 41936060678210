import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const StyledContainer = styled(Box)(() => ({
  position: 'fixed',
  background: '#ffffff',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  height: '100%',
  top: '0',
  borderRadius: 0,
  width: 'auto',
  display: 'flex',
  zIndex: 10,
}));

export const ArrowContainer = styled(Button)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  width: 24,
  minWidth: 24,
  maxWidth: 24,
  background: '#ffffff',
  cursor: 'pointer',
}));
