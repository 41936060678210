import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';

const ChartContainer = ({ children, title = '' }) => (
  <Paper sx={{ padding: 2 }}>
    <Typography
      component='div'
      sx={{ mb: 2, fontSize: 16, fontWeight: 'bold', textAlign: 'center', whiteSpace: 'pre-line' }}
    >
      {title}
    </Typography>
    {children}
  </Paper>
);

export default ChartContainer;
