import CircularProgress from '@mui/material/CircularProgress';
import React, { memo } from 'react';

import './load-screen.scss';

const LoadScreen = () => (
  <div className='load-screen'>
    <CircularProgress />
  </div>
);

export default memo(LoadScreen);
