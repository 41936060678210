import AccountCircle from '@mui/icons-material/AccountCircle';
import ChangeProjectIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../constants';
import { PROJECTS_URL } from '../../constants/urls';
import useAuth from '../../hooks/useAuth';
import { userSelector } from '../../reducers/selectors';

import StyledAppBar from './styled';

export default function AppBar({ drawerWidth, open, toggleDrawer, handleDrawerOpen }) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const currentUser = useSelector(userSelector);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    logout();
    handleClose();
  };

  const handleChangeProject = () => {
    window.location = PROJECTS_URL;
    handleClose();
  };

  const userName = currentUser?.first_name ? `${currentUser.first_name} ${currentUser.last_name}` : '';

  return (
    <StyledAppBar
      open={open}
      position='fixed'
      sx={{ width: { xs: '100%', md: !open ? '100%' : `calc(100% - ${drawerWidth}px)` } }}
      width={drawerWidth}
    >
      <Toolbar sx={!open ? { paddingLeft: '16px !important' } : {}}>
        <IconButton
          aria-label='menu'
          color='inherit'
          edge='start'
          onClick={toggleDrawer}
          size='large'
          sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          aria-label='menu'
          color='inherit'
          edge='start'
          onClick={handleDrawerOpen}
          size='large'
          sx={{ mr: 2, display: { xs: 'none', md: 'inline-flex' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component='div' sx={{ flexGrow: 1, fontWeight: 'bold' }} variant='h6'></Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 1, fontWeight: 600 }} variant='h6'>
            {userName}
          </Typography>
          <IconButton
            aria-controls='menu-appbar'
            aria-haspopup='true'
            aria-label='account of current user'
            color='inherit'
            onClick={handleMenu}
            size='large'
          >
            <AccountCircle sx={{ fontSize: 28 }} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id='menu-appbar'
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem>
              <PersonIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
              {t('profile')}
            </MenuItem>
            <MenuItem onClick={handleChangeProject}>
              <ChangeProjectIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
              {t('changeProgram')}
            </MenuItem>
            <MenuItem onClick={logOut}>
              <a
                href={routes.LOGIN}
                onClick={logOut}
                style={{
                  textDecoration: 'inherit',
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LogoutIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
                {t('signOut')}
              </a>
            </MenuItem>
          </Menu>
          <IconButton color='inherit' onClick={() => navigate(routes.SETTINGS)} size='large'>
            <SettingsIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
}
