import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = (colorMain) => {
  const themePalette = palette(colorMain);
  const config = {
    palette: themePalette,
    typography: {
      fontFamily: 'Verdana, sans-serif',
      fontWeight: 400,
      fontSize: 14,
      button: {
        fontWeight: 500,
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {},
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: themePalette?.primary?.greyLight,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: { backgroundColor: 'white' },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: themePalette?.primary?.main,
            // color: '#ffffff',
            '.MuiTableCell-root': {
              fontWeight: 600,
              textTransform: 'uppercase',
            },
          },
        },
      },
    },
  };

  return createTheme(config);
};

export default theme;
