import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SingleSampleFeedModal, SingleSampleTMRModal } from '../../../components/particle-size-calculator';
import { ButtonLinkCard } from '../../../components/shared/cards';
import routes from '../../../constants/routes';
import { containerPadding } from '../../../styles/theme/shared';

const PSCPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [singleSampleFeedModalOpen, setSingleSampleFeedModalOpen] = useState(false);
  const [singleSampleTMRModalOpen, setSingleSampleTMRModalOpen] = useState(false);

  const links = [
    {
      title: t('psc.singleSampleFeed'),
      subtitle: t('psc.singleSampleFeedSubtitle'),
      icon: <AddCircleIcon />,
      onClick: () => setSingleSampleFeedModalOpen(true),
    },
    {
      title: t('psc.singleSampleTMR'),
      subtitle: t('psc.singleSampleTMRSubtitle'),
      icon: <AddCircleIcon />,
      onClick: () => setSingleSampleTMRModalOpen(true),
    },
    {
      title: t('psc.historyFeed'),
      subtitle: t('psc.historyFeedSubtitle'),
      icon: <TableRowsIcon />,
      url: routes.PSC_FEED_HISTORY,
    },
    {
      title: t('psc.historyTMR'),
      subtitle: t('psc.historyTMRSubtitle'),
      icon: <TableRowsIcon />,
      url: routes.PSC_TMR_HISTORY,
    },
  ];

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{t('psc.title')}</h2>
      <Grid
        component='div'
        container
        spacing={{ xs: 3, sm: 3, md: 4 }}
        sx={{ mt: { xs: '-24px', sm: '-24px', md: '-16px' }, pb: 2 }}
      >
        {links?.map((item) => (
          <Grid component='div' item key={item.title} md={6} sm={12} xs={12}>
            <ButtonLinkCard {...item} onClick={() => (item?.onClick ? item?.onClick() : navigate(item.url))} />
          </Grid>
        ))}
      </Grid>
      <SingleSampleFeedModal onClose={() => setSingleSampleFeedModalOpen(false)} open={singleSampleFeedModalOpen} />
      <SingleSampleTMRModal onClose={() => setSingleSampleTMRModalOpen(false)} open={singleSampleTMRModalOpen} />
    </Container>
  );
};

export default PSCPage;
