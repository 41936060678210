/* eslint-disable */
const revertedLabels = {
  'Inputted Milk Production': 'Inputted_Milk_Production',
  'Actual Milk Production': 'Actual_Milk_Production',
  IOFC: 'IOFC',
  IOpurFC: 'IOpurFC',
  Cost: 'Cost',
  'Cost per ADG': 'Cost_Per_ADG',
  PurchasedCost: 'PurchasedCost',
  'DM (%)': 'perDM',
  DMI: 'DMI',
  'DMI (%BW)': 'DMI_per_BW',
  DMI_NRC: 'DMI_NRC',
  'ForageNDF (%BW)': 'ForageNDF_perBW',
  'Forage (%DM)': 'Forage',
  'ME Prod': 'MEProd',
  'MP Prod': 'MPProd',
  ME: 'MEng',
  MP: 'MP',
  'ME req. per kg predicted DMI': 'ME_req_perDMI',
  'MP req. per kg predicted DMI': 'MP_req_perDMI',
  'MP Supply (g)': 'MPSupp',
  'MP Supply (g/DMI)': 'MPSupp_per_DMI',
  'Rumen NH3': 'RumenNH3',
  Peptides: 'Peptides',
  NFC: 'NFC',
  peNDF: 'peNDF',
  'peCHO C': 'peCHO_C',
  'peCHO C (%BW)': 'peCHO_C_BW',
  Lactic: 'Lactic',
  Sugar: 'Sugar',
  Starch: 'Starch',
  'Sugar + Starch': 'Sugar_plus_Starch',
  'Sol. Fiber': 'SolFiber',
  'CHO-C': 'CHO_C',
  'Ferm. CHO': 'Ferm_CHO',
  'Ferm. CHO (%DM)': 'Ferm_CHO_DM',
  'Ferm. Fiber (%DM)': 'Ferm_Fiber_DM',
  'Ferm. Starch (%DM)': 'Ferm_Starch_DM',
  'Ferm. Starch (%Starch)': 'Ferm_Starch_Starch',
  'Ferm. Sugar (%DM)': 'Ferm_Sugar_DM',
  'Ferm. Sol. Fiber (%DM)': 'Ferm_SolFiber_DM',
  DTC_BCS: 'DTC_BCS',
  MUN: 'MUN',
  'Urea Cost': 'UreaCost',
  EE: 'EE',
  LCFA: 'LCFA',
  'Total UNSAT': 'TotalUNSAT',
  'LYS (%MP)': 'LYS_perMP',
  'MET (%MP)': 'MET_perMP',
  'LYS:MET': 'LYStoMET',
  'LYS (%Rqd)': 'LYS_perReq',
  'MET (%Rqd)': 'MET_perReq',
  'LYS (g)': 'LYS',
  'MET (g)': 'MET',
  'Predicted Milk True Protein (g)': 'Pred_Milk_True_Protein_Yield_g',
  'Actual Milk True Protein (g)': 'Actual_Milk_True_Protein_Yield_g',
  'Predicted Milk True Protein (%)': 'Pred_Milk_True_Protein_Yield_per',
  'Predicted Milk Fat (g)': 'Pred_Milk_Fat_Yield_g',
  'Actual Milk Fat (g)': 'Actual_Milk_Fat_Yield_g',
  'Predicted Milk Fat (%)': 'Pred_Milk_Fat_Yield_per',
  'Predicted Milk Production': 'Pred_Milk_Yield',
  'Component Yield (Fat+Protein)': 'Component_Yield',
  'Component Yield (Fat+Protein) %BW': 'Component_Yield_perBW',
  'AMTS casein Yield': 'AMTS_Casein_Yield',
  'AMTS VS Cheddar Yield': 'AMTS_VS_Cheddar_Yield',
  'CNCPS Rumen pH': 'CNCPS_Rumen_pH',
  'AMTS Rumen pH': 'AMTS_Rumen_pH',
  'AMTS Rumen pH < 5.8 Hrs': 'AMTS_Rumen_pH_Hrs_Below_5_8',
  'RDP (%DM)': 'RDP_DM',
  SP: 'SP',
  'ME Conc.': 'MEConc',
  'NEl Conc.': 'NElConc',
  'NEg Conc.': 'NEgConc',
  'NEm Conc.': 'NEmConc',
  Ash: 'Ash',
  'ForageNDF (%NDF)': 'ForageNDF_perNDF',
  'NDF (%DM)': 'NDF',
  'ADF (%DM)': 'ADF',
  'TDN (%)': 'TDN',
  'CP (%)': 'CP',
  NEl_3X: 'NEl_3X',
  NEl_3X_Conc: 'NEl_3X_Conc',
  'Monensin (mg/day)': 'Monensin',
  'Monensin (ppm)': 'Monensin_ppm',
  'DCAD1 (meq/kg)': 'DCAD1',
  'Ca (%Rqd)': 'Ca_Rqd',
  'P (%Rqd)': 'P_Rqd',
  'K (%Rqd)': 'K_Rqd',
  'Mg (%Rqd)': 'Mg_Rqd',
  'S (%Rqd)': 'S_Rqd',
  'Na (%Rqd)': 'Na_Rqd',
  'Cl (%Rqd)': 'Cl_Rqd',
  'Fe (%Rqd)': 'Fe_Rqd',
  'Zn (%Rqd)': 'Zn_Rqd',
  'Co (%Rqd)': 'Co_Rqd',
  'I (%Rqd)': 'I_Rqd',
  'Mn (%Rqd)': 'Mn_Rqd',
  'Se (%Rqd)': 'Se_Rqd',
  'Cu (%Rqd)': 'Cu_Rqd',
  'Vit-A (%Rqd)': 'VitA_Rqd',
  'Vit-D (%Rqd)': 'VitD_Rqd',
  'Vit-E (%Rqd)': 'VitE_Rqd',
  'Ca (%DM)': 'Ca_DM',
  'P (%DM)': 'P_DM',
  'K (%DM)': 'K_DM',
  'Mg (%DM)': 'Mg_DM',
  'S (%DM)': 'S_DM',
  'Na (%DM)': 'Na_DM',
  'Cl (%DM)': 'Cl_DM',
  'Fe (ppm)': 'Fe_ppm',
  'Zn (ppm)': 'Zn_ppm',
  'Co (ppm)': 'Co_ppm',
  'I (ppm)': 'I_ppm',
  'Mn (ppm)': 'Mn_ppm',
  'Se (ppm)': 'Se_ppm',
  'Cu (ppm)': 'Cu_ppm',
  'Vit-A': 'VitA_KIU_kg',
  'Vit-D': 'VitD_KIU_kg',
  'Vit-E': 'VitE_IU_kg',
  'Ca (g)': 'Ca',
  'P (g)': 'P',
  'K (g)': 'K',
  'Mg (g)': 'Mg',
  'S (g)': 'S',
  'Na (g)': 'Na',
  'Cl (g)': 'Cl',
  'Fe (g)': 'Fe',
  'Zn (g)': 'Zn',
  'Co (g)': 'Co',
  'I (g)': 'I',
  'Mn (g)': 'Mn',
  'Se (g)': 'Se',
  'Cu (g)': 'Cu',
  'Vit-A (KIU)': 'VitA',
  'Vit-D (KIU)': 'VitD',
  'Vit-E (IU)': 'VitE',
  'Biotin (ppm)': 'Biotin',
  'Niacin (%DM)': 'Niacin',
  'Niacin (ppm)': 'Niacin_ppm',
  'Chromium (ppm)': 'Chromium',
  'Choline (ppm)': 'Choline',
  'Lasalocid (mg/day)': 'Lasalocid',
  'Lasalocid (ppm)': 'Lasalocid_ppm',
  'Chlortetracycline (mg/day)': 'Chlortetracycline',
  'Chlortetracycline (ppm)': 'Chlortetracycline_ppm',
  'Salt (%DM)': 'Salt',
  'DCAD2 (meq/kg)': 'DCAD2',
  'Org_Zn (%Zn)': 'Org_Zn',
  'Org_Se (%Se)': 'Org_Se',
  'Org_Co (%Co)': 'Org_Co',
  'Org_Mn (%Mn)': 'Org_Mn',
  'Org_Cu (%Cu)': 'Org_Cu',
  'Org_Chromium (%Chromium)': 'Org_Chromium',
  'ARG (%MP)': 'ARG_perMP',
  'THR (%MP)': 'THR_perMP',
  'LEU (%MP)': 'LEU_perMP',
  'ILE (%MP)': 'ILE_perMP',
  'VAL (%MP)': 'VAL_perMP',
  'HIS (%MP)': 'HIS_perMP',
  'PHE (%MP)': 'PHE_perMP',
  'TRP (%MP)': 'TRP_perMP',
  'ARG (%Rqd)': 'ARG_perReq',
  'THR (%Rqd)': 'THR_perReq',
  'LEU (%Rqd)': 'LEU_perReq',
  'ILE (%Rqd)': 'ILE_perReq',
  'VAL (%Rqd)': 'VAL_perReq',
  'HIS (%Rqd)': 'HIS_perReq',
  'PHE (%Rqd)': 'PHE_perReq',
  'TRP (%Rqd)': 'TRP_perReq',
  'ARG (g)': 'ARG',
  'THR (g)': 'THR',
  'LEU (g)': 'LEU',
  'ILE (g)': 'ILE',
  'VAL (g)': 'Val',
  'HIS (g)': 'HIS',
  'PHE (g)': 'PHE',
  'TRP (g)': 'TRP',
  'Milk Fever Risk (%)': 'MilkFeverRisk',
  'Drinking Water Intake': 'DrinkingWaterIntake',
  'Total Water Intake': 'TotalWaterIntake',
  'Total Manure N (g)': 'Total_Manure_N',
  'Total Manure P (g)': 'Total_Manure_P',
  'Ca Added (g)': 'Ca_Added',
  'P Added (g)': 'P_Added',
  'K Added (g)': 'K_Added',
  'Mg Added (g)': 'Mg_Added',
  'S Added (g)': 'S_Added',
  'Na Added (g)': 'Na_Added',
  'Cl Added (g)': 'Cl_Added',
  'Fe Added (mg)': 'Fe_Added',
  'Zn Added (mg)': 'Zn_Added',
  'Co Added (mg)': 'Co_Added',
  'I Added (mg)': 'I_Added',
  'Mn Added (mg)': 'Mn_Added',
  'Se Added (mg)': 'Se_Added',
  'Cu Added (mg)': 'Cu_Added',
  'Vit-A Added (KIU)': 'VitA_Added',
  'Vit-D Added (KIU)': 'VitD_Added',
  'Vit-E Added (IU)': 'VitE_Added',
  'Inputted RUP (g)': 'Inputted_RUP',
  'Inputted RDP (g)': 'Inputted_RDP',
  'Inputted RUP (%CP)': 'Inputted_RUP_CP',
  'Inputted RUP (%DM)': 'Inputted_RUP_DM',
  'Inputted RDP (%CP)': 'Inputted_RDP_CP',
  'Inputted RDP (%DM)': 'Inputted_RDP_DM',
  'Urea (g)': 'Urea',
  'Vegetable Fat (g)': 'Vegetable_Fat',
  'Animal Protein (g)': 'Animal_Protein',
  'Inputted NEg (Mcal)': 'Inputted_NEg',
  'Inputted NEg (Mcal/kg)': 'Inputted_NEg_Conc',
  'Inputted NEm (Mcal)': 'Inputted_NEm',
  'Inputted NEm (Mcal/Kg)': 'Inputted_NEm_perKg',
  'Inputted NEl (Mcal)': 'Inputted_NEl',
  'Inputted NEl (Mcal/Kg)': 'Inputted_NEl_perKg',
  'Inputted TDN (g)': 'Inputted_TDN',
  'Inputted TDN (%DM)': 'Inputted_TDN_perDM',
  'Organic Fe (mg)': 'Organic_Fe',
  'Carotene (mg)': 'Carotene',
  'Beta Carotene (mg)': 'Beta_Carotene',
  'Thiamine (mg)': 'Thiamine',
  'Amprolium (mg)': 'Amprolium',
  'Bambermycin (mg)': 'Bambermycin',
  'Fenbendazole (mg)': 'Fenbendazole',
  'MGA (mg)': 'MGA',
  'Neomycin Base (mg)': 'Neomycin_Base',
  'Rabon (mg)': 'Rabon',
  'Rumatel (mg)': 'Rumatel',
  'Tylosin (mg)': 'Tylosin',
  'Tilmicosin (mg)': 'Tilmicosin',
  'EDDI (mg)': 'EDDI',
  'Altosid (mg)': 'Altosid',
  'Clarifly (mg)': 'Clarifly',
  'Zilpaterol (mg)': 'Zilpaterol',
  'Natural Vit E (IU)': 'Natural_Vit_E',
  'Gosypol (mg)': 'Gosypol',
  'DHA (g)': 'DHA',
  'EPA (g)': 'EPA',
  'DHA:EPA Ratio': 'DHA_EPA_Ratio',
  'DPA (g)': 'DPA',
  'Molybdenum (mg)': 'Molybdenum',
  'Aflatoxin B1 (mg)': 'Aflatoxin_B1',
  'Aflatoxin B2 (mg)': 'Aflatoxin_B2',
  'Aflatoxin G1 (mg)': 'Aflatoxin_G1',
  'Aflatoxin G2 (mg)': 'Aflatoxin_G2',
  'Vomitoxin (mg)': 'Vomitoxin',
  'DON 3-Acetyl (mg)': 'DON_3_Acetyl',
  'DON 15-Acetyl (mg)': 'DON_15_Acetyl',
  'Zearalenone (mg)': 'Zearalenone',
  'Ochratoxin (mg)': 'Ochratoxin',
  'T2 (mg)': 'T2',
  'Fumonisin B1 (mg)': 'Fumonisin_B1',
  'Fumonisin B2 (mg)': 'Fumonisin_B2',
  'Fumonisin B3 (mg)': 'Fumonisin_B3',
  'Mold (CFU)': 'Mold',
  'Ergot (mg)': 'Ergot',
  'Slaframine (mg)': 'Slaframine',
  'Patulin (mg)': 'Patulin',
  'Citrinin (CFU)': 'Citrinin',
  'Inputted P Avail (g)': 'P_Avail',
  'uNDF (g)': 'uNDF',
  'C12:0 Intake (g)': 'C12_0_Intake',
  'C14:0 Intake (g)': 'C14_0_Intake',
  'C16:0 Intake (g)': 'C16_0_Intake',
  'C16:1 Intake (g)': 'C16_1_Intake',
  'C18:0 Intake (g)': 'C18_0_Intake',
  'C18:1 Trans Intake (g)': 'C18_1_Trans_Intake',
  'C18:1 Cis Intake (g)': 'C18_1_Cis_Intake',
  'C18:2 Intake (g)': 'C18_2_Intake',
  'C18:3 Intake (g)': 'C18_3_Intake',
  'C18:4 Intake (g)': 'C18_4_Intake',
  'C16:0 to C18:1 (c+t) Intake': 'C16_to_C18_Intake',
  'Other Lipid Intake (g)': 'OtherLipid_Intake',
  'IntTFA Intake (g)': 'IntTFA_Intake',
  'LCFA Intake (g)': 'LCFA_Intake',
  'RUFAL Intake (g)': 'RUFAL_Intake',
  'Urea (kg)': 'Urea_kg',
  'RUP (g)': 'RUP',
  'RDP (g)': 'RDP',
  'RUP (%DM)': 'RUP_DM',
  'RDP (%CP)': 'RDP_CP',
  'RUP (%CP)': 'RUP_CP',
  'Forage NDF (%DM)': 'Forage_NDF_DM',
  'Lignin (%DM)': 'Lignin_DM',
  'uNDF (%DM)': 'uNDF_DM',
  'Ca Added (%DM)': 'Ca_Added_perDM',
  'P Added (%DM)': 'P_Added_perDM',
  'K Added (%DM)': 'K_Added_perDM',
  'Mg Added (%DM)': 'Mg_Added_perDM',
  'S Added (%DM)': 'S_Added_perDM',
  'Na Added (%DM)': 'Na_Added_perDM',
  'Cl Added (%DM)': 'Cl_Added_perDM',
  'Fe Added (ppm)': 'Fe_Added_ppm',
  'Zn Added (ppm)': 'Zn_Added_ppm',
  'Co Added (ppm)': 'Co_Added_ppm',
  'I Added (ppm)': 'I_Added_ppm',
  'Mn Added (ppm)': 'Mn_Added_ppm',
  'Se Added (ppm)': 'Se_Added_ppm',
  'Cu Added (ppm)': 'Cu_Added_ppm',
  'Vit-A Added (KIU/kg)': 'VitA_Added_KIU_kg',
  'Vit-D Added (KIU/kg)': 'VitD_Added_KIU_kg',
  'Vit-E Added (IU/kg)': 'VitE_Added_IU_kg',
  'Milk Urea (mg/dl)': 'Milk_Urea_mg_dl',
  'Total NDF (%BW)': 'Total_NDF_perBW',
  'MET:ME': 'MET_to_ME',
  'LYS:ME': 'LYS_to_ME',
  'ARG:ME': 'ARG_to_ME',
  'THR:ME': 'THR_to_ME',
  'LEU:ME': 'LEU_to_ME',
  'ILE:ME': 'ILE_to_ME',
  'VAL:ME': 'VAL_to_ME',
  'HIS:ME': 'HIS_to_ME',
  'PHE:ME': 'PHE_to_ME',
  'TRP:ME': 'TRP_to_ME',
  'EAA:ME': 'EAA_to_ME',
  'Milk Production (%BW)': 'Milk_Production_perBW',
  ECM: 'ECM',
  'ECM (%BW)': 'ECM_perBW',
  'Feed Efficiency': 'Feed_Efficiency',
  'Concentrate Intake (%BW)': 'Concentrate_Intake_perBW',
  'EAA (g)': 'EAA',
  'Total EAA Req. (g)': 'Total_EAA_Rqd',
  'CH4 (Mcal)': 'CH4',
  Butyric: 'Butyric',
  'Butyric (g)': 'Butyric_g',
  'Butyric (%DM)': 'Butyric_perDM',
  'Acetic (%DM)': 'Acetic_perDM',
  'Propionic (%DM)': 'Propionic_perDM',
  'uNDF (%BW)': 'uNDF_per_BW',
  'CHO-C (%DM)': 'CHO_C_per_DM',
  'dNDF-12HR (%NDF)': 'dNDF_12HR',
  'dNDF-30HR (%NDF)': 'dNDF_30HR',
  'dNDF-72HR (%NDF)': 'dNDF_72HR',
  'dNDF-120HR (%NDF)': 'dNDF_120HR',
  'dNDF-240HR (%NDF)': 'dNDF_240HR',
  'uNDF-12HR (%BW)': 'uNDF_12HR_perBW',
  'uNDF-30HR (%BW)': 'uNDF_30HR_perBW',
  'uNDF-72HR (%BW)': 'uNDF_72HR_perBW',
  'uNDF-120HR (%BW)': 'uNDF_120HR_perBW',
  'uNDF-240HR (%BW)': 'uNDF_240HR_perBW',
  'Molybdenum (ppm)': 'Molybdenum_ppm',
  'Allowable Milk (%BW)': 'Allowable_Milk_perBW',
  aNDFom: 'aNDFom_kg',
  'TFA (%DM)': 'TFA_perDM',
  'Forage uNDF-30HR': 'Forage_uNDF_30HR_kg',
  Forage: 'Forage_kg',
  'Microbial MP (%MP)': 'Microbial_MP_perMP',
  'Forage dNDF-30HR': 'Forage_dNDF_30HR_kg',
  'ME Milk:Feed': 'ME_Milk_to_Feed',
  'MP Milk:Feed': 'MP_Milk_to_Feed',
  'ME ADG + Preg': 'ME_ADG_plus_Preg',
  'MP ADG + Preg': 'MP_ADG_plus_Preg',
  'Milk to Cover Feed Costs (kg)': 'Milk_to_Cover_Costs',
  'Feed Type': 'FeedType',
  Purchased: 'Purchased',
  Density: 'Density',
  Lab: 'Lab',
  'Lab Sample ID': 'LabSampleID',
  'Lab Submission Date': 'LabSubmissionDate',
  DM: 'DM',
  Conc: 'Conc',
  Ammonia: 'NPN',
  ADIP: 'ADIP',
  NDIP: 'NDIP',
  IUN: 'IUN',
  Acetic: 'Acetic',
  Propionic: 'Propionic',
  'Other OAs': 'Other OAs',
  'CHO-B1 kd': 'CHO_B1_kd',
  'CHO-B3 kd': 'CHO_B3_kd',
  dStarch2: 'dStarch2',
  dStarch7: 'dStarch7',
  Lignin: 'Lignin',
  TFA: 'TFA_EE',
  TSI: 'TSI',
  Menadione: 'Menadione',
  'Vit. C': 'Vit_C',
  Riboflavin: 'Riboflavin',
  'Pantothenic Acid': 'PantothenicAcid',
  Pyridoxine: 'Pyridoxine',
  'Folic Acid': 'FolicAcid',
  'Vit. B-12': 'Vit_B_12',
  'Organic Zinc': 'Org_Zinc',
  'Organic Copper': 'Org_Copper',
  'Organic Manganese': 'Org_Manganese',
  'Organic Selenium': 'Org_Selenium',
  'Organic Cobalt': 'Org_Cobalt',
  Decoquinate: 'Decoquinate',
  Yeast: 'Yeast',
  'Beta Agonist': 'Beta_Agonist',
  Virginiamycin: 'Virginiamycin',
  Aureomycin: 'Aureomycin',
  Oxytetracycline: 'Oxytetracycline',
  Salinomycin: 'Salinomycin',
  'Zinc Bacitracin': 'Zinc_Bacitracin',
  Enzymes: 'Enzymes',
  'Toxin Binders': 'Toxin Binders',
  Flavor: 'Flavor',
  BMD: 'BMD',
  Sulfamethazine: 'Sulfamethazine',
  Laidlomycin: 'Laidlomycin',
  Poloxalene: 'Poloxalene',
  Ractopamine: 'Ractopamine',
  VAL: 'VAL',
  Glycerol: 'Glycerol',
  Pigment: 'Pigment',
  'C12:0': 'C12_0',
  'C14:0': 'C14_0',
  'C16:0': 'C16_0',
  'C16:1': 'C16_1',
  'C18:0': 'C18_0',
  'C18:1 Trans': 'C18_1_Trans',
  'C18:1 Cis': 'C18_1_Cis',
  'C18:2': 'C18_2',
  'C18:3': 'C18_3',
  'C18:4': 'C18:4',
  'Other Lipid': 'OtherLipid',
  'CHO-A1 kd': 'CHO_A1_kd',
  'CHO-A2 kd': 'CHO_A2_kd',
  'CHO-A3 kd': 'CHO_A3_kd',
  'CHO-A4 kd': 'CHO_A4_kd',
  'CHO-B2 kd': 'CHO_B2_kd',
  'CHO-C kd': 'CHO_C_kd',
  'Prt-A1 kd': 'Prt_A1_kd',
  'Prt-A2 kd': 'Prt_A2_kd',
  'Prt-B1 kd': 'Prt_B1_kd',
  'Prt-B2 kd': 'Prt_B2_kd',
  'Prt-C kd': 'Prt_C_kd',
  'Ca Bioavailability': 'Ca_BioAvail',
  'P Bioavailability': 'P_BioAvail',
  'Mg Bioavailability': 'Mg_BioAvail',
  'K Bioavailability': 'K_BioAvail',
  'S Bioavailability': 'S_BioAvail',
  'Na Bioavailability': 'Na_BioAvail',
  'Cl Bioavailability': 'Cl_BioAvail',
  'Fe Bioavailability': 'Fe_BioAvail',
  'Zn Bioavailability': 'Zn_BioAvail',
  'Cu Bioavailability': 'Cu_BioAvail',
  'Mn Bioavailability': 'Mn_BioAvail',
  'Se Bioavailability': 'Se_BioAvail',
  'Co Bioavailability': 'Co_BioAvail',
  'I Bioavailability': 'I_BioAvail',
  'Vit-A Bioavailability': 'VitA_BioAvail',
  'Vit-D Bioavailability': 'VitD_BioAvail',
  'Vit-E Bioavailability': 'VitE_BioAvail',
  'CHO-A1 ID': 'CHO_A1_ID',
  'CHO-A2 ID': 'CHO_A2_ID',
  'CHO-A3 ID': 'CHO_A3_ID',
  'CHO-A4 ID': 'CHO_A4_ID',
  'CHO-B1 ID': 'CHO_B1_ID',
  'CHO-B2 ID': 'CHO_B2_ID',
  'CHO-B3 ID': 'CHO_B3_ID',
  'CHO-C ID': 'CHO_C_ID',
  'Prt-A1 ID': 'Prt_A1_ID',
  'Prt-A2 ID': 'Prt_A2_ID',
  'Prt-B1 ID': 'Prt_B1_ID',
  'Prt-B2 ID': 'Prt_B2_ID',
  'Prt-C ID': 'Prt_C_ID',
  'Fat ID': 'Fat_ID',
  'C12:0 ID': 'C12_0_ID',
  'C14:0 ID': 'C14_0_ID',
  'C16:0 ID': 'C16_0_ID',
  'C16:1 ID': 'C16_1_ID',
  'C18:0 ID': 'C18_0_ID',
  'C18:1 Trans ID': 'C18_1_Trans_ID',
  'C18:1 Cis ID': 'C18_1_Cis_ID',
  'C18:2 ID': 'C18_2_ID',
  'C18:3 ID': 'C18_3_ID_',
  'C18:4 ID': 'C18_4_ID',
  'DHA ID': 'DHA_ID',
  'EPA ID': 'EPA_ID',
  'DPA ID': 'DPA_ID',
  'CP NPN': 'CP_NPN',
  'Lipolysis Rate': 'LipolysisRate',
  'Adjustment Factor': 'AdjFactor',
};

export default revertedLabels;
