import { SET_FARMS_LIST, SET_PICKED_FARMS, SET_EXACT_RECIPE } from '../actions/actionTypes';

const initialState = {
  farmsList: [],
  pickedFarms: [],
  exactFarm: [],
  isLoaded: false,
};

const farmsReducer = (state = initialState, action = {}) => {
  const { farmsList = [], pickedFarms = [], exactFarm = [] } = action;

  switch (action.type) {
    case SET_FARMS_LIST:
      return {
        ...state,
        farmsList,
        isLoaded: true,
      };
    case SET_PICKED_FARMS:
      return {
        ...state,
        pickedFarms,
      };
    case SET_EXACT_RECIPE:
      return {
        ...state,
        exactFarm,
      };
    default:
      return state;
  }
};

export default farmsReducer;
