import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import isAccessPermitted from '../../helpers/isAccessPermitted';
import { userSelector } from '../../reducers/selectors';

const RestrictComponent = ({ children, ...props }) => {
  const currentUser = useSelector(userSelector);
  const userRoles = currentUser.roles || [];
  return isAccessPermitted({ ...props, userRoles }) ? children : null;
};

RestrictComponent.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  excludeRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default RestrictComponent;
