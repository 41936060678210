import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/system';

export const ToggleContainer = styled(ToggleButtonGroup)(() => ({
  background: '#ffffff',
}));

export const ToggleButton = styled(MuiToggleButton)(() => ({
  fontWeight: 'bold',
}));
