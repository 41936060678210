// const defaultStaticColor = '#006a4e';
const defaultStaticColor = '#9db668';

const palette = (colorPrimary = defaultStaticColor) => ({
  primary: {
    main: colorPrimary,
    light: colorPrimary,
    dark: colorPrimary,
    contrastText: '#ffffff',
    grey: '#eeeeee',
    greyLight: '#f9f9f9',
    greyDark: '#a7a7a7',
    danger: '#f53434',
    error: '#f53434',
    success: '#198754',
    warning: '#f97032',
    info: '#0288D1',
  },
  secondary: {
    main: '#b1b1b1',
    light: '#f2f2f2',
    dark: '#333333',
    contrastText: '#ffffff',
    grey: '#eeeeee',
    greyLight: '#f9f9f9',
    greyDark: '#a7a7a7',
    danger: '#f53434',
    error: '#f53434',
    success: '#198754',
    warning: '#f97032',
    info: '#0288D1',
  },
});

export default palette;
export { defaultStaticColor };
