import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const StyledTag = styled(Box)(() => ({
  padding: '4px 16px',
  borderRadius: '16px',
  fontSize: 10,
  width: 'fit-content',
  textTransform: 'uppercase',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default StyledTag;
