import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAllFarms, setExactRecipe, setUserFarms } from '../../../actions/farmsActions';
import { FarmsTable, FarmsList, FarmsSelect } from '../../../components/farms';
import { useAlert } from '../../../hooks';
import { farmsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const FarmsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userFarms = useSelector(farmsSelector);

  const { farmsList, pickedFarms } = userFarms;

  const { showAlert } = useAlert();

  const onSelectChange = async (farms) => {
    try {
      await dispatch(setUserFarms(farms));
    } catch (err) {
      showAlert('error', err);
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const onLoad = async () => {
    try {
      await dispatch(getAllFarms());
    } catch (err) {
      console.error(err);
    }
  };

  const handleRationClick = async (pickedRation, farmId) => {
    const pickedFarm = farmsList.filter((q) => q.farm_id === farmId);
    try {
      await dispatch(setExactRecipe([{ ...pickedFarm?.[0], ...pickedRation }]));
    } catch (err) {
      showAlert('error', err);
    } finally {
      navigate('/rations');
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{t('farms.title')}</h2>
      <FarmsSelect farmsList={farmsList} onSelectChange={onSelectChange} pickedFarms={pickedFarms} />

      {!!farmsList?.length && !pickedFarms?.length && (
        <Box component='div' sx={{ width: 'fit-content' }}>
          <MuiAlert icon={<HelpOutlineIcon />} severity='warning' variant='outlined'>
            {t('farms.warningAlert')}
          </MuiAlert>
        </Box>
      )}

      {/*{mobileView ? (
        <FarmsList data={pickedFarms} handleRationClick={handleRationClick} />
      ) : (
        <FarmsTable data={pickedFarms} handleRationClick={handleRationClick} />
      )}*/}

      <FarmsTable data={pickedFarms} handleRationClick={handleRationClick} isMobile={mobileView} />
    </Container>
  );
};

export default FarmsPage;
