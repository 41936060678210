import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { mean, max, min } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CattleIcon from '../../assets/HoofIcon';
import { useSettings } from '../../hooks';
import { Button } from '../shared';

import BcsHistogram from './results/BcsHistogram';

const rowStyles = {
  display: 'flex',
  alignItems: 'center',
  pt: '4px',
  pb: '4px',
  borderBottom: '1px solid #eeeeee',
};

const Step4 = (props) => {
  const { onSubmit, loading, formValues, onBack, farms = [] } = props;

  const { t } = useTranslation();
  const { formatDate, formatDecimal } = useSettings();

  const farmValue = useMemo(() => {
    const farm = farms?.find((item) => item.farm_id === formValues?.Farm_ID);
    return farm?.farm_name || '';
  }, [formValues, farms]);

  const locationValue = useMemo(() => {
    const farm = farms?.find((item) => item.farm_id === formValues?.Farm_ID);
    const location = farm?.locations?.find((item) => item?.location_name === formValues?.LocationName);
    return location?.location_name || '';
  }, [formValues, farms]);

  const cattleValue = useMemo(() => {
    const farm = farms?.find((item) => item.farm_id === formValues?.Farm_ID);
    const location = farm?.locations?.find((item) => item?.location_name === formValues?.LocationName);
    const cattle = location?.cattles?.find((item) => item?.cattle_name === formValues?.CattleName);
    return cattle?.cattle_name || '';
  }, [formValues, farms]);

  const bcsValues = useMemo(() => {
    const data = formValues?.BCS?.map((v) => +v) || [];
    return {
      n: data.length,
      avg: formatDecimal(mean(data)),
      max: formatDecimal(max(data)),
      min: formatDecimal(min(data)),
      range: formatDecimal(max(data) - min(data)),
    };
  }, [formValues.BCS]);

  return (
    <Box component='div' sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
      <Box component='div' sx={{ width: { xs: '100%', sm: '100%', md: '90%' } }}>
        <Box component='div' sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
          <Box
            component='div'
            elevation={1}
            sx={{ flex: 0.35, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Paper
              component='div'
              sx={{ p: 2, flex: 1, mb: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
              <Box component='div' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box component='div' sx={{ ...rowStyles, border: 'none' }}>
                  <FarmIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>{`${t('farm')}:`}</Typography>
                  <Typography component='span' sx={{ ml: 1 }}>
                    {farmValue}
                  </Typography>
                </Box>

                <Box component='div' sx={{ ...rowStyles, border: 'none' }}>
                  <LocationIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>{`${t('location')}:`}</Typography>
                  <Typography component='span' sx={{ ml: 1 }}>
                    {locationValue}
                  </Typography>
                </Box>

                <Box component='div' sx={{ ...rowStyles, border: 'none' }}>
                  <CattleIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>{`${t('cattle')}:`}</Typography>
                  <Typography component='span' sx={{ ml: 1 }}>
                    {cattleValue}
                  </Typography>
                </Box>

                <Box component='div' sx={{ ...rowStyles, border: 'none' }}>
                  <DateIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>{`${t('date')}:`}</Typography>
                  <Typography component='span' sx={{ ml: 1 }}>
                    {formatDate(new Date())}
                  </Typography>
                </Box>
              </Box>
            </Paper>

            <Paper
              component='div'
              sx={{ p: 2, flex: 1, mt: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
              <Box component='div' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box component='div' sx={{ ...rowStyles, justifyContent: 'space-between' }}>
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>
                    {t('bcs.number')}
                  </Typography>
                  <Typography component='span' sx={{ ml: 2 }}>
                    {bcsValues.n}
                  </Typography>
                </Box>
                <Box component='div' sx={{ ...rowStyles, justifyContent: 'space-between' }}>
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>
                    {t('bcs.avg')}
                  </Typography>
                  <Typography component='span' sx={{ ml: 2 }}>
                    {bcsValues.avg}
                  </Typography>
                </Box>
                <Box component='div' sx={{ ...rowStyles, justifyContent: 'space-between' }}>
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>
                    {t('bcs.max')}
                  </Typography>
                  <Typography component='span' sx={{ ml: 2 }}>
                    {bcsValues.max}
                  </Typography>
                </Box>
                <Box component='div' sx={{ ...rowStyles, justifyContent: 'space-between' }}>
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>
                    {t('bcs.min')}
                  </Typography>
                  <Typography component='span' sx={{ ml: 2 }}>
                    {bcsValues.min}
                  </Typography>
                </Box>
                <Box component='div' sx={{ ...rowStyles, justifyContent: 'space-between', border: 'none' }}>
                  <Typography component='span' sx={{ fontWeight: 'bold' }}>
                    {t('bcs.range')}
                  </Typography>
                  <Typography component='span' sx={{ ml: 2 }}>
                    {bcsValues.range}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Paper
            elevation={1}
            sx={{
              pt: 0,
              pr: 1,
              pb: 1,
              ml: { xs: 0, sm: 0, md: 2 },
              mt: { xs: 2, sm: 2, md: 0 },
              flex: 0.65,
              display: 'flex',
              justifyContent: 'center',
              minHeight: {
                xs: '200px',
                sm: '200px',
                md: 'auto',
              },
              '> div': { flex: 1 },
            }}
          >
            <BcsHistogram data={formValues?.BCS} systemId={formValues?.BCSSetting?.BCSSystemId} />
          </Paper>
        </Box>

        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
          <Button disabled={loading} onClick={onBack} outlined type='submit'>
            {t('back')}
          </Button>
          <Button disabled={loading} loading={loading} onClick={onSubmit} type='submit'>
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Step4;
