import { urls } from '../constants';

import axios from './axios';

const initLoad = async (data) => {
  const res = await axios.post(urls.bcs.initLoad, data);
  return res && res.data && res.data.result === 'success' ? res?.data?.bcs_data : null;
};

const createBCS = (data) => axios.post(urls.bcs.create, data);

const getBcsResults = () => axios.get(urls.bcs.farms);

const getBcsView = (info) => axios.post(urls.bcs.view, { ...info });

export { initLoad, createBCS, getBcsView, getBcsResults };
