import Box from '@mui/material/Box';
import React from 'react';

import FarmsCard from './FarmsCard';

export default function FarmsList({ users, changes, farms, handleSelectAll, handleChange, handleChangeDate }) {
  return (
    <Box component='div'>
      {farms?.map((farm, idx) => (
        <FarmsCard
          changes={changes}
          farm={farm}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          handleClick={handleSelectAll}
          key={`${farm?.farm_id}_${idx}`}
          users={users}
        />
      ))}
    </Box>
  );
}
