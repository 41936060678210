import UserService from '../services/userService';

import { SET_CURRENT_COMPARISONS, FILTER_CURRENT_COMPARISONS } from './actionTypes';

export const setCurrentComparisons = (currentComparisons) => ({
  type: SET_CURRENT_COMPARISONS,
  currentComparisons,
});

export const filterCurrentComparisons = (currentComparisons) => ({
  type: FILTER_CURRENT_COMPARISONS,
  currentComparisons,
});

export const getRationDataById = (obj) => async (dispatch) => {
  try {
    const rationRes = await UserService.getRationById(obj);
    const nutrientRes = await UserService.getNutrientById(obj);
    dispatch(
      setCurrentComparisons({
        ...obj,
        feeds: rationRes?.data?.all_feeds,
        nutrients: nutrientRes?.data?.data?.nutrients,
      })
    );
  } catch (err) {
    console.error(err);
  }
};
