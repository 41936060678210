/* eslint-disable max-len */
import DeleteIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SectionWrap } from '../../../components/admin-farm-visit';
import LoadScreen from '../../../components/load-screen';
import { Input, Select } from '../../../components/shared';
import { EMPTY_OPTION, EMPTY_QUESTION, EMPTY_SECTION, QUESTION_TYPES } from '../../../constants/adminFarmVisit';
import { getUUID } from '../../../helpers';
import { formatToForm, formatToRequest } from '../../../helpers/farmVisit';
import { useAlert, useLoading } from '../../../hooks';
import { farmsSelector } from '../../../reducers/selectors';
import farmVisitService from '../../../services/farmVisitService';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TREE: 0,
  QUESTIONNAIRE: 1,
};

const templates = [
  { label: 'No Template (Empty)', value: 0 },
  { label: 'Toms Farm Overview Questions', value: 1 },
];

const AdminFarmVisitList = () => {
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(true);
  const [sections, setSections] = useState([EMPTY_SECTION]);
  const [formTitle, setFormTitle] = useState('New Form');
  const [template, setTemplate] = useState(0);

  const { showAlert } = useAlert();
  const { loading, startLoading, stopLoading } = useLoading(false);

  const onTemplateChange = async (value) => {
    // To Do: Use dynamic templates
    setTemplate(value);
    if (value === 0) {
      setFormTitle('New Form');
      setSections([EMPTY_SECTION]);
    }
    if (value === 1) {
      await onTemplateLoad();
    }
  };

  const onTemplateLoad = async () => {
    try {
      startLoading();
      const resp = await farmVisitService.getFarmVisitQuestionnaireTemplate();
      const formattedResp = formatToForm({ questionnaire: resp.template });
      if (formattedResp?.title) {
        setFormTitle(formattedResp?.title);
      }
      if (formattedResp?.sections) {
        setSections(formattedResp?.sections);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const handleUpdateSection = (section, field, newValue) => {
    const foundIndex = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    sectionCopy[foundIndex][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateQuestion = (section, question, field, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (field === 'type' && !sectionCopy[foundIndexSect]?.questions[foundIndexQuest]?.options?.length) {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options = [];
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    }
    sectionCopy[foundIndexSect].questions[foundIndexQuest][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateOption = (section, question, index, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    Object.assign(sectionCopy[foundIndexSect].questions[foundIndexQuest].options[index], {
      label: newValue,
      id: getUUID(),
    });
    setSections(sectionCopy);
  };

  const handleOption = (section, question, index, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    } else {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.splice(index, 1);
    }
    setSections(sectionCopy);
  };

  const handleQuestion = (section, question, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions.push({
        ...EMPTY_QUESTION,
        id: getUUID(),
      });
    } else {
      const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
      sectionCopy[foundIndexSect].questions.splice(foundIndexQuest, 1);
    }
    setSections(sectionCopy);
  };

  const handleSection = (section, action = 'add') => {
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy.push({
        ...EMPTY_SECTION,
        id: getUUID(),
        questions: [
          {
            ...EMPTY_QUESTION,
            id: getUUID(),
          },
        ],
      });
    } else {
      const foundIndexSect = sections.findIndex((q) => q.id === section.id);
      sectionCopy.splice(foundIndexSect, 1);
    }
    setSections(sectionCopy);
  };

  const handleSave = async () => {
    try {
      startLoading();
      const formattedObject = formatToRequest(formTitle, sections);
      await farmVisitService.createNewAdminVisit({ questionnaires: [formattedObject] });
      showAlert('success', t('adminFarmVisit.successAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const handleUpdate = async (id) => {
    try {
      startLoading();
      const formattedObject = formatToRequest(formTitle, sections);
      await farmVisitService.updateExistingFarmVisit(formattedObject, id);
      showAlert('success', t('adminFarmVisit.successAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const handlePublish = async (id) => {
    try {
      startLoading();
      await farmVisitService.publishFarmVisit(id);
      showAlert('success', t('adminFarmVisit.successAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isDisabled =
    !formTitle?.trim()?.length ||
    sections.filter(
      (sect) => !sect?.title?.trim()?.length || sect?.questions?.filter((q) => !q?.title?.trim()?.length)?.length
    ).length;

  return (
    <Container
      maxWidth={false}
      sx={{
        p: {
          lg: 0,
          md: 0,
          sm: 0,
          sx: 0,
        },
        position: 'relative',
      }}
    >
      <Container maxWidth={false} sx={{ p: containerPadding }}>
        <Box component='div'>
          <Box
            component='div'
            sx={{
              p: {
                md: '0',
                sm: '0',
                xs: '0 16px',
              },
            }}
          >
            <h2>{t('adminFarmVisit.create')}</h2>
          </Box>
          <Box
            component='div'
            sx={{
              p: {
                md: '0',
                sm: '0',
                xs: '0 16px',
              },
            }}
          >
            <Select
              label='Template'
              onChange={(e) => onTemplateChange(e.target.value)}
              options={templates}
              sx={{
                width: mobileView ? '100%' : '50%',
                mb: 2,
              }}
              value={template}
            />
          </Box>
          <Box
            component='div'
            sx={{
              p: {
                md: '0',
                sm: '0',
                xs: '0 16px',
              },
            }}
          >
            <Input
              error={!formTitle?.length}
              id='admin-farm-visit-form-title'
              label={t('adminFarmVisit.formTitle')}
              name='admin-farm-visit-form-title'
              onChange={(e) => setFormTitle(e.target.value)}
              sx={{
                width: mobileView ? '100%' : '50%',
                mb: 2,
              }}
              value={formTitle}
            />
          </Box>
          <Box
            component='div'
            sx={{
              mb: '16px',
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              flexDirection: mobileView ? 'column' : 'row',
            }}
          >
            <SectionWrap
              handleOption={handleOption}
              handleQuestion={handleQuestion}
              handleSection={handleSection}
              handleUpdateOption={handleUpdateOption}
              handleUpdateQuestion={handleUpdateQuestion}
              handleUpdateSection={handleUpdateSection}
              mobileView={mobileView}
              sections={sections}
              setSections={setSections}
            />
          </Box>

          <Fab
            aria-label='add'
            color='primary'
            disabled={!!isDisabled}
            onClick={() => handleSave()}
            sx={{
              margin: 0,
              top: 'auto',
              right: 20,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              fontWeight: 'bold',
            }}
            variant='extended'
          >
            <SaveIcon sx={{ mr: 1 }} />
            {t('submit')}
          </Fab>
        </Box>
      </Container>
      {(!isLoaded || loading) && <LoadScreen />}
    </Container>
  );
};

export default AdminFarmVisitList;
