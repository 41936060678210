import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import RecipeIcon from '@mui/icons-material/AssignmentOutlined';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import FeedIcon from '@mui/icons-material/TornadoOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';
import Tree from '../shared/tree';

const FeedHistoryTree = (props) => {
  const { historyList = [], handleClick, mobileView, currentResults, onClear } = props;

  const { formatDate } = useSettings();
  const { t } = useTranslation();

  const treeData = useMemo(
    () =>
      historyList.map((item) => ({
        id: item.farm_id,
        label: (
          <Box component='div'>
            <FarmIcon fontSize='small' /> {item.farm_name}
          </Box>
        ),
        children: item.feeds.map((feed) => ({
          id: `${item.farm_id}${feed.feed_name}`,
          label: (
            <Box component='div'>
              <FeedIcon fontSize='small' /> {feed.feed_name}
            </Box>
          ),
          children: feed?.feed_versions.map((date) => ({
            id: `${date.feed_id}${date.oadate}`,
            selected: currentResults.find((q) => q.Feed_ID === feed.feed_id && q.OADate === date.oadate),
            name: formatDate(date.oadate, true),
            onClick: () =>
              handleClick({
                Farm_ID: item.farm_id,
                farmName: item.farm_name,
                FeedName: feed.feed_name,
                OADate: date.oadate,
                Feed_ID: feed.feed_id,
              }),
            label: (
              <Box component='div'>
                <RecipeIcon fontSize='small' />
                <span style={{ display: 'flex' }}>{formatDate(date.oadate, true)}</span>
              </Box>
            ),
          })),
        })),
      })),
    [historyList, currentResults]
  );

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('psc.selectSample')}
        </Typography>
        {currentResults && !!currentResults?.length && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default FeedHistoryTree;
