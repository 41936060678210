import { urls } from '../constants';

import axios from './axios';

// Farms Access

const getAdminMills = async () => {
  const response = await axios.get(urls.admin.farmsAccess);
  return response;
};

const getFarmUsers = async (millObj) => {
  const response = await axios.post(urls.admin.millUsers, { ...millObj });
  return response;
};

const updateFarmsAccess = async (obj) => {
  const response = await axios.post(urls.admin.permissions, obj);
  return response;
};

const addExtUser = async (obj) => {
  const response = await axios.post(urls.admin.addExtUser, { ...obj });
  return response;
};

const updateUserDate = async (obj) => {
  const response = await axios.patch(urls.admin.permissions, { ...obj });
  return response;
};

// Activity Log

const getActivityLog = async (startDate, endDate) => {
  let params = {};

  if (startDate && endDate) {
    params = { start_date: startDate, end_date: endDate, date_type: 'upload' };
  }

  const response = await axios.get(urls.admin.activityLog, { params });
  return response;
};

const adminService = {
  getAdminMills,
  getFarmUsers,
  updateFarmsAccess,
  getActivityLog,
  addExtUser,
  updateUserDate,
};

export default adminService;
