import FilterIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format, subDays, isValid, isBefore, getYear } from 'date-fns';
import debounce from 'debounce';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setActivityLog, getActivityLog } from '../../../actions/activityLogActions';
import { ActivityLogTable, FilterModal, FilterList, ActivityLogCard } from '../../../components/activity-log';
import LoadScreen from '../../../components/load-screen';
import { DatePicker } from '../../../components/shared';
import { activityLogActions, activityLogStatuses } from '../../../constants/enums';
import { useLoading } from '../../../hooks';
import { activityLogSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const isDateValid = (date) => {
  if (!date) {
    return false;
  }
  const year = `${getYear(date)}`;
  return isValid(date) && year?.length === 4;
};

const ActivityLogPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activityLog = useSelector(activityLogSelector);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  const [filters, setFilters] = useState({
    farm: 'ALL',
    status: 'ALL',
    action: 'ALL',
  });
  const [filterModalOpen, toggleFilterModal] = useState(false);

  useEffect(
    () => () => {
      // Clear store on unmount
      dispatch(setActivityLog([]));
    },
    []
  );

  const fetchData = useCallback(
    debounce(async (start, end) => {
      if (isDateValid(start) && isDateValid(end) && isBefore(start, end) && isBefore(end, new Date())) {
        startLoading();
        await dispatch(getActivityLog(format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')));
        stopLoading();
      }
    }, 600),
    []
  );

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [startDate, endDate]);

  const onChangeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const list = useMemo(() => {
    let result = [...activityLog?.list] || [];

    if (filters?.action !== 'ALL') {
      result = result.filter((item) => filters.action === item.action);
    }

    if (filters?.status !== 'ALL') {
      result = result.filter((item) => filters.status === item.status);
    }

    if (filters?.farm !== 'ALL') {
      result = result.filter((item) => filters.farm === item.farm_id);
    }

    return result;
  }, [activityLog.list, filters]);

  const farms = useMemo(() => {
    const result = [];

    activityLog?.list?.forEach((item) => {
      if (!result?.find((farm) => farm?.id === item?.farm_id)) {
        result.push({ id: item.farm_id || '', name: item.farm_name || '' });
      }
    });

    return result || [];
  }, [activityLog.list]);

  // Select options

  const farmOptions = useMemo(
    () => [{ label: t('all'), value: 'ALL' }, ...farms?.map((farm) => ({ label: farm.name, value: farm.id }))],
    [farms]
  );

  const actionOptions = useMemo(
    () => [
      { label: t('all'), value: 'ALL' },
      { label: t('upload'), value: activityLogActions.UPLOAD },
      { label: t('download'), value: activityLogActions.DOWNLOAD },
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { label: t('all'), value: 'ALL' },
      { label: t('success'), value: activityLogStatuses.SUCCESS },
      { label: t('failed'), value: activityLogStatuses.FAILED },
    ],
    []
  );

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      {loading && mobileView && <LoadScreen />}
      {mobileView ? (
        <>
          <h2>{t('activityLog.title')}</h2>
          <Box component='div' sx={{ mt: 1, mb: 2, '> div': { width: '100%' } }}>
            <DatePicker closeOnSelect label='From' maxDate={endDate} onChange={setStartDate} value={startDate} />
          </Box>
          <Box component='div' sx={{ mb: 2, '> div': { width: '100%' } }}>
            <DatePicker
              closeOnSelect
              disableFuture
              label='To'
              minDate={startDate}
              onChange={setEndDate}
              value={endDate}
            />
          </Box>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Typography color='secondary' component='div' sx={{ fontWeight: 'bold' }}>
              {t('filter')}
            </Typography>
            <IconButton
              aria-label='menu'
              color='inherit'
              edge='start'
              onClick={() => toggleFilterModal(true)}
              sx={{ ml: 1, mr: 1 }}
            >
              <FilterIcon color='secondary' />
            </IconButton>
            <FilterList
              actionOptions={actionOptions}
              farmOptions={farmOptions}
              filters={filters}
              isMobile={mobileView}
              onRemove={(key) => setFilters({ ...filters, [key]: 'ALL' })}
              statusOptions={statusOptions}
            />
          </Box>

          {/*list?.map((item) => (
            <ActivityLogCard key={item.id} {...item} />
          ))*/}

          <ActivityLogTable data={list} loading={loading} />
        </>
      ) : (
        <Box component='div'>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('activityLog.title')}</h2>

            <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
              <Box component='div' sx={{ mr: 2 }}>
                <DatePicker label='From' maxDate={endDate} onChange={setStartDate} value={startDate} />
              </Box>
              <Box component='div'>
                <DatePicker disableFuture label='To' minDate={startDate} onChange={setEndDate} value={endDate} />
              </Box>
            </Box>
          </Box>
          <Box component='div' sx={{ pt: 1 }}>
            <Box component='div' sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography color='secondary' component='div' sx={{ fontWeight: 'bold' }}>
                {t('filter')}
              </Typography>
              <IconButton
                aria-label='menu'
                color='inherit'
                edge='start'
                onClick={() => toggleFilterModal(true)}
                sx={{ ml: 1, mr: 1 }}
              >
                <FilterIcon color='secondary' />
              </IconButton>
              <FilterList
                actionOptions={actionOptions}
                farmOptions={farmOptions}
                filters={filters}
                onRemove={(key) => setFilters({ ...filters, [key]: 'ALL' })}
                statusOptions={statusOptions}
              />
            </Box>
            <ActivityLogTable data={list} loading={loading} />
          </Box>
        </Box>
      )}

      <FilterModal
        actionOptions={actionOptions}
        farmOptions={farmOptions}
        filters={filters}
        onChange={onChangeFilters}
        onClose={() => toggleFilterModal(false)}
        open={filterModalOpen}
        statusOptions={statusOptions}
      />
    </Container>
  );
};

export default ActivityLogPage;
