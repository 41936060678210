import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { bcsStepOptions } from '../../constants/bcs';
import { Button, Input, RadioGroup } from '../shared';

const Step2 = (props) => {
  const { formValues, onBack, onSubmit } = props;

  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      NumAnimals: formValues.NumAnimals || 100,
      Percent_to_BCS: formValues?.BCSSetting?.Percent_to_BCS ? formValues?.BCSSetting?.Percent_to_BCS * 100 : 10,
      BCSSystemId: formValues?.BCSSetting?.BCSSystemId || 1,
    },
    onSubmit,
  });

  const cows = useMemo(() => {
    const value = form?.values?.NumAnimals * (form?.values?.Percent_to_BCS / 100);
    return value && value > 10 ? Math.round(value) : 10;
  }, [form.values]);

  return (
    <form onSubmit={form.onSubmit}>
      <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            width: { xs: '100%', sm: '100%', md: '60%' },
          }}
        >
          <Stack
            component='div'
            spacing={2}
            sx={{
              mr: { xs: 0, sm: 0, md: 2 },
              mb: { xs: 2, sm: 2, md: 0 },
              width: '100%',
            }}
          >
            <Input
              id='number-of-cows'
              label={t('bcs.cowsInGroup')}
              name='NumAnimals'
              onChange={form.handleChange}
              type='number'
              value={form.values.NumAnimals}
            />
            <Input
              id='percent-to-bcs'
              label={t('bcs.percentOfGroup')}
              name='Percent_to_BCS'
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  form.handleChange(e);
                }
              }}
              type='number'
              value={form.values.Percent_to_BCS}
            />
            <Input
              disabled
              endAdornment={
                <Tooltip enterTouchDelay={0} title={t('bcs.tooltip')}>
                  <HelpOutlineIcon />
                </Tooltip>
              }
              id='cows'
              label={t('bcs.cowsToBCS')}
              type='number'
              value={cows}
            />
          </Stack>
          <Box
            component='div'
            sx={{
              p: 2,
              background: '#ffffff',
              border: '1px solid rgba(0,0,0,0.26)',
              borderRadius: '4px',
              width: '100%',
            }}
          >
            <RadioGroup
              defaultValue={formValues.BCSSystemId}
              name='BCSSystemId'
              onChange={form.handleChange}
              options={Object.values(bcsStepOptions)?.map((option) => ({
                label: t(option.localeKey),
                value: option.id,
              }))}
              title={t('bcs.systemId')}
              value={form.values.BCSSystemId}
            />
          </Box>
        </Box>
      </Box>
      <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component='div'
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'space-between',
            width: { xs: '100%', sm: '100%', md: '60%' },
          }}
        >
          <Button onClick={onBack} outlined type='submit'>
            {t('back')}
          </Button>
          <Button
            disabled={!form.values.NumAnimals || !form.values.Percent_to_BCS}
            onClick={() => onSubmit(form.values)}
            type='submit'
          >
            {t('next')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Step2;
