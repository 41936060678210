import { getUUID } from '../helpers';

export const QUESTION_TYPES = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Yes/No',
    value: 'boolean',
  },
  {
    label: 'Score',
    value: 'score',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Radio',
    value: 'radio',
  },
];

export const HAS_OPTIONS = ['checkbox', 'radio'];

export const EMPTY_OPTION = { label: 'New Option', id: getUUID() };
export const EMPTY_QUESTION = {
  title: 'New Question',
  description: '',
  required: false,
  type: 'text',
};
export const EMPTY_SECTION = {
  id: getUUID(),
  title: 'New Section',
  description: '',
  questions: [
    {
      id: getUUID(),
      ...EMPTY_QUESTION,
    },
  ],
};
