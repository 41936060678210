import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '../shared/autocomplete';

const FarmsSelect = (props) => {
  const { farmsList, pickedFarms, onSelectChange } = props;

  const { t } = useTranslation();

  const handleOnChange = (e, value) => {
    onSelectChange(value);
  };

  return (
    <Box component='div' sx={{ width: { md: '50%', sm: '100%' } }}>
      <Autocomplete
        getOptionLabel={(option) => option.farm_name}
        inputLabel={t('farms.label')}
        inputPlaceholder={t('farms.placeholder')}
        isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
        labelKey='farm_name'
        noOptionsText={t('farms.noOptions')}
        onChange={handleOnChange}
        options={farmsList}
        value={pickedFarms}
        valueKey='farm_id'
      />
    </Box>
  );
};

export default FarmsSelect;
