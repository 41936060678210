import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Input, Modal, Autocomplete } from '../shared';

const AddExternalUserModal = (props) => {
  const { open, onClose, addUser, farmsList, defaultDate } = props;

  const { t } = useTranslation();

  const [pickedDate, setPickedDate] = useState(defaultDate);
  const [userEmail, setUserEmail] = useState('');
  const [pickedFarms, setPickedFarms] = useState([]);

  useEffect(() => {
    setPickedDate(defaultDate);
    setUserEmail('');
    setPickedFarms([]);
  }, [defaultDate, open]);

  const handleOnChange = (e, value) => {
    setPickedFarms(value);
  };

  return (
    <Modal
      closeText={t('cancel')}
      onClose={onClose}
      onSubmit={() => {
        addUser(pickedDate, userEmail, pickedFarms);
        onClose();
      }}
      open={open}
      submitText={t('submit')}
      sxBody={{ padding: '24px 16px' }}
      title={t('farmsAccess.addExtUser')}
    >
      <Grid component='div' container spacing={2}>
        <Grid component='div' item md={6} sm={12} xs={12}>
          <Input
            id='user-email'
            label={t('farmsAccess.userEmail')}
            name='userEmail'
            onChange={(e) => setUserEmail(e.target.value)}
            sx={{ width: '100%' }}
            value={userEmail}
          />
        </Grid>
        <Grid component='div' item md={6} sm={12} sx={{ '> div': { display: 'flex' } }} xs={12}>
          <DatePicker label={t('farmsAccess.validUntil')} onChange={setPickedDate} value={pickedDate} />
        </Grid>
      </Grid>
      <Box
        component='div'
        sx={{
          mt: '16px',
          width: { sm: '80%' },
        }}
      >
        <Autocomplete
          getOptionLabel={(option) => option.farm_name}
          inputLabel={t('farms.label')}
          inputPlaceholder={t('farms.placeholder')}
          isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
          labelKey='farm_name'
          noOptionsText={t('farms.noOptions')}
          onChange={handleOnChange}
          options={farmsList}
          sx={{ mb: 0 }}
          value={pickedFarms}
          valueKey='farm_id'
        />
      </Box>
    </Modal>
  );
};

export default AddExternalUserModal;
