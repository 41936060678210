/* eslint-disable max-len */
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../shared';

export default function CheckboxRow(props) {
  const { handleChange, handleSelectAll, users, farms, changes } = props;

  const { t } = useTranslation();

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell
        align='center'
        component='th'
        scope='row'
        sx={{
          position: 'sticky',
          outline: '1px solid #e0e0e0',
          background: '#fff',
          zIndex: 2,
          left: 0,
          padding: 0,
        }}
      >
        <Button onClick={() => handleSelectAll()} outlined size='small'>
          {t('farmsAccess.grantFullAccess')}
        </Button>
      </TableCell>
      {users.map((user) => (
        <TableCell align='center' key={`0_${user?.user_id}`}>
          <Checkbox
            checked={
              changes.filter((el) => el.user_id === user.user_id && el.grant_access === true).length === farms.length
            }
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => handleChange(e.target.checked, null, 'user', user)}
            sx={{
              padding: '0',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  );
}
