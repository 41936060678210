import Stack from '@mui/material/Stack';
import React from 'react';

import { tmrFields } from '../../../constants/psc';
import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../../helpers';
import { useSettings } from '../../../hooks';

import SampleTMRCard from './SampleTMRCard';

const SampleTMRList = ({ data = [] }) => {
  const { formatDecimal } = useSettings();

  const sortedData = React.useMemo(() => {
    const calcData = {};
    if (data.length) {
      tmrFields.forEach((field) => {
        const fieldData = data.map((q) => q[field]);
        const avg = getAvg(fieldData);
        const sd = getStandardDeviation(fieldData);
        const cv = coefficientOfVariation(fieldData);
        calcData[field] = {
          avg: formatDecimal(avg),
          sd: formatDecimal(sd),
          cv: formatDecimal(cv),
        };
      });
    }
    return calcData;
  }, [data]);

  return (
    <Stack component='div' spacing={2} sx={{ pb: 2 }}>
      {data?.map((sample, idx) => (
        <SampleTMRCard data={sample} isSolo={data.length === 1} key={`card_${idx}`} sortedData={sortedData} />
      ))}
    </Stack>
  );
};

export default SampleTMRList;
