import MuiSkeleton from '@mui/material/Skeleton';
import React from 'react';

const Skeleton = (props) => {
  const { variant = 'rows', animation = 'wave', height, width = '100%', rows = 3 } = props;

  if (variant === 'rows' && rows) {
    return Array(rows)
      .fill('')
      ?.map((i, idx) => (
        <MuiSkeleton
          animation={animation}
          height={height || 40}
          key={idx}
          sx={{ mt: idx === 0 ? 0 : 2, borderRadius: '5px' }}
          variant='rectangular'
          width={width}
        />
      ));
  }

  return <MuiSkeleton animation={animation} height={height} variant={variant} width={width} />;
};

export default Skeleton;
