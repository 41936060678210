export const globalUnits = {
  METRIC: 'Metric',
  IMPERIAL: 'Imperial',
};

export const currencyUnits = {
  DOLLAR: '$',
};

export const energyUnits = {
  MCAL: 'Mcal',
  MJ: 'MJ',
};

export const languages = {
  ENGLISH: 'en',
  FRENCH: 'fr',
};

export const decimalFormats = {
  DOT: '.',
  COMA: ',',
};

export const dateFormats = {
  ddMM: 'dd/MM/yyyy',
  MMdd: 'MM/dd/yyyy',
  dM: 'd/M/yyyy',
  Md: 'M/d/yyyy',
};

export const decimalPrecisionFormats = {
  ONE_DIGIT: 1,
  TWO_DIGITS: 2,
  THREE_DIGITS: 3,
  FOUR_DIGITS: 4,
};

export const formatRatios = {
  KGtoLBS: 2.20462262185, // kg to lbs
  MTtoET: 1.10231131092, // Metric Ton to English Ton
  PerMTtoPerTon: 0.90718474004, // per Metric Ton to per English Ton
  KGpercuMtoLBSpercuFT: 0.0624386,
  MtoFT: 3.28084, // Meters to Feet
  CMtoIN: 0.393701, // cm to inches
  MMtoIN: 0.0394, // mm to inches
  KPHtoMPH: 0.621371,
  SQMtoSQFT: 10.76391,
  HAtoAC: 2.471054,
  GMTtoGTon: 0.90718474004,
  GtoLbs: 0.00220462,
  McalToMj: 4.1868,
};

export const formatLabels = {
  kg: 'lbs',
  MT: 'ET',
  // KGpercuMtoLBSpercuFT: 0.0624386,
  M: 'FT',
  cm: 'in',
  mm: 'in',
  // KPHtoMPH: 0.621371,
  // SQMtoSQFT: 10.76391,
  // HAtoAC: 2.471054,
  // GMTtoGTon: 0.90718474004,
  g: 'lbs',
  mg: 'lbs',
};
