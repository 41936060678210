import MoneyIcon from '@mui/icons-material/PaidOutlined';
import WeightIcon from '@mui/icons-material/ScaleOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';
import { Button } from '../shared';
import { StyledCard } from '../shared/cards';

export default function FeedCard(props) {
  const { AF_amount, DM_amount, cost, feed_name, feed_type, handleFeedClick } = props;

  const { t } = useTranslation();
  const { formatLabel, formatWeight, formatCost } = useSettings();

  return (
    <>
      <StyledCard component={Paper}>
        <Typography component='h6'>{feed_name}</Typography>
        <Box component='div'>
          <Typography component='span' sx={{ fontWeight: 'bold' }}>
            {formatLabel(`${t('rations.DM')} (kg)`)}
          </Typography>
          <Typography component='span'>
            <WeightIcon color='secondary' />
            {formatWeight(DM_amount, feed_type, 'lbs')}
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='span' sx={{ fontWeight: 'bold' }}>
            {formatLabel(`${t('rations.asFed')} (kg)`)}
          </Typography>
          <Typography component='span'>
            <WeightIcon color='secondary' />
            {formatWeight(AF_amount, feed_type, '(lbs)')}
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='span' sx={{ fontWeight: 'bold' }}>
            {t('rations.costPerTon')}
          </Typography>
          <Typography component='span'>
            <MoneyIcon color='secondary' />
            {formatCost(cost)}
          </Typography>
        </Box>
        <Button onClick={() => handleFeedClick(feed_name, feed_type)} type='submit'>
          {t('view')}
        </Button>
      </StyledCard>
    </>
  );
}
