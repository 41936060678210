import { SET_FARM_VISIT_RESULTS } from '../actions/actionTypes';

const initialState = {
  results: [],
  isLoaded: false,
};

const farmVisitResultsReducer = (state = initialState, action = {}) => {
  const { results = [] } = action;

  switch (action.type) {
    case SET_FARM_VISIT_RESULTS:
      return {
        ...state,
        results,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default farmVisitResultsReducer;
