import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const Tabs = ({ activeTab, onChange, tabs = [] }) => (
  <MuiTabs
    aria-label='silo_tabs'
    onChange={(e, newValue) => onChange(newValue)}
    sx={{ borderBottom: (theme) => `1px solid ${theme?.palette?.primary?.grey}` }}
    value={activeTab}
  >
    {tabs.map((tab, index) => (
      <Tab
        disabled={tab.disabled}
        icon={tab.icon}
        iconPosition='start'
        key={index}
        label={tab.label}
        {...a11yProps(index)}
        sx={{
          fontWeight: 'bold',
          borderRadius: '4px 4px 0 0',
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: 54,
        }}
      />
    ))}
  </MuiTabs>
);

Tabs.propTypes = {
  activeTab: PropTypes.number,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      disabled: PropTypes.bool,
      icon: PropTypes.any,
    })
  ),
};

export default Tabs;
