import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { hexToRgba } from '../../../helpers';

import StyledChip from './styled';

const TableChip = (props) => {
  const { color = 'default', label = '', icon } = props;

  const theme = useTheme();

  const hex = theme?.palette?.primary[color] || theme?.palette?.primary?.grey;

  return (
    <StyledChip
      color={color}
      icon={icon}
      label={label}
      sx={{
        background: hexToRgba(hex, 0.1),
        '.MuiChip-icon': {
          marginLeft: label ? '8px' : '16px',
        },
      }}
      variant='outlined'
    />
  );
};

TableChip.propTypes = {
  color: PropTypes.oneOf(['default', 'secondary', 'error', 'info', 'success', 'warning']),
  label: PropTypes.string,
  icon: PropTypes.any,
};

export default TableChip;
